import { ButtonBase, styled, Typography } from "@mui/material";
import React, { useState } from "react";
import PageHeader from "../common/PageHeader";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import SkillsAddModal from "./SkillsAddModal";
import skillsService from "../../services/skills.service";
import { useTranslation } from "react-i18next";

const Root = styled("div")`
  background-color: #72767e;
  background-image: url("/static/backgrounds/mountain.jpg");
  background-size: cover;
  background-position: center;
  padding-top: 30px;
  padding-bottom: 100px;
`;

const AddButton = styled(ButtonBase)`
  background: #eceef7;
  height: 62px;
  width: 350px;
  border-radius: 16px;
  display: flex;
  padding: 25px;
`;

const BtnCtn = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const SkillsPageHeader: React.FC<{ transversal?: boolean }> = ({
  transversal,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    skillsService.addList = [];
    skillsService._addList.next([]);
  };

  return (
    <Root>
      <PageHeader
        title={t(`pages.${transversal ? "abilities" : "skills"}`)}
        subtitle={t(`skills.enter_${transversal ? "abilities" : "skills"}`)}
      />

      <BtnCtn>
        <AddButton onClick={handleOpen}>
          <Typography sx={{ fontWeight: "bold", color: "#505887" }}>
            {t(`skills.add_${transversal ? "abilities" : "skills"}`)}
          </Typography>
          <AddCircleRoundedIcon color="primary" sx={{ marginLeft: "auto" }} />
        </AddButton>
      </BtnCtn>

      <SkillsAddModal {...{ open, handleClose, transversal }} />
    </Root>
  );
};

export default SkillsPageHeader;
