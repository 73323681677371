import { Typography, styled, Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const SButton = styled(Button)`
  width: 280px;
  height: 69px;
  font-size: 14px;
  margin-top: 20px;
  background: transparent linear-gradient(to right, #505dff, #01bdff) no-repeat
    padding-box;
  font-weight: bold;
`;

const Root = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const textMargin = 2;

const Big5Intro: React.FC<any> = ({ handleStart }) => {
  const { t } = useTranslation();
  return (
    <Root>
      <Typography marginBottom={textMargin} variant="subtitle2">
        {t("big5.intro.text_1")}
      </Typography>
      <Typography marginBottom={textMargin}>
        {t("big5.intro.text_2")}
      </Typography>
      <Typography marginBottom={textMargin}>
        {t("big5.intro.text_3")}
      </Typography>
      <Typography marginBottom={textMargin}>
        {t("big5.intro.text_4")}
      </Typography>
      <Typography marginBottom={2}>{t("tests.ready")}</Typography>
      <SButton onClick={handleStart}>{t("big5.intro.begin")}</SButton>
    </Root>
  );
};

export default Big5Intro;
