import { styled } from "@mui/material";
import React from "react";
import PageHeader from "../../components/common/PageHeader";

const Root = styled("div")`
  background-color: #6c7882;
  background-image: url("/static/backgrounds/lake.jpg");
  background-size: cover;
  background-position: center;
  padding-top: 30px;
  padding-bottom: 100px;
`;

const TrainingPageHeader = () => {
  return (
    <Root>
      <PageHeader
        title="Formations"
        subtitle="Découvre des formations qui correspondent à ton projet pro !"
      />
    </Root>
  );
};

export default TrainingPageHeader;
