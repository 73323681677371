import { BehaviorSubject, forkJoin, map, Observable, tap } from "rxjs";
import { SkillsListEntity } from "../@types/SkillCategoryGroup";
import store from "../store/store";
import http from "./http";
import { toastError } from "../utils/toasts";
import worksService from "./works.service";

class skillsService {
  static skills: SkillsListEntity[] = [];
  static _skills = new BehaviorSubject<SkillsListEntity[]>([]);
  static addList: Partial<SkillsListEntity>[] = [];
  static _addList = new BehaviorSubject<Partial<SkillsListEntity>[]>([]);

  static get(type?: string) {
    const state: any = store.getState();
    this.addList = [];
    this._addList.next([]);

    return http
      .get("person_skills", {
        params: {
          "person.id": state.user.person_id,
          pagination: false,
          ...(type === "transversal" ? { "skill.type": "transversal" } : {}),
        },
      })
      .pipe(
        tap({
          next: (res) => {
            this.skills = res.data["hydra:member"];
            this._skills.next(this.skills);
          },
          error: () => {
            toastError();
          },
        })
      );
  }

  static edit(skill: SkillsListEntity, level: number) {
    return http
      .put(`person_skills/${skill.id}`, {
        level: `/api/skill_levels/${level}`,
      })
      .pipe(
        tap({
          error: () => toastError(),
        })
      );
  }

  static delete(skill: SkillsListEntity, transversal?: boolean) {
    return http.delete(`person_skills/${skill.id}`).pipe(
      tap({
        next: () => {
          const completeness = JSON.parse(
            localStorage.getItem("completeness") ?? "{}"
          );
          const count = Math.max(0, this.skills.length - 1);
          completeness[transversal ? "nbSkillsTransversal" : "nbSkills"] =
            count;

          localStorage.setItem("completeness", JSON.stringify(completeness));

          if (completeness.nbSkillsTransversal === 0) {
            const filters = [...(worksService.baseParams.filters as [])];
            const index = filters.findIndex((e) => e === "transversal_skills");
            index >= 0 && filters.splice(index, 1);
            worksService.baseParams.filters = filters;
          }

          worksService.reset();

          const index = this.skills.findIndex((el) => el.id === skill.id);
          const cp = [...this.skills];
          cp.splice(index, 1);
          this.skills = cp;
          this._skills.next(this.skills);
        },
        error: () => toastError(),
      })
    );
  }

  /**
   * Add/delete an item to/from addList
   * @param item
   */
  static toggleFromQueue(item: Partial<SkillsListEntity>) {
    if (!item?.id) return;
    const index = this.addList.findIndex((e) => e.id === item.id);
    if (index >= 0) {
      this.addList.splice(index, 1);
    } else {
      this.addList.push(item);
    }
    this._addList.next(this.addList);
  }

  static updateAddList(item: Partial<SkillsListEntity>, level: number) {
    const list = [...this.addList];
    const index = list.findIndex((el) => el.id === item.id);
    if (index >= 0) {
      list[index].level = {
        "@id": `/api/skill_levels/${level}`,
        "@type": "string",
        id: "string",
        level,
        label: "string",
        description: "",
      };
    }
    this.addList = list;
    this._addList.next(list);
  }

  static addSkills(transversal?: boolean) {
    const obs: Observable<any>[] = this.addList.map((el) =>
      http.post("person_skills", {
        label: el.label,
        level: `/api/skill_levels/${el.level?.level ?? 1}`,
        skill: el["@id"],
      })
    );

    return forkJoin(obs).pipe(
      map((e) => e.map((f) => f.data)),
      tap({
        next: (e) => {
          const completeness = JSON.parse(
            localStorage.getItem("completeness") ?? "{}"
          );
          const count = this.skills.length + e.length;
          completeness[transversal ? "nbSkillsTransversal" : "nbSkills"] =
            count;

          localStorage.setItem("completeness", JSON.stringify(completeness));

          if (
            completeness[transversal ? "nbSkillsTransversal" : "nbSkills"] > 0
          ) {
            worksService.baseParams.filters = [
              ...(worksService.baseParams.filters as []),
              transversal ? "transversal_skills" : "skills",
            ];
          }

          worksService.reset();

          this.skills = [...this.skills, ...e];
          this._skills.next(this.skills);
        },
        error: () => toastError(),
      })
    );
  }

  static removeFromAddList(skill: Partial<SkillsListEntity>) {
    const index = this.addList.findIndex((e) => e.id === skill.id);
    if (index === -1) return;
    this.addList.splice(index, 1);
    this._addList.next(this.addList);
  }
}

export default skillsService;
