import React, { useEffect, useState } from "react";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterMoment from "@mui/lab/AdapterMoment";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import { Controller } from "react-hook-form";
import { FormControlLabel, FormGroup, Switch, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import moment from "moment";

const ExperienceFormDate: React.FC<any> = ({
  control,
  baseOngoing,
  getValues,
  clearErrors,
  watch,
  training,
}) => {
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [ongoing, setOngoing] = useState(baseOngoing);

  useEffect(() => {
    if (ongoing) {
      clearErrors("dateEnd");
    }
  }, [clearErrors, ongoing]);

  const { t }: any = useTranslation();

  const [minEndDate, setMinEndDate] = useState<any>(null);

  useEffect(() => {
    const subscription = watch((value: any) => {
      setMinEndDate(moment(value.dateStart));
      setOngoing(value.ongoing);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
    <div>
      <div style={{ display: "flex" }}>
        <LocalizationProvider locale={"fr"} dateAdapter={AdapterMoment}>
          <Controller
            control={control}
            name="dateStart"
            rules={{
              required: { value: true, message: "errors.required" },
              validate: {
                time: (value) =>
                  new Date(value) <= new Date() ? true : "ERRORTIME",
              },
            }}
            render={({
              field: { onChange, name, value },
              formState: { errors },
            }) => (
              <MobileDatePicker
                cancelText={t("btn.cancel")}
                label={t("experience.dateStart")}
                value={value ? value : null}
                maxDate={moment(Date.now())}
                onChange={(date: any) => {
                  onChange(date?.isValid ? date._d : undefined);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors?.dateStart)}
                    helperText={t(errors?.dateStart?.message ?? " ")}
                  />
                )}
              />
            )}
          />

          <div style={{ width: "20px" }} />

          <Controller
            control={control}
            name="dateEnd"
            rules={{
              required: { value: !ongoing, message: "errors.required" },
              validate: {
                time: (value) =>
                  getValues("ongoing")
                    ? true
                    : new Date(value) > new Date(getValues("dateStart"))
                    ? true
                    : "errors.dateEnd",
              },
            }}
            render={({
              field: { onChange, name, value },
              formState: { errors },
            }) => (
              <MobileDatePicker
                cancelText={t("btn.cancel")}
                label={t("experience.dateEnd")}
                value={value && !ongoing ? value : null}
                disabled={ongoing}
                minDate={minEndDate}
                onChange={(date: any) => {
                  onChange(date?.isValid ? date._d : undefined);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!ongoing && Boolean(errors.dateEnd)}
                    helperText={
                      !ongoing ? t(errors?.dateEnd?.message) ?? " " : " "
                    }
                  />
                )}
              />
            )}
          />
        </LocalizationProvider>
      </div>
      <FormGroup sx={{ mt: "-12px" }}>
        <FormControlLabel
          control={
            <Controller
              control={control}
              name="ongoing"
              render={({ field: { onChange, value } }) => (
                <Switch
                  checked={value}
                  onChange={(e) => onChange(e.target.checked)}
                  {...label}
                />
              )}
            />
          }
          label={t(`${training ? "training" : "experience"}.ongoing`)}
        />
      </FormGroup>
    </div>
  );
};

export default ExperienceFormDate;
