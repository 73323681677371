import { AxiosResponse } from "axios";
import { BehaviorSubject, Observable, tap } from "rxjs";
import { WorkItem } from "../@types/WorkItem";
import store from "../store/store";
import { toastError } from "../utils/toasts";
import http from "./http";

export interface WorksParams {
  filters: string[] | string;
  page: number;
  blacklist: number;
  needle: string;
  bookmark?: boolean;
  video?: boolean;
  tension?: string;
  category?: string;
  studyLevel?: any[];
  mood?: any;
}

const filters = ["dynamics"];
const localCompleteness =
  JSON.parse(localStorage.getItem("completeness") ?? "{}") || [];
localCompleteness.nbSkillsTransversal > 0 && filters.push("transversal_skills");
localCompleteness.nbSkills > 0 && filters.push("skills");
!localCompleteness.hasDynamic &&
  filters[0] === "dynamics" &&
  filters.splice(0, 1);
class worksService {
  static baseParams: WorksParams = {
    filters,
    page: 1,
    blacklist: 0,
    needle: "",
    bookmark: false,
    ...(localCompleteness?.hasMood ? { mood: [3, 2, 1, 0] } : {}),
  };

  static params: WorksParams = { ...this.baseParams };
  static list: WorkItem[] = [];
  static categories: any = [];
  static studyLevels: any = [];
  static draftParams: WorksParams = { ...this.baseParams };

  static _loader = new BehaviorSubject(true);
  static _params = new BehaviorSubject(this.params);
  static _list = new BehaviorSubject(this.list);
  static _categories = new BehaviorSubject(this.categories);
  static _studyLevels = new BehaviorSubject(this.studyLevels);
  static _draftParams = new BehaviorSubject(this.draftParams);

  static mood: any = null;
  static _mood = new BehaviorSubject(this.mood);

  static firstLoad = true;
  static _firstLoad = new BehaviorSubject(this.firstLoad);

  static init() {
    this.firstLoad = true;
    this._firstLoad.next(this.firstLoad);
    this.mood = null;
    this._mood.next(this.mood);
    const filters = ["dynamics"];
    const localCompleteness =
      JSON.parse(localStorage.getItem("completeness") ?? "{}") || [];
    localCompleteness.nbSkillsTransversal > 0 &&
      filters.push("transversal_skills");
    localCompleteness.nbSkills > 0 && filters.push("skills");
    !localCompleteness.hasDynamic &&
      filters[0] === "dynamics" &&
      filters.splice(0, 1);

    this.baseParams = {
      ...this.baseParams,
      filters,
      ...(localCompleteness?.hasMood
        ? { mood: localCompleteness.hasDynamic ? [3, 2, 1, 0] : [3, 2] }
        : {}),
    };

    !localCompleteness.hasMood && delete this.baseParams.mood;

    this.draftParams = { ...this.baseParams };
    this._draftParams.next(this.draftParams);
    this.params = { ...this.baseParams };
    this._params.next({ ...this.params });
  }

  static getCategories() {
    http.get("work_categories?pagination=false").subscribe({
      next: (res) => {
        this.categories = res.data["hydra:member"];
        this._categories.next(this.categories);
      },
      error: (err) => {},
    });
  }

  static getStudyLevels() {
    http.get("referentials?type=studyLevel").subscribe({
      next: (res) => {
        this.studyLevels =
          res.data["hydra:member"]?.sort(
            (a: any, b: any) => a?.code - b?.code
          ) ?? [];
        this._studyLevels.next(this.studyLevels);
      },
      error: (err) => {},
    });
  }

  static initMood() {
    return http.get("tests/mood/result").pipe(
      tap({
        next: (res) => {
          this.mood = res;
          this._mood.next(res);
        },
        error: (err) => {},
      })
    );
  }

  static updateDraft(params: Partial<WorksParams>) {
    params.page = 1;
    this.draftParams = { ...this.draftParams, ...params };

    if (!this.draftParams.video) delete this.draftParams.video;
    if (!this.draftParams.mood || this.draftParams.mood?.length === 0)
      delete this.draftParams.mood;
    if (!this.draftParams.tension) delete this.draftParams.tension;
    if (!this.draftParams.category) delete this.draftParams.category;
    if (
      !this.draftParams.studyLevel ||
      this.draftParams.studyLevel?.length === 0
    )
      delete this.draftParams.studyLevel;
    this._draftParams.next(this.draftParams);
  }

  static undoDraft() {
    this.draftParams = this.params;
    this._draftParams.next(this.draftParams);
  }

  static applyDraft() {
    this.updateParams(this.draftParams);
  }

  /**
   * Update the parameters
   * NOT used to update the page param
   * @param params parameters to update
   */
  static updateParams(params: Partial<WorksParams>) {
    params.page = 1;
    this.params = { ...this.params, ...params };
    if (!this.draftParams.bookmark) delete this.params.bookmark;
    if (!this.draftParams.video) delete this.params.video;
    if (!this.draftParams.mood || this.draftParams.mood?.length === 0)
      delete this.params.mood;
    if (!this.draftParams.tension) delete this.params.tension;
    if (!this.draftParams.category) delete this.params.category;
    this.draftParams = this.params;
    this._draftParams.next(this.draftParams);
    this._params.next(this.params);
  }

  /**
   * Set the page param
   * @param page page number
   */
  static setPage(page: number) {
    this.params = { ...this.params, page };
    this._params.next(this.params);
  }

  /**
   * Get the list with the service params
   * @returns http request as observable
   */
  static get(): Observable<any> {
    this._firstLoad.next(this.firstLoad);
    if (this.firstLoad) {
      this.firstLoad = false;
      this._loader.next(false);
      return new BehaviorSubject(null);
    }

    const localCompleteness = JSON.parse(
      localStorage.getItem("completeness") ?? "{}"
    );

    this._loader.next(true);

    const params = { ...this.params };

    params.filters = JSON.stringify(params.filters);
    if (!params.bookmark) delete params.bookmark;
    if (!params.video) delete params.video;
    if (params.mood?.length === 0) delete params.mood;
    if (!params.tension) delete params.tension;
    if (!params.category) delete params.category;
    if (!params.studyLevel || params.studyLevel?.length === 0)
      delete params.studyLevel;
    else params.studyLevel = params.studyLevel.map((e) => e.code);

    if (params.mood) params.mood = JSON.stringify(params.mood);

    if (!localCompleteness.hasMood) delete params.mood;

    return http.get("works/list", { params }).pipe(
      tap({
        next: (res) => {
          this.list = res.data["hydra:member"];
          this._list.next(this.list);
        },
        error: () => {
          this._loader.next(false);
          toastError();
        },
        complete: () => this._loader.next(false),
      })
    );
  }

  /**
   * Reset matching
   */
  static resetMatching() {
    const params = { ...this.params, filters };

    const completeness = JSON.parse(
      localStorage.getItem("completeness") ?? "{}"
    );
    if (completeness.hasMood) params.mood = [3, 2, 1, 0];
    else delete params.mood;

    this.params = { ...params };
    this.draftParams = { ...params };
    this._draftParams.next(this.draftParams);
    this._params.next(this.params);
  }

  /**
   * Reset filters
   */
  static resetFilters() {
    this.params = {
      filters: this.params.filters,
      page: 1,
      blacklist: this.params.blacklist,
      needle: this.params.needle,
      bookmark: this.params.bookmark,
      ...(this.params.mood ? { mood: this.params.mood } : {}),
    };
    this.draftParams = {
      filters: this.draftParams.filters,
      page: 1,
      blacklist: this.draftParams.blacklist,
      needle: this.draftParams.needle,
      bookmark: this.draftParams.bookmark,
      ...(this.draftParams.mood ? { mood: this.draftParams.mood } : {}),
    };
    this._draftParams.next(this.draftParams);
    this._params.next(this.params);
  }

  /**
   * Reset params & list
   */
  static reset() {
    this.list = [];
    this._list.next([]);
    this.params = { ...this.baseParams };
    this.draftParams = { ...this.baseParams };
    this._draftParams.next(this.draftParams);
    this._params.next(this.params);
  }

  /**
   * Toggle isBookmarked for the corresponding WorkItem
   * @param id work id
   */
  static toggleBookmark(id: string): Observable<AxiosResponse> | null {
    const index = this.list.findIndex((el) => el.id === id);

    if (index === -1) return null;

    const work = this.list[index];

    const state: any = store.getState();
    const personId: string = state.user.person_id;

    const api = work.isBookmarked
      ? `people/${personId}/remove-bookmarks`
      : `people/${personId}/add-bookmarks`;

    const body = {
      items: [work["@id"]],
    };

    return http.post(api, body).pipe(
      tap({
        next: () => {
          const list = [...this.list];
          list[index].isBookmarked = !work.isBookmarked;
          this._list.next(list);
        },
        error: () => {
          toastError();
        },
      })
    );
  }
}

export default worksService;
