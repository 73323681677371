import axios from "axios";

export class Api {
  endpoint: string;
  constructor(public url: string) {
    this.endpoint = url;
  }

  private setParams(url: string, params: any) {
    let value = `${url}`;
    const keys = Object.keys(params);
    if (keys.length > 0) value += "?";
    keys.forEach((key, index) => {
      value += `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}${
        index < keys.length - 1 ? "&" : ""
      }`;
    });
    return value;
  }

  get(params?: any) {
    return params ? this.setParams(this.endpoint, params) : this.endpoint;
  }

  getById(id: string, params?: any) {
    return params
      ? this.setParams(`${this.endpoint}/${id}`, params)
      : `${this.endpoint}/${id}`;
  }

  delete(id: string, params?: any) {
    return axios.delete(
      params
        ? this.setParams(`${this.endpoint}/${id}`, params)
        : `${this.endpoint}/${id}`
    );
  }

  post(data?: any, params?: any) {
    return axios.post(
      params ? this.setParams(this.endpoint, params) : this.endpoint,
      data
    );
  }

  put(data: any, params?: any) {
    return axios.put(
      params ? this.setParams(this.endpoint, params) : this.endpoint,
      data
    );
  }

  patch(id: string, data: any, params?: any) {
    return axios.patch(
      params
        ? this.setParams(`${this.endpoint}/${id}`, params)
        : `${this.endpoint}/${id}`,
      data
    );
  }
}
