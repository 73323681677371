import { styled } from "@mui/material";
import React, { useState } from "react";
import Big5 from "../../components/big5/Big5";
import { pageWithNavbar } from "../../globalConfig";
import Page from "../../layouts/Page";
import Big5PageHeader from "./Big5PageHeader";

const Content = styled("div")`
  background: white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: 100%;
  height: 100%;
  margin-top: -70px;
  min-height: 120px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const Big5Page: React.FC<{ webapp?: boolean }> = ({ webapp }) => {
  const [state, setState] = useState(-1);

  return (
    <Page
      title="pages.big5"
      style={{
        ...(webapp
          ? { background: "white", minHeight: "100vh" }
          : pageWithNavbar),
        paddingBottom: 12,
      }}
    >
      <Big5PageHeader menu={!webapp} />
      <Content
        style={{
          ...(state === 1 ? { paddingLeft: "10px", paddingRight: "10px" } : {}),
        }}
      >
        <Big5 state={state} setState={setState} />
      </Content>
    </Page>
  );
};

export default Big5Page;
