import experiencesService from "../../services/experiences.service";
import axios from "axios";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import CareerItem from "../../@types/CareerItem";

export const SET_EXPERIENCES = "SET_EXPERIENCES";

export const setExperiences = (payload: any[]) => ({
  type: SET_EXPERIENCES,
  payload,
});

export const initExperiences = (): ThunkAction<
  void,
  any,
  unknown,
  AnyAction
> => {
  return async (dispatch, getState) => {
    try {
      experiencesService.loading.next(true);
      const careerItems: CareerItem[] = (
        await axios.get(`people/${getState().user.person_id}/career_items`)
      ).data.careerItems;
      dispatch(setExperiences(careerItems));
    } catch (err) {
    } finally {
      experiencesService.loading.next(false);
    }
  };
};

export const addExperience =
  (careerItem: CareerItem): ThunkAction<void, any, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const experiences = [...getState().experiences.experiences];

    experiences.push(careerItem);

    experiences.sort((a, b) =>
      new Date(a.dateStart) < new Date(b.dateStart) ? 1 : -1
    );

    dispatch(setExperiences(experiences));
  };

export const updateExperience =
  (careerItem: CareerItem): ThunkAction<void, any, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const experiences = [...getState().experiences.experiences];

    const index = experiences.findIndex((el: any) => el.id === careerItem.id);

    experiences[index] = careerItem;

    experiences.sort((a, b) =>
      new Date(a.dateStart) < new Date(b.dateStart) ? 1 : -1
    );

    dispatch(setExperiences(experiences));
  };

export const deleteExperience =
  (id: string): ThunkAction<void, any, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const experiences = [...getState().experiences.experiences];

    const index = experiences.findIndex((el: any) => el.id === id);

    experiences.splice(index, 1);

    experiences.sort((a, b) =>
      new Date(a.dateStart) < new Date(b.dateStart) ? 1 : -1
    );

    dispatch(setExperiences(experiences));
  };
