import { styled } from "@mui/material";
import React from "react";
import PageHeader from "../../components/common/PageHeader";
import { useTranslation } from "react-i18next";

const Root = styled("div")`
  background-color: #6c7882;
  background-image: url("/static/backgrounds/lake.jpg");
  background-size: cover;
  background-position: center;
  padding-top: 30px;
  padding-bottom: 100px;
`;

const Big5PageHeader: React.FC<{ menu?: boolean }> = ({ menu = true }) => {
  const { t } = useTranslation();
  return (
    <Root>
      <PageHeader
        menu={menu}
        title={t("pages.big5")}
        subtitle={t("big5.card_desc")}
      />
    </Root>
  );
};

export default Big5PageHeader;
