import React from "react";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import styled from "@emotion/styled";
import { Modal, Typography, IconButton } from "@mui/material";
import ModalBody from "../common/ModalBody";
import ProfileEditForm from "./ProfileEditForm";
import { useTranslation } from "react-i18next";

const Header = styled("div")`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ProfileEditModal: React.FC<any> = ({ open, handleClose }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalBody>
          <Header>
            <Typography variant="h5">{t("profile_modal.title")}</Typography>

            <IconButton sx={{ ml: "auto" }} onClick={handleClose}>
              <CancelRoundedIcon color="primary" />
            </IconButton>
          </Header>

          <ProfileEditForm handleClose={handleClose} />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ProfileEditModal;
