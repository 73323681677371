import en from "./locales/en.json";
import fr from "./locales/fr.json";
import i18next from "i18next";
import { mergeDeep } from "./utils/utils";

const locales = ["fr", "en"];

const nav = navigator as any;
let lng = (
  localStorage.getItem("lng") ||
  nav.language ||
  nav["userLanguage"] ||
  "fr"
).split("-")[0];

if (!locales.includes(lng)) lng = locales[0];

if (!localStorage.getItem("lng")) localStorage.setItem("lng", lng);

const resources = {
  fr: {
    translation: fr,
    // translation: {},
  },
  en: {
    translation: en,
    // translation: {},
  },
};

export const i18nInit = {
  interpolation: { escapeValue: false },
  lng,
  resources,
};

export const seti18n = (labels?: any) => {
  let config: any = { ...i18nInit };

  // if (labels) {
  //   console.log("labels", labels);
  //   const resources = { ...config.resources };
  //   const merged = mergeDeep(resources, {
  //     fr: { translation: labels.webappFR ?? {} },
  //     en: { translation: labels.webappEN ?? {} },
  //   });
  //   config.resources = merged;
  // }

  i18next.init(config);
};

// ! Uncomment line 40 - 47 and replace 22 and 26
