import { Box, ButtonBase, styled, Typography } from "@mui/material";
import React, { useState } from "react";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import ExperienceModal from "../../components/experience/ExperienceModal";
import PageHeader from "../../components/common/PageHeader";
import { useSelector } from "react-redux";
import PEConnectButton from "../../components/auth/peconnect/PEConnectButton";
import { useTranslation } from "react-i18next";

const Root = styled("div")`
  background-color: #113f34;
  background-image: url("/static/backgrounds/forest.jpg");
  background-size: cover;
  background-position: center;
  padding-top: 30px;
  padding-bottom: 150px;
`;

const AddButton = styled(ButtonBase)`
  background: #eceef7;
  height: 62px;
  width: 350px;
  border-radius: 16px;
  display: flex;
  padding: 25px;
`;

const ExperiencesPageHeader: React.FC = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { t } = useTranslation();
  const url2 = useSelector((state: any) => state.home?.check_saml?.url2);

  return (
    <Root>
      <PageHeader
        title={t("pages.experiences")}
        subtitle={t("experience.subtitle")}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <AddButton onClick={handleOpen}>
          <Typography sx={{ fontWeight: "bold", color: "#505887" }}>
            {t("experience.add")}
          </Typography>
          <AddCircleRoundedIcon color="primary" sx={{ marginLeft: "auto" }} />
        </AddButton>

        {url2 && (
          <Box sx={{ mt: 2 }}>
            <PEConnectButton link />
          </Box>
        )}
      </div>

      <ExperienceModal {...{ open, handleClose }} />
    </Root>
  );
};

export default ExperiencesPageHeader;
