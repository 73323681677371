import { styled } from "@mui/material";
import React from "react";
import SkillsList from "../../components/skills/SkillsList";
import SkillsPageHeader from "../../components/skills/SkillsPageHeader";
import { pageWithNavbar } from "../../globalConfig";
import Page from "../../layouts/Page";

const Content = styled("div")`
  background: white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: 100%;
  margin-top: -70px;
  min-height: 120px;
  padding: 30px;
`;

const SkillsPage = ({ transversal = false }) => {
  return (
    <Page title="pages.skills" style={{ ...pageWithNavbar }}>
      <SkillsPageHeader transversal={transversal} />
      <Content>
        <SkillsList transversal={transversal} />
      </Content>
    </Page>
  );
};

export default SkillsPage;
