import { SET_USER } from "../actions/userActions";
import initialState from "../initialState";

export const userReducer = (state = initialState.user, action: any) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...action.value,
      };
    default:
      return { ...state };
  }
};
