import { SET_LOGGED } from "../actions/authActions";
import initialState from "../initialState";

export const authReducer = (state = initialState.auth, action: any) => {
  switch (action.type) {
    case SET_LOGGED:
      return {
        ...state,
        isLogged: action.value,
      };
    default:
      return { ...state };
  }
};
