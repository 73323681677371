import { Skeleton, Stack, styled } from "@mui/material";
import React from "react";

const GroupLabel = styled(Skeleton)`
  height: 34px;
  border-radius: 8px;
  width: 200px;
`;

const SkillSkeleton = styled(Skeleton)`
  border-radius: 16px;
  height: 80px;
`;

const SkillsGroupSkeleton: React.FC = () => {
  return (
    <div>
      <Stack spacing={2} sx={{ mb: 2 }}>
        <GroupLabel variant="rectangular" />

        {[1, 2, 3].map((e) => (
          <SkillSkeleton variant="rectangular" key={e} />
        ))}
      </Stack>
    </div>
  );
};

export default SkillsGroupSkeleton;
