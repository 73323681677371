import { Box, Button, styled, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSpring, animated as a } from "react-spring";

type MoodIntroProps = {
  start: () => void;
  hasLoaded: boolean;
};

const Root = styled(a.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const STitle = styled(Typography)`
  background-color: #fdf5f2;
  padding: 5px 10px;
  border-radius: 8px;
  margin-top: 20px;
  margin-bottom: 31px;
`;

const SButton = styled(Button)`
  width: 280px;
  height: 69px;
  font-size: 14px;
  margin-top: 20px;
  background: transparent linear-gradient(80deg, #dd736a 0%, #e8a100 100%) 0% 0%
    no-repeat padding-box;
  font-weight: bold;
`;

const MoodIntro: React.FC<MoodIntroProps> = ({ start, hasLoaded }) => {
  const [next, setNext] = useState(false);
  const handleStart = () => setNext(true);
  const { t } = useTranslation();
  const duration = 300;
  const styles = useSpring({
    to: { opacity: next ? 0 : 1 },
    from: { opacity: next ? 1 : 0 },
    duration,
  });

  useEffect(() => {
    let to: NodeJS.Timeout;
    if (next && hasLoaded) {
      to = setTimeout(() => {
        start();
      }, duration);
    }
    return () => {
      if (to) clearTimeout(to);
    };
  }, [next, hasLoaded, start]);

  return (
    <Root style={styles}>
      {next && !hasLoaded ? (
        <Typography>{t("mood.loading")}</Typography>
      ) : (
        <>
          <STitle variant="subtitle1">{t("mood.introduction")}</STitle>
          <Box sx={{ maxWidth: "80ch" }}>
            <Typography mb={2} fontWeight="bold">
              {t("mood.intro")}
            </Typography>
            <Typography mb={2}>{t("mood.intro_2")}</Typography>
            <Typography mb={2} fontWeight="bold" color="primary">
              {t("mood.on_you")}
            </Typography>
          </Box>
          <SButton variant="contained" onClick={handleStart}>
            {t("mood.begin_test")}
          </SButton>
        </>
      )}
    </Root>
  );
};

export default MoodIntro;
