import { FormControl, FormHelperText } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";

const ControlledPhoneInput: React.FC<any> = ({
  control,
  rules = {},
  errors,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <FormControl sx={{ width: "100%" }}>
        <Controller
          control={control}
          name="phone"
          rules={rules}
          render={({ field }) => (
            <>
              <PhoneInput
                {...field}
                country="fr"
                specialLabel={t("form.phone")}
                placeholder="+33 6 07 08 09 10"
                searchStyle={{ width: "100% !important" }}
                isValid={!Boolean(errors.phone)}
                onChange={(e) => field.onChange(`+${e}`)}
              />
            </>
          )}
        />
        <FormHelperText error>
          {errors?.phone?.message ? t(errors?.phone?.message) : " "}
        </FormHelperText>
      </FormControl>
    </div>
  );
};

export default ControlledPhoneInput;
