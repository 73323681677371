import { Stack, Pagination, styled, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SkillsListEntity } from "../../@types/SkillCategoryGroup";
import skillsService from "../../services/skills.service";
import { map } from "rxjs";
import SkillsSuggestListItem from "./SkillsSuggestListItem";
import AutocompleteComponent from "../common/AutocompleteComponent";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const Option = styled("li")(({ theme }) => ({
  padding: theme.spacing(1),
  "&.active": {
    background: theme.palette.success.light,
  },
}));

const SkillsSuggestList: React.FC<{
  suggests: Partial<SkillsListEntity>[];
  transversal?: boolean;
}> = ({ suggests, transversal }) => {
  const [list, setList] = useState(suggests.slice(0, 10));
  const [page, setPage] = useState(1);
  const [addListIds, setAddListIds] = useState<string[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    setList(suggests.slice(0, 10));
    setPage(1);
  }, [suggests]);

  useEffect(() => {
    const sub = skillsService._addList
      .pipe(map((x) => x.map((x) => x.id ?? "-")))
      .subscribe((e) => setAddListIds(e));
    return () => {
      sub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const el = document.querySelector(".skills-modal-body");
    el?.scrollTo({ top: 0, left: 0 });
    setList(suggests.slice((page - 1) * 10, page * 10));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handlePageChange = (e: any, value: number) => {
    setPage(value);
  };

  const autoAdd = (e: any) => {
    skillsService.toggleFromQueue(e);
  };

  const renderOption = (props: any, option: any) => {
    const active = addListIds.includes(option.id);
    return (
      <Option {...props} className={clsx(active && "active")}>
        <span>{option?.label}</span>
      </Option>
    );
  };

  return (
    <div>
      {addListIds.length > 0 && (
        <Typography sx={{ mb: 1 }} textAlign="center">
          {addListIds.length} {transversal ? "aptitude" : "compétence"}
          {addListIds.length > 1 && "s"} à évaluer
        </Typography>
      )}
      <AutocompleteComponent
        placeholder={t("common.search")}
        sendDataToParent={autoAdd}
        clearOnSelect
        type="skills"
        renderOption={renderOption}
      />

      <Stack spacing={2}>
        {list.length === 0 ? (
          <Typography>
            {t(`skills.no_suggest_${transversal ? "abilities" : "skills"}`)}
          </Typography>
        ) : (
          list.map((el) => (
            <SkillsSuggestListItem
              skill={el}
              key={el.id}
              active={el?.id ? addListIds.includes(el.id) : false}
            />
          ))
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "25px",
          }}
        >
          <Pagination
            page={page}
            onChange={handlePageChange}
            count={Math.ceil(suggests.length / 10)}
            size="small"
          />
        </div>
      </Stack>
    </div>
  );
};

export default SkillsSuggestList;
