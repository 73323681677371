import { ButtonBase, IconButton, styled } from "@mui/material";
import React, { useLayoutEffect, useRef, useState } from "react";
import { getCookie, setCookies } from "../utils/utils";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { useTranslation } from "react-i18next";
// const navigator = window.navigator as any;

const InstallButton = styled(ButtonBase)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: "bold",
  paddingLeft: "10px",
  paddingRight: "10px",
  display: "flex",
  alignItems: "center",
}));

const A2HS = () => {
  const { t } = useTranslation();
  const pwaButtonRef = useRef<any>(null);
  const [hasCookie, setHasCookie] = useState(getCookie("A2HS"));
  const [promptRegisteted, setPromptRegistered] = useState(false);

  // const showInstallPromotion = () => {
  //   console.log("Show");
  // };

  const refuseWebapp = () => {
    setHasCookie("cancelled");
    setCookies("A2HS", "cancelled");
  };

  useLayoutEffect(() => {
    let A2HSCookie = getCookie("A2HS");

    let deferredPrompt: any;
    const addBtn = pwaButtonRef.current;

    const fnc = (e: any) => {
      A2HSCookie = getCookie("A2HS");
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;

      setPromptRegistered(true);

      if (!A2HSCookie) {
        addBtn.style.display = "flex";
      }

      addBtn.addEventListener("click", (e: any) => {
        setCookies("A2HS", "clicked");
        setHasCookie("clicked");
        // hide our user interface that shows our A2HS button

        addBtn.style.display = "none";

        // Show the prompt
        deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        deferredPrompt.userChoice.then((choiceResult: any) => {
          if (choiceResult.outcome === "accepted") {
            setCookies("A2HS", "accepted");
            // console.log("User accepted the A2HS prompt");
          } else {
            setCookies("A2HS", "cancelled");
            // console.log("User dismissed the A2HS prompt");
          }
          deferredPrompt = null;
        });
      });

      // Update UI notify the user they can install the PWA
      // showInstallPromotion();
      // Optionally, send analytics event that PWA install promo was shown.
      // console.log(`'beforeinstallprompt' event was fired.`);
    };

    window.addEventListener("beforeinstallprompt", fnc);

    // -------------

    // function getPWADisplayMode() {
    //   const isStandalone = window.matchMedia(
    //     "(display-mode: standalone)"
    //   ).matches;
    //   if (document.referrer.startsWith("android-app://")) {
    //     return "twa";
    //   } else if (navigator.standalone || isStandalone) {
    //     return "standalone";
    //   }
    //   return "browser";
    // }

    // console.log(getPWADisplayMode());

    return () => {
      if (A2HSCookie && addBtn)
        window.removeEventListener("beforeinstallprompt", fnc);
    };
  }, [pwaButtonRef]);

  return (
    <div
      className="prompt-popup"
      style={{
        display: !hasCookie && promptRegisteted ? "flex" : "none",
        position: "fixed",
        top: 0,
        left: "50%",
        transform: "translateX(-50%)",
        height: "50px",
        width: "100%",
        maxWidth: "540px",
        zIndex: 10,
        background: "white",
      }}
    >
      {/* <button
        ref={pwaButtonRef}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 1000,
          display: "none",
        }}
        className="add-button"
      >
        Add to home screen
      </button> */}
      <InstallButton ref={pwaButtonRef}>
        <img
          alt="logo"
          src="/static/path_to_work_logo.svg"
          style={{
            height: "32px",
            marginRight: "10px",
          }}
        />
        <span>{t("add_webapp")}</span>
      </InstallButton>

      <IconButton sx={{ ml: "auto" }} onClick={refuseWebapp}>
        <CancelRoundedIcon color="primary" />
      </IconButton>
    </div>
  );
};

export default A2HS;
