import React from "react";
import { animated } from "@react-spring/web";
import { styled, Typography } from "@mui/material";

const Card = styled(animated.div)`
  position: absolute;
  height: 275px;
  width: 210px;
  border-radius: 30px;
  background-color: white;
  background-position: center;
  background-size: cover;
  transition: opacity 1s ease;
  transition: top 0.5s ease;
  top: 0;

  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.23);
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.23);

  cursor: grab;
  touch-action: none;
  -webkit-user-select: none;
  user-select: none;
`;

const TextBox = styled("div")`
  background: transparent linear-gradient(180deg, #dd736a 0%, #e8a100 100%) 0%
    0% no-repeat padding-box;
  border-radius: 15px;
  padding: 10px;
  margin: 10px;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  position: absolute;
  bottom: 15px;
`;

const Overlay = styled("div")`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255, 53%);
  transition: opacity 0.5s ease;
`;

const OverlayDark = styled("div")`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(0, 0, 0, 14%);
`;

const SIcon = styled("div")`
  position: absolute;
  top: 20px;
  border: 4px solid;
  padding: 10px;
  transform: rotate(30deg);
  font-size: 20px;
  transition: opacity 0.2s ease;
  border-radius: 8px;
`;

const MoodSwipeCard: React.FC<any> = ({
  style,
  text,
  current,
  background,
  overlayDir,
  ...props
}) => {
  return (
    <Card
      style={{ ...style, backgroundImage: `url(${background})` }}
      {...props}
    >
      <OverlayDark>
        <TextBox>
          <Typography
            sx={{ fontWeight: "bold", fontSize: "16px", lineHeight: "18px" }}
            component="span"
          >
            {text}
          </Typography>
        </TextBox>
      </OverlayDark>

      <Overlay style={{ opacity: current ? 0 : 1 }} />
      <SIcon
        style={{
          right: 20,
          borderColor: "#30cc84",
          transform: "rotate(30deg)",
          opacity: overlayDir > 0.3 ? Math.abs(overlayDir) / 1.2 : 0,
        }}
      >
        <img src="/static/mood/heart.svg" alt="green heart" />
      </SIcon>

      <SIcon
        style={{
          left: 20,
          borderColor: "#ff6465",
          transform: "rotate(-30deg)",
          opacity: overlayDir < -0.3 ? Math.abs(overlayDir) / 1.2 : 0,
        }}
      >
        <img src="/static/mood/cross.svg" alt="red mark" />
      </SIcon>
    </Card>
  );
};

export default MoodSwipeCard;
