import { FormControl } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import LocationAutocomplete from "../../common/LocationAutocomplete";

const ExperienceFormLocation: React.FC<any> = ({ control }) => {
  return (
    <FormControl>
      <Controller
        name="location"
        control={control}
        render={({
          field: { onChange, value, onBlur },
          formState: { errors },
        }) => (
          <LocationAutocomplete baseValue={value} sendDataToParent={onChange} />
        )}
      />
    </FormControl>
  );
};

export default ExperienceFormLocation;
