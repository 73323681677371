import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CareerItem from "../@types/CareerItem";
import experiencesService from "../services/experiences.service";

const useInitExperiences = (): {
  loading: boolean;
  experiences: CareerItem[];
} => {
  const [loading, setLoading] = useState(true);
  const experiences: CareerItem[] = useSelector((state: any) =>
    state.experiences.experiences.filter((e: any) => e.type === "experience")
  );

  useEffect(() => {
    const loadingSub = experiencesService.init(setLoading);
    return () => {
      loadingSub.unsubscribe();
    };
  }, []);
  return { loading, experiences };
};
export default useInitExperiences;
