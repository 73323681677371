import { Divider, Stack, styled, Typography } from "@mui/material";
import React from "react";
import LogoutButton from "../../layouts/navbar/LogoutButton";
import NotificationsNoneRoundedIcon from "@mui/icons-material/NotificationsNoneRounded";
import { NavLink } from "react-router-dom";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import ChatBubbleOutlineRoundedIcon from "@mui/icons-material/ChatBubbleOutlineRounded";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import drawer from "../../services/drawer.service";
import { useSelector } from "react-redux";
import FeedbackButton from "../feedback/FeedbackButton";
import { useTranslation } from "react-i18next";

const NavItem = styled(NavLink)`
  display: flex;
  text-decoration: none;
  align-items: center;
  color: #b4b4b4;
  &.active {
    color: #505887;
  }
  line-height: 0px;
  font-weight: bold;
`;

const NotifIconContainer = styled("div")`
  position: relative;
`;

const Dot = styled("div")`
  position: absolute;
  width: 10px;
  height: 10px;
  background: #ff7272;
  border-radius: 50%;
  left: 0;
  top: 0;
`;

const NavItemText = styled(Typography)`
  font-weight: bold;
  font-size: 18px;
`;

const CustomDivider = styled(Divider)`
  width: 180px;
`;

const ProfileDrawerMenu: React.FC = () => {
  const { t } = useTranslation();
  const notifsCount = useSelector((state: any) => state.notificationsCount);
  const supportMail = useSelector(
    (state: any) =>
      state.home?.paramjson?.supportMail ?? "contact@monkey-tie.com"
  );

  return (
    <Stack spacing={2} sx={{ mt: 2 }}>
      <NavItem onClick={() => drawer.close()} to="/app/notifications">
        <NotifIconContainer>
          <NotificationsNoneRoundedIcon sx={{ fontSize: "25px", mr: 1 }} />
          {notifsCount > 0 && <Dot />}
        </NotifIconContainer>
        <NavItemText>{t("menu.notifications")}</NavItemText>
      </NavItem>

      <NavItem onClick={() => drawer.close()} to="/app/chat">
        <NotifIconContainer>
          <ChatBubbleOutlineRoundedIcon sx={{ fontSize: "25px", mr: 1 }} />
          {notifsCount > 0 && <Dot />}
        </NotifIconContainer>
        <NavItemText>{t("menu.messenger")}</NavItemText>
      </NavItem>

      <CustomDivider />

      <NavItem onClick={() => drawer.close()} to="/app/experiences">
        <CheckCircleOutlineRoundedIcon sx={{ fontSize: "25px", mr: 1 }} />
        <NavItemText>{t("menu.my_experiences")}</NavItemText>
      </NavItem>

      <NavItem onClick={() => drawer.close()} to="/app/transversal_skills">
        <PsychologyOutlinedIcon sx={{ fontSize: "25px", mr: 1 }} />
        <NavItemText>{t("menu.my_abilities")}</NavItemText>
      </NavItem>

      <NavItem onClick={() => drawer.close()} to="/app/skills">
        <PsychologyOutlinedIcon sx={{ fontSize: "25px", mr: 1 }} />
        <NavItemText>{t("menu.my_skills")}</NavItemText>
      </NavItem>

      <NavItem onClick={() => drawer.close()} to="/app/my-trainings">
        <SchoolOutlinedIcon sx={{ fontSize: "25px", mr: 1 }} />
        <NavItemText>{t("menu.my_trainings")}</NavItemText>
      </NavItem>

      <CustomDivider />

      <NavItem onClick={() => drawer.close()} to="/app/parameters">
        <SettingsOutlinedIcon sx={{ fontSize: "25px", mr: 1 }} />
        <NavItemText>{t("menu.parameters")}</NavItemText>
      </NavItem>

      <CustomDivider />

      <LogoutButton />
      <CustomDivider />

      <div>
        <Typography sx={{ fontSize: "14px", color: "#505887", opacity: 0.56 }}>
          monkey tie
        </Typography>

        <Typography
          sx={{ fontSize: "14px", textDecoration: "none" }}
          component="a"
          color="secondary"
          href={`mailto:${supportMail}`}
        >
          {t("menu.contact_us")}
        </Typography>
      </div>
      <FeedbackButton />
    </Stack>
  );
};

export default ProfileDrawerMenu;
