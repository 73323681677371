import React, { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
// import { useSelector } from "react-redux";
import { useRoutes, Navigate } from "react-router-dom";
import Loader from "../components/loader/Loader";
import UserLayout from "../layouts/UserLayout";
import ExperiencesPage from "../pages/experiences/ExperiencesPage";
import LicensesPage from "../pages/LicensesPage";
import MoodPage from "../pages/mood/MoodPage";
import TransversalSkillsPage from "../pages/skills/TransversalSkillsPage";
import TrainingPage from "../pages/trainings/TrainingPage";

const Home = lazy(() => import("../pages/Home"));
const NotFound = lazy(() => import("../pages/NotFound"));
const ProfilePage = lazy(() => import("../pages/profile/ProfilePage"));
const WorksPage = lazy(() => import("../pages/works/WorksPage"));
// const InDeveloment = lazy(() => import("../pages/InDevelopment"));
const WorkPage = lazy(() => import("../pages/work/WorkPage"));
const SkillsPage = lazy(() => import("../pages/skills/SkillsPage"));
const HobbiesPage = lazy(() => import("../pages/HobbiesPage"));
const ResultsPage = lazy(() => import("../pages/ResultsPage"));
const ResultsListPage = lazy(() => import("../pages/ResultsListPage"));
const NotificationsPage = lazy(() => import("../pages/NotificationsPage"));
const MyTrainingsPage = lazy(
  () => import("../pages/MyTrainings/MyTrainingsPage")
);
const ParametersPage = lazy(() => import("../pages/ParametersPage"));
const TrainingsPage = lazy(() => import("../pages/trainings/TrainingsPage"));
const ChatPage = lazy(() => import("../pages/ChatPage"));
const Big5Page = lazy(() => import("../pages/big5/Big5Page"));

const UserRoutes = () => {
  const hasTrainings = useSelector((state: any) => state.user?.trainings);

  const completeness = JSON.parse(localStorage.getItem("completeness") ?? "{}");

  return useRoutes(
    [
      {
        path: "/app",
        element: <UserLayout />,
        children: [
          { path: "", element: <Navigate to="/app/home" replace /> },
          {
            path: "home",
            element: (
              <Suspense fallback={<Loader inProp={false} />}>
                <Home />
              </Suspense>
            ),
          },
          {
            path: "profile",
            element: (
              <Suspense fallback={<Loader inProp={false} />}>
                <ProfilePage />
              </Suspense>
            ),
          },
          {
            path: "experiences",
            element: (
              <Suspense fallback={<Loader inProp={false} />}>
                <ExperiencesPage />
              </Suspense>
            ),
          },
          {
            path: "works",
            element: (
              <Suspense fallback={<Loader inProp={false} />}>
                <WorksPage />
              </Suspense>
            ),
          },
          {
            path: "work/:id",
            element: (
              <Suspense fallback={<Loader inProp={false} />}>
                <WorkPage />
              </Suspense>
            ),
          },
          hasTrainings && {
            path: "trainings",
            element: (
              <Suspense fallback={<Loader inProp={false} />}>
                <TrainingsPage />
              </Suspense>
            ),
          },
          hasTrainings && {
            path: "training/:id",
            element: (
              <Suspense fallback={<Loader inProp={false} />}>
                <TrainingPage />
              </Suspense>
            ),
          },
          {
            path: "results",
            element: (
              <Suspense fallback={<Loader inProp={false} />}>
                <ResultsListPage />
              </Suspense>
            ),
          },
          {
            path: "skills",
            element: (
              <Suspense fallback={<Loader inProp={false} />}>
                <SkillsPage />
              </Suspense>
            ),
          },
          {
            path: "transversal_skills",
            element: (
              <Suspense fallback={<Loader inProp={false} />}>
                <TransversalSkillsPage />
              </Suspense>
            ),
          },
          {
            path: "my-trainings",
            element: (
              <Suspense fallback={<Loader inProp={false} />}>
                <MyTrainingsPage />
              </Suspense>
            ),
          },
          {
            path: "parameters",
            element: (
              <Suspense fallback={<Loader inProp={false} />}>
                <ParametersPage />
              </Suspense>
            ),
          },
          {
            path: "hobbies",
            element: (
              <Suspense fallback={<Loader inProp={false} />}>
                <HobbiesPage />
              </Suspense>
            ),
          },
          {
            path: "licenses",
            element: (
              <Suspense fallback={<Loader inProp={false} />}>
                <LicensesPage />
              </Suspense>
            ),
          },
          {
            path: "notifications",
            element: (
              <Suspense fallback={<Loader inProp={false} />}>
                <NotificationsPage />
              </Suspense>
            ),
          },
          {
            path: "chat",
            element: (
              <Suspense fallback={<Loader inProp={false} />}>
                <ChatPage />
              </Suspense>
            ),
          },
          {
            path: "mood",
            element: (
              <Suspense fallback={<Loader inProp={false} />}>
                <MoodPage />
              </Suspense>
            ),
          },
          completeness?.needBigFive && {
            path: "big5",
            element: (
              <Suspense fallback={<Loader inProp={false} />}>
                <Big5Page />
              </Suspense>
            ),
          },
          {
            path: "result",
            element: (
              <Suspense fallback={<Loader inProp={false} />}>
                <ResultsPage />
              </Suspense>
            ),
          },
          {
            path: "onboarding",
            element: <Navigate to="/app/home" replace />,
          },
          { path: "*", element: <Navigate to="/404" replace /> },
        ].filter((el) => el),
      },
      {
        path: "/404",
        element: (
          <Suspense fallback={<Loader inProp={false} />}>
            <NotFound />
          </Suspense>
        ),
      },
      { path: "/login", element: <Navigate to="/app/home" replace /> },
      { path: "/sign-up", element: <Navigate to="/app/home" replace /> },
      { path: "/", element: <Navigate to="/app/home" replace /> },
      {
        path: "*",
        element: <Navigate to="/404" replace />,
      },
    ].filter((el) => el)
  );
};

export default UserRoutes;
