import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import "./FeedbackReactionStyles.scss";

const FeedbackReactions = ({ activeMood, handleMood, error }: any) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<number>(activeMood);

  const handleReaction = (e: number) => {
    setValue(e);
    handleMood(e);
  };

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <ul className="feedback">
          <li
            className={`angry ${value === 1 ? "active" : ""}`}
            onClick={() => handleReaction(1)}
          >
            <div>
              <svg className="eye left">
                <use xlinkHref="#eye" />
              </svg>
              <svg className="eye right">
                <use xlinkHref="#eye" />
              </svg>
              <svg className="mouth">
                <use xlinkHref="#mouth" />
              </svg>
            </div>
          </li>
          <li
            className={`sad ${value === 2 ? "active" : ""}`}
            onClick={() => handleReaction(2)}
          >
            <div>
              <svg className="eye left">
                <use xlinkHref="#eye" />
              </svg>
              <svg className="eye right">
                <use xlinkHref="#eye" />
              </svg>
              <svg className="mouth">
                <use xlinkHref="#mouth" />
              </svg>
            </div>
          </li>
          <li
            className={`ok ${value === 3 ? "active" : ""}`}
            onClick={() => handleReaction(3)}
          >
            <div></div>
          </li>
          <li
            className={`good ${value === 4 ? "active" : ""}`}
            onClick={() => handleReaction(4)}
          >
            <div>
              <svg className="eye left">
                <use xlinkHref="#eye" />
              </svg>
              <svg className="eye right">
                <use xlinkHref="#eye" />
              </svg>
              <svg className="mouth">
                <use xlinkHref="#mouth" />
              </svg>
            </div>
          </li>
          <li
            className={`happy ${value === 5 ? "active" : ""}`}
            onClick={() => handleReaction(5)}
          >
            <div>
              <svg className="eye left">
                <use xlinkHref="#eye" />
              </svg>
              <svg className="eye right">
                <use xlinkHref="#eye" />
              </svg>
            </div>
          </li>
        </ul>

        <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
          <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 4" id="eye">
            <path d="M1,1 C1.83333333,2.16666667 2.66666667,2.75 3.5,2.75 C4.33333333,2.75 5.16666667,2.16666667 6,1"></path>
          </symbol>
          <symbol
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 7"
            id="mouth"
          >
            <path d="M1,5.5 C3.66666667,2.5 6.33333333,1 9,1 C11.6666667,1 14.3333333,2.5 17,5.5"></path>
          </symbol>
        </svg>
      </Box>
      {error && (
        <Typography variant="body2" color="error" sx={{ mt: 1, ml: 2 }}>
          {t("errors.required")}
        </Typography>
      )}
    </Box>
  );
};

export default FeedbackReactions;
