import { BehaviorSubject, forkJoin, map, tap } from "rxjs";
import store from "../store/store";
import http from "./http";

class licensesService {
  static licenses: any[] = [];
  static referential: any[] = [];

  static _referential = new BehaviorSubject<any[]>([]);

  static init() {
    const state = store.getState();

    const referential = http.get("referentials?type=driverLicense").pipe(
      map((x) => x.data["hydra:member"]),
      tap((e) => {
        e.forEach((f: any) => (f.active = false));
        this.referential = e;
      })
    );
    const licenses = http.get(`people/${state.user.person_id}`).pipe(
      map((x) => x.data.driverLicenses ?? []),
      tap((e) => {
        this.licenses = e;
      })
    );

    return forkJoin({
      referential,
      licenses,
    }).pipe(tap(() => this.handleResponse()));
  }

  private static handleResponse() {
    this.referential.forEach((el) => (el.active = false));

    this.licenses.forEach((license) => {
      this.referential[
        this.referential.findIndex((e) => e["@id"] === license["@id"])
      ].active = true;
    });

    const newArr: any[] = [];
    this.referential?.map((item: any) =>
      newArr.filter((x) => x.code === item.code.slice(0, 1)).length === 0
        ? newArr.push({
            code: item.code.slice(0, 1),
            list: [item],
          })
        : newArr[
            newArr.findIndex((x: any) => x.code === item.code.slice(0, 1))
          ]?.list.push(item)
    );

    this._referential.next(newArr);
  }

  static handleLicense(license: any) {
    const index = this.licenses.findIndex((el) => el["@id"] === license["@id"]);
    if (index === -1) {
      this.licenses.push({
        "@id": license["@id"],
        "@type": license["@type"],
        label: license.label,
      });
    } else {
      const cp = [...this.licenses];
      cp.splice(index, 1);
      this.licenses = cp;
    }

    const state = store.getState();

    return http
      .put(`people/${state.user.person_id}`, {
        driverLicenses: this.licenses.map((e) => e["@id"]),
      })
      .pipe(
        tap({
          next: () => this.handleResponse(),
        })
      );
  }
}

export default licensesService;
