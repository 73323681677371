import { styled } from "@mui/material";
import React from "react";
import ExperiencesList from "../../components/experience/ExperiencesList";
import { pageWithNavbar } from "../../globalConfig";
import Page from "../../layouts/Page";
import ExperiencesPageHeader from "./ExperiencesPageHeader";

const Content = styled("div")`
  background: white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: 100%;
  margin-top: -120px;
  min-height: 120px;
`;

const ExperiencesPage: React.FC = () => {
  return (
    <Page title="pages.experiences" style={{ ...pageWithNavbar }}>
      <ExperiencesPageHeader />

      <Content>
        <ExperiencesList />
      </Content>
    </Page>
  );
};

export default ExperiencesPage;
