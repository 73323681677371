import React, { useEffect } from "react";
import useBig5 from "../../hooks/useBig5";
import big5service from "../../services/big5Service";
import Loader from "../loader/Loader";
import Big5Intro from "./Big5Intro";
import Big5Results from "./results/Big5Results";
import Big5Test from "./test/Big5Test";

const Big5: React.FC<any> = ({ state, setState }) => {
  const { loading, results } = useBig5();

  useEffect(() => {
    const init = big5service.init().subscribe();
    return () => {
      init.unsubscribe();
    };
  }, []);

  useEffect(() => {
    setState(results?.results ? 2 : 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  const handleStart = () => {
    setState(1);
  };

  const handleEnd = () => {
    setState(2);
  };

  return (
    <div>
      {loading ? (
        <Loader inProp={true} noBackground />
      ) : (
        <>
          {state === 0 && <Big5Intro handleStart={handleStart} />}
          {state === 1 && <Big5Test nextStep={handleEnd} />}
          {state === 2 && <Big5Results results={results.results} />}
        </>
      )}
    </div>
  );
};

export default Big5;
