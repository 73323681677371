import { Box, Button, Stack, TextField } from "@mui/material";
import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
// import { toast } from "react-toastify";
import { zip } from "rxjs";
import http from "../../services/http";
import { setUser } from "../../store/actions/userActions";
import { toastError } from "../../utils/toasts";
import ControlledPhoneInput from "../common/ControlledPhoneInput";
import GenderInput from "./GenderInput";
import ProfilePictureButton from "./ProfilePictureButton";

interface IFormInput {
  gender: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
}

const ProfileEditForm: React.FC<any> = ({ handleClose }) => {
  const user = useSelector((state: any) => state.user);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm<IFormInput>({
    mode: "onBlur",
    defaultValues: {
      gender: user.gender,
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phone ?? "",
      email: user.email ?? "",
    },
  });

  const onSubmit: SubmitHandler<IFormInput> = (data: IFormInput) => {
    setLoading(true);
    const people = http.put(`people/${user.person_id}`, {
      phone: data.phone,
      email: data.email,
    });
    const putUser = http.put(`users/${user.user_id}`, {
      firstName: data.firstName,
      lastName: data.lastName,
      gender: data.gender,
    });

    zip(people, putUser).subscribe({
      next: () => {
        dispatch(setUser({ ...user, ...data }));
        // toast.success("Votre profil a bien été modifié");
        handleClose();
      },
      error: () => {
        setLoading(false);
        toastError();
      },
    });
  };

  return (
    <div>
      {/* Profile picture */}

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack>
          <Box sx={{ mb: 2, display: "flex", justifyContent: "center" }}>
            <ProfilePictureButton />
          </Box>
          <Box sx={{ mb: 2 }}>
            <GenderInput control={control} />
          </Box>
          <TextField
            label={t("profile_modal.firstName")}
            error={Boolean(errors?.firstName?.message)}
            helperText={
              errors?.firstName?.message ? t(errors.firstName.message) : " "
            }
            {...register("firstName", {
              required: { value: true, message: "errors.required" },
            })}
          />
          <TextField
            label={t("profile_modal.lastName")}
            error={Boolean(errors?.lastName?.message)}
            helperText={
              errors?.lastName?.message ? t(errors.lastName.message) : " "
            }
            {...register("lastName", {
              required: { value: true, message: "errors.required" },
            })}
          />
          <div style={{ marginTop: "2px" }}>
            <ControlledPhoneInput
              control={control}
              errors={errors}
              rules={{
                required: {
                  value: user.phone && user.phone?.length > 0,
                  message: "errors.required",
                },
              }}
            />
          </div>
          <TextField
            label={t("form.email")}
            type="email"
            error={Boolean(errors.email?.message)}
            helperText={errors.email?.message ? t(errors.email.message) : " "}
            {...register("email", {
              required: {
                value: user.email?.length > 0,
                message: "errors.required",
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "errors.email",
              },
            })}
          />

          <Box display="flex" justifyContent="center">
            <Button disabled={loading} type="submit">
              {t("btn.save")}
            </Button>
          </Box>
        </Stack>
      </form>
    </div>
  );
};

export default ProfileEditForm;
