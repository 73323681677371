import {
  Box,
  Grid,
  IconButton,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import Big5CompareBars from "./Big5CompareBars";

const Title = styled(Typography)(({ theme }: any) => ({
  color: theme.palette.secondary.main,
  fontFamily: "Product sans light,Product sans light,sans-serif",
}));

const HelpIcon = styled(HelpOutlineOutlinedIcon)(({ theme }) => ({
  fontSize: "10px",
  color: theme.palette.secondary.main,
}));

const Big5ProgressMarker: React.FC<{
  result: number;
  trait: string;
  compare?: boolean;
  editable?: boolean;
  compareResult?: any;
  handlePosition?: any;
}> = ({ result, trait, compare, editable, compareResult, handlePosition }) => {
  const { t }: { t: any } = useTranslation();

  return (
    <Grid container display="flex" alignItems="center" width="100%">
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Box display="flex" alignItems="center">
          <Title
            sx={{
              display: "flex",
            }}
            variant="subtitle1"
            fontWeight="100"
          >
            {t(`big5.traits.${trait}.label`)}
            <sup>
              <Tooltip title={t(`big5.traits.${trait}.help`)}>
                <IconButton
                  aria-label="big5 trait info"
                  sx={{ padding: 0, marginLeft: 0.5, marginTop: "-10px" }}
                >
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </sup>
          </Title>
        </Box>

        <Big5CompareBars
          result={result}
          trait={trait}
          compare={compare}
          compareResult={compareResult}
          editable={editable}
        />
      </Grid>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          variant="body2"
          color="secondary"
          sx={{ mt: 2, textAlign: "left" }}
          dangerouslySetInnerHTML={{
            __html: t(`big5.traits.${trait}.adjectives.left`),
          }}
        />

        <Typography
          variant="body2"
          sx={{
            mt: 2,
            ml: { sm: 4, md: 2 },
            textAlign: "right",
          }}
          color="secondary"
          dangerouslySetInnerHTML={{
            __html: t(`big5.traits.${trait}.adjectives.right`),
          }}
        />
      </div>
    </Grid>
  );
};

export default Big5ProgressMarker;
