import { styled, Tooltip, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";

const LongText: React.FC<{
  text: string;
  maxChar: number;
  variant?: string;
  color?: string;
  fontWeight?: string;
  lineHeight?: any;
  onMouseDown?: any;
  onClick?: any;
  clickable?: boolean;
}> = ({
  text,
  maxChar,
  variant,
  color,
  fontWeight,
  lineHeight,
  onMouseDown,
  onClick,
  clickable,
}: any) => {
  const [innerText, setInnerText] = useState("");

  useEffect(() => {
    if (text?.length > maxChar) {
      const splitted = text.slice(0, maxChar) + "...";
      setInnerText(splitted);
    } else {
      setInnerText(text);
    }
  }, [text, maxChar]);

  const Clickable = styled(Typography)(({ theme }) => ({
    ...(clickable
      ? {
          cursor: "pointer",
          "&:hover": { color: theme.palette.grey[900] },
        }
      : {}),
  }));

  return (
    <Tooltip
      title={
        text?.length > maxChar ? (
          <div
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        ) : (
          ""
        )
      }
    >
      <Clickable
        onMouseDown={onMouseDown}
        onClick={onClick}
        variant={variant}
        color={color}
        fontWeight={fontWeight}
        sx={lineHeight ? { lineHeight: lineHeight } : {}}
        dangerouslySetInnerHTML={{ __html: innerText }}
      />
    </Tooltip>
  );
};
export default LongText;
