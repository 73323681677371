import { seti18n } from "../i18nInit";

export const getShortDate = (date: Date | string) =>
  new Date(date)
    .toLocaleDateString("default", {
      // day: "numeric",
      month: "short",
      year: "numeric",
    })
    .toLocaleLowerCase();

function padTo2Digits(num: number) {
  return num.toString().padStart(2, "0");
}

export const getDateDigits = (date: Date | string) => {
  date = new Date(date);

  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join("/");
};

export const getBadgeColors = (color: string) => {
  switch (color) {
    case "red":
      return {
        background: "#ea384f0f",
        content: "#f6175a",
      };
    case "green":
      return {
        background: "#E1F7E2",
        content: "#1EC626",
      };
    default:
      return {
        background: "#FEEEDF",
        content: "#F58A1E",
      };
  }
};

export const getEnvWithoutAPI = () => {
  const origin = window.location.origin;
  const env = process.env.REACT_APP_ENV;

  let baseUrl = process.env.REACT_APP_BASEURL_W_API;

  if (
    !origin.includes("monkey-tie") &&
    process.env.REACT_APP_GOM !== "true" &&
    !origin.includes("localhost")
  ) {
    const split = origin.split(".");
    split[0] = `https://mtback-${env}`;
    baseUrl = split.join(".") + "/";
  }

  return baseUrl;
};

export const checkFileExt = (file: any) => {
  try {
    const fname = file.name;
    const ext = fname
      .slice(((fname.lastIndexOf(".") - 1) >>> 0) + 2)
      .toLowerCase();

    const regex = /pdf|docx|doc|odt|xls|xlsx|ods|ppt|pptx|txt|png|jpg|jpeg|zip/;

    return regex.test(ext);
  } catch (err) {
    return false;
  }
};

export const setCookies = (item: string, value: string | number) => {
  if (!value && value !== 0) return;
  const now = new Date();
  let time = now.getTime();

  // Expire au bout de 2h
  time += 3600 * 2000;
  now.setTime(time);
  document.cookie = `${item}=${value}; path=/; expires=Thu, 6 Dec 2100 12:00:00 UTC;`;
};

export const getCookie = (cname: string) => {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let c of ca) {
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const deleteCookie = (): void => {
  document.cookie = `A2HS=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
};

export const shuffleArray = (arr: any[]) => {
  const array = [...arr];
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
};

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item: any) {
  return item && typeof item === "object" && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target: any, ...sources: any): any {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

export const handleTranslationChanges = (labels: any) => {
  seti18n(labels);
};
