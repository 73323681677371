import { Chip, styled, Typography, Paper, Stack } from "@mui/material";
import React from "react";

import UpdateRoundedIcon from "@mui/icons-material/UpdateRounded";
import PinDropRoundedIcon from "@mui/icons-material/PinDropRounded";
import AccessibleRoundedIcon from "@mui/icons-material/AccessibleRounded";

import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import PriorityHighRoundedIcon from "@mui/icons-material/PriorityHighRounded";
import BeenhereRoundedIcon from "@mui/icons-material/BeenhereRounded";
import EuroRoundedIcon from "@mui/icons-material/EuroRounded";
import NotificationsActiveRoundedIcon from "@mui/icons-material/NotificationsActiveRounded";
import AccessAlarmRoundedIcon from "@mui/icons-material/AccessAlarmRounded";
import BusinessRoundedIcon from "@mui/icons-material/BusinessRounded";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";

const capitalizeFirstLetter = (str: string) => {
  return str[0].toUpperCase() + str.slice(1, str.length);
};

const InfosItem = styled(Paper)(({ theme }: any) => ({
  width: "100%",
  maxWidth: "84vw",
  padding: "25px",
  borderRadius: "9px",
  boxShadow: "0px 10px 60px #00000021 !important",
  marginTop: theme.spacing(2),
  textAlign: "left",
  overflow: "hidden",
}));

const InfosTitleBlock = styled("div")`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  h6 {
    line-height: 16px;
  }
  svg {
    margin-right: 5px;
  }
`;

const TitleCtn = styled("div")`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const Title = styled(Typography)(({ theme }) => ({
  background: "#4c6fff12",
  padding: "10px 15px",
  borderRadius: "10px",
}));

const TrainingInfos: React.FC<any> = ({ data }) => {
  const {
    organismeFormationResponsable,
    codeNiveauSortie,
    urlFormation,
    action: {
      nombreHeuresTotal,
      accesHandicapes,
      modalitesRecrutement,
      detailConditionsPriseEnCharge,
      prixTotalTtc,
      conditionsSpecifiques,
      rythmeFormation,
    },
  } = data;

  return (
    <div>
      <TitleCtn>
        <Title variant="h5" color="primary">
          Informations diverses
        </Title>
      </TitleCtn>

      <KeyInfos
        organismeFormationResponsable={organismeFormationResponsable}
        nombreHeuresTotal={nombreHeuresTotal}
        accesHandicapes={accesHandicapes}
      />

      <Admission modalitesRecrutement={modalitesRecrutement} />

      <Certification codeNiveauSortie={codeNiveauSortie} />

      <Indemnisation
        detailConditionsPriseEnCharge={detailConditionsPriseEnCharge}
      />

      <OrgInfos
        organismeFormationResponsable={organismeFormationResponsable}
        urlFormation={urlFormation}
      />

      <Price prixTotalTtc={prixTotalTtc} />

      <Conditions conditionsSpecifiques={conditionsSpecifiques} />

      <Duration
        nombreHeuresTotal={nombreHeuresTotal}
        rythmeFormation={rythmeFormation}
      />
    </div>
  );
};

export default TrainingInfos;

const SChip = styled(Chip)(({ theme }) => ({
  borderRadius: "8px",
  fontSize: "12px",
  backgroundColor: "#4c6fff14",
  color: theme.palette.secondary.main,
}));

const UpdateIcon = styled(UpdateRoundedIcon)(({ theme }) => ({
  color: `${theme.palette.primary.main}!important`,
}));

const KeyInfos: React.FC<any> = ({
  organismeFormationResponsable,
  nombreHeuresTotal,
  accesHandicapes,
}) => {
  return (
    <InfosItem>
      <InfosTitleBlock>
        <HelpRoundedIcon />
        <Typography variant="subtitle1">Informations clés</Typography>
      </InfosTitleBlock>
      <Stack spacing={1}>
        <div>
          <SChip
            sx={{ color: "#4c6fff" }}
            icon={<UpdateIcon sx={{ fontSize: "17px" }} />}
            label={`${nombreHeuresTotal?.value}h`}
          />
        </div>
        {organismeFormationResponsable?.coordonneesOrganisme?.coordonnees
          ?.adresse?.ville?.value && (
          <div>
            <SChip
              icon={<PinDropRoundedIcon sx={{ fontSize: "17px" }} />}
              label={
                organismeFormationResponsable?.coordonneesOrganisme?.coordonnees
                  ?.adresse?.ville?.value
              }
            />
          </div>
        )}
        <div>
          <SChip
            icon={<AccessibleRoundedIcon sx={{ fontSize: "17px" }} />}
            label={
              accesHandicapes?.value === "Oui"
                ? "Bâtiment conforme"
                : "Bâtiment non conforme"
            }
          />
        </div>
      </Stack>
    </InfosItem>
  );
};

const Admission: React.FC<any> = ({ modalitesRecrutement }) => (
  <InfosItem>
    <InfosTitleBlock>
      <PriorityHighRoundedIcon />
      <Typography variant="subtitle1">Modalité d'admission</Typography>
    </InfosTitleBlock>

    <Typography variant="body1">
      {capitalizeFirstLetter(
        modalitesRecrutement?.value ? modalitesRecrutement.value : "ND"
      )}
    </Typography>
  </InfosItem>
);

const Certification: React.FC<any> = ({ codeNiveauSortie }) => (
  <InfosItem>
    <InfosTitleBlock>
      <BeenhereRoundedIcon />
      <Typography variant="subtitle1">Certification</Typography>
    </InfosTitleBlock>

    <Typography variant="body1">
      {capitalizeFirstLetter(codeNiveauSortie ? codeNiveauSortie?.value : "ND")}
    </Typography>
  </InfosItem>
);

const Indemnisation: React.FC<any> = ({ detailConditionsPriseEnCharge }) => (
  <InfosItem>
    <InfosTitleBlock>
      <AccountBalanceRoundedIcon />
      <Typography variant="subtitle1">Prise en charge financière</Typography>
    </InfosTitleBlock>

    <Typography variant="body1">
      {capitalizeFirstLetter(detailConditionsPriseEnCharge?.value ?? "ND")}
    </Typography>
  </InfosItem>
);

const OrgInfos: React.FC<any> = ({
  organismeFormationResponsable,
  urlFormation,
}) => (
  <InfosItem>
    <InfosTitleBlock>
      <BusinessRoundedIcon />
      <Typography variant="subtitle1">Organisme de formation</Typography>
    </InfosTitleBlock>

    <Stack spacing={1}>
      <Typography variant="body1">
        {organismeFormationResponsable?.nomOrganisme?.value}
      </Typography>
      <div>
        {organismeFormationResponsable?.coordonneesOrganisme?.coordonnees?.adresse?.ligne?.map(
          (item: any) => (
            <Typography key={item?.value} variant="body1">
              {capitalizeFirstLetter(item?.value)}
            </Typography>
          )
        )}
        <Typography variant="body1">
          {
            organismeFormationResponsable?.coordonneesOrganisme?.coordonnees
              ?.adresse?.ville?.value
          }{" "}
          {
            organismeFormationResponsable?.coordonneesOrganisme?.coordonnees
              ?.adresse?.codePostal?.value
          }
        </Typography>
      </div>
      <Typography variant="body1">
        <a
          href={`tel:${organismeFormationResponsable?.contactOrganisme?.coordonnees?.telFixe?.numTel?.value}`}
          style={{ color: "#4c6fff", textDecoration: "none" }}
        >
          {
            organismeFormationResponsable?.contactOrganisme?.coordonnees
              ?.telFixe?.numTel?.value
          }
        </a>
      </Typography>
      <Typography variant="body1">
        <a
          href={`mailto:${organismeFormationResponsable?.contactOrganisme?.coordonnees?.courriel?.value}`}
          style={{ color: "#4c6fff", textDecoration: "none" }}
        >
          {
            organismeFormationResponsable?.contactOrganisme?.coordonnees
              ?.courriel?.value
          }
        </a>
      </Typography>
      <Typography
        variant="body1"
        sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
      >
        <a
          href={`${urlFormation?.urlWeb?.[0]?.value}`}
          target="_blank"
          style={{
            color: "#4c6fff",
            textDecoration: "none",
          }}
          rel="noreferrer"
        >
          {urlFormation?.urlWeb && urlFormation?.urlWeb?.[0]?.value}
        </a>
      </Typography>
    </Stack>
  </InfosItem>
);

const Price: React.FC<any> = ({ prixTotalTtc }) => (
  <>
    {prixTotalTtc?.value > 0 && (
      <InfosItem>
        <EuroRoundedIcon />
        <InfosTitleBlock>Prix</InfosTitleBlock>
        <Typography variant="subtitle1">{prixTotalTtc?.value}€</Typography>€
      </InfosItem>
    )}
  </>
);

const Conditions: React.FC<any> = ({ conditionsSpecifiques }) => (
  <InfosItem>
    <InfosTitleBlock>
      <NotificationsActiveRoundedIcon />
      <Typography variant="subtitle1">
        Conditions spécifiques et prérequis
      </Typography>
    </InfosTitleBlock>

    <Typography variant="body1">
      {conditionsSpecifiques
        ? capitalizeFirstLetter(conditionsSpecifiques?.value)
        : "ND"}
    </Typography>
  </InfosItem>
);

const Duration: React.FC<any> = ({ rythmeFormation, nombreHeuresTotal }) => (
  <InfosItem>
    <InfosTitleBlock>
      <AccessAlarmRoundedIcon />
      <Typography variant="subtitle1">
        Durée et rythme de la formation
      </Typography>
    </InfosTitleBlock>

    <Stack>
      <Typography variant="body1">
        {nombreHeuresTotal ? `${nombreHeuresTotal?.value}h` : "ND"}
      </Typography>
      <Typography variant="body1">
        {rythmeFormation ? capitalizeFirstLetter(rythmeFormation?.value) : "ND"}
      </Typography>
    </Stack>
  </InfosItem>
);
