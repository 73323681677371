import React, { useEffect, useState } from "react";
import http from "../../services/http";
import skillsService from "../../services/skills.service";
import { SkillsListEntity } from "../../@types/SkillCategoryGroup";
import SkillsSuggestList from "./SkillsSuggestList";
import { Button, styled } from "@mui/material";
import SkillsListConfirm from "./SkillsListConfirm";
import { map } from "rxjs";
import SkillsAddSkeleton from "./SkillsAddSkeleton";
import { toastError } from "../../utils/toasts";
import { useTranslation } from "react-i18next";

const Footer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-evenly",
  marginTop: theme.spacing(2),
}));

const SkillsModalBody: React.FC<{
  handleClose: () => void;
  transversal?: boolean;
}> = ({ handleClose, transversal = false }) => {
  const [suggests, setSuggests] = useState<Partial<SkillsListEntity>[]>([]);
  const [loading, setLoading] = useState(true);
  const [evaluate, setEvaluate] = useState(false);
  const [saving, setSaving] = useState(false);
  const [addListLength, setAddListLength] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const addSub = skillsService._addList
      .pipe(map((e) => e.length))
      .subscribe((e) => setAddListLength(e));

    const suggestSub = http
      .get("skills/suggest", {
        params: {
          ...(transversal ? { type: "transversal" } : {}),
        },
      })
      .subscribe({
        next: (e) => {
          setSuggests(e.data["hydra:member"]);
          setLoading(false);
        },
        error: () => {
          toastError();
          setLoading(false);
        },
      });

    return () => {
      suggestSub.unsubscribe();
      addSub.unsubscribe();
    };
  }, []);

  const handleSave = () => {
    setSaving(true);
    if (skillsService.addList.length === 0) return;
    skillsService.addSkills(transversal).subscribe({
      next: () => {
        handleClose();
      },
      error: () => {
        handleClose();
      },
    });
  };

  return (
    <div>
      {loading ? (
        <SkillsAddSkeleton />
      ) : (
        <>
          {evaluate ? (
            <>
              <SkillsListConfirm />
              <Footer>
                <Button
                  disabled={saving}
                  variant="outlined"
                  onClick={() => setEvaluate(false)}
                >
                  {t("btn.return")}
                </Button>
                {addListLength > 0 && (
                  <Button disabled={saving} onClick={handleSave}>
                    {t("btn.confirm")}
                  </Button>
                )}
              </Footer>
            </>
          ) : (
            <>
              <SkillsSuggestList
                transversal={transversal}
                suggests={suggests}
              />

              <Footer>
                <Button
                  disabled={addListLength === 0}
                  onClick={() => setEvaluate(true)}
                >
                  {t(`skills.evaluate_${transversal ? "abilities" : "skills"}`)}
                </Button>
              </Footer>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default SkillsModalBody;
