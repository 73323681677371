import { useEffect, useState } from "react";
import { tap } from "rxjs";
import licensesService from "../services/licenses.service";

const useLicenses = () => {
  const [loading, setLoading] = useState(true);
  const [referential, setReferential] = useState<any[]>([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    const initSub = licensesService
      .init()
      .pipe(tap(() => setLoading(false)))
      .subscribe({
        error: () => setError(true),
      });

    const refSub = licensesService._referential.subscribe((e) =>
      setReferential(e)
    );
    return () => {
      initSub.unsubscribe();
      refSub.unsubscribe();
    };
  }, []);
  return { loading, referential, error };
};
export default useLicenses;
