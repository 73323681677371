export const B5_COLORS = {
  openness: "#f7b02e",
  conscientiousness: "#1a52c2",
  extraversion: "#e52963",
  agreeableness: "#1fc726",
  neuroticism: "#7842b2",
};

export const returnColor = (trait: string) => {
  switch (trait) {
    case "agreeableness":
      return B5_COLORS.agreeableness;
    case "conscientiousness":
      return B5_COLORS.conscientiousness;
    case "extraversion":
      return B5_COLORS.extraversion;
    case "neuroticism":
      return B5_COLORS.neuroticism;
    case "openness":
      return B5_COLORS.openness;
  }
};
