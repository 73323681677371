import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { forwardRef } from "react";
import useScrollTop from "../hooks/useScrollTop";
import { useTranslation } from "react-i18next";
// material

// ----------------------------------------------------------------------

const Page = forwardRef(
  ({ children, title = "", padding, sx, className, ...other }: any, ref) => {
    useScrollTop();
    const { t } = useTranslation();
    return (
      <div className={className} ref={ref} {...other}>
        <Helmet>
          <title>{t(title)}</title>
        </Helmet>
        {children}
      </div>
    );
  }
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Page;
