import { styled, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSpring, animated as a } from "react-spring";
import MoodChoicesHandler from "./MoodChoicesHandler";
import MoodGameProgress from "./MoodGameProgress";
import MoodGameView from "./MoodGameView";
import { useTranslation } from "react-i18next";

type MoodGameProps = {
  questions: any[];
  handleEnd: (res: any) => void;
};

const Root = styled(a.div)`
  /* box-shadow: 0 8px 16px 0 rgb(145, 158, 171, 24%); */
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  border-radius: 30px;
  margin-top: 20px;
  padding: 20px;
`;

const MoodGame: React.FC<MoodGameProps> = ({ questions, handleEnd }) => {
  const [choices, setChoices] = useState<any>([]);
  const duration = 500;
  const { t } = useTranslation();
  const [showHandler, setShow] = useState(false);

  const end = choices.length === questions.length;

  const handleChoice = (choice: any) => {
    setChoices((c: any) => [...c, choice]);
  };

  const styles = useSpring({
    to: { opacity: end ? 0 : 1 },
    from: { opacity: end ? 1 : 0 },
    duration,
  });

  const stylesShow = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    duration,
  });

  useEffect(() => {
    let to: any;
    if (end) {
      to = setTimeout(() => {
        setShow(true);
        //handleEnd();
      }, duration);
    }
    return () => {
      if (to) clearTimeout(to);
    };
  }, [choices, end]);

  return (
    <div>
      {showHandler ? (
        <a.div style={stylesShow}>
          <MoodChoicesHandler choices={choices} end={handleEnd} />
        </a.div>
      ) : (
        <Root style={styles}>
          <Typography
            sx={{ color: "#5D5D5D" }}
            mb={4}
            variant="h5"
            textAlign="center"
          >
            {t("mood.swipe")}
          </Typography>
          <MoodGameView
            handleChoice={handleChoice}
            deck={questions.map((q: any) => ({
              id: q.id,
              label: q.label,
              background: q.details?.image,
            }))}
          />
          <MoodGameProgress choices={choices.length} deck={questions.length} />
        </Root>
      )}
    </div>
  );
};

export default MoodGame;
