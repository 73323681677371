import { styled, Typography } from "@mui/material";
import React from "react";
import MoodRating from "../MoodRating";

const SRoot = styled("div")`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SStarsContainer = styled("div")`
  display: flex;
  position: relative;
  margin-left: 10px;
`;

const STitleContainer = styled("div")`
  display: flex;
  position: relative;
  color: white;
  padding: 10px 20px 10px 20px;

  border-radius: 30px;
  box-shadow: 0px 0px 30px #00000029;
  background: transparent linear-gradient(257deg, #dd736a 0%, #e8a100 100%) 0%
    0% no-repeat padding-box;
`;

// const SCounter = styled("div")`
//   position: absolute;
//   display: flex;
//   align-items: center;
//   color: #5d5d5d;
//   justify-content: center;
//   background: white;
//   border-radius: 100px;
//   box-shadow: 0px 0px 30px #00000029;
//   top: 50%;
//   transform: translateY(-50%);
//   left: -10px;
//   width: 25px;
//   height: 25px;
// `;

// const SIconContainer = styled("div")`
//   margin-right: 5px;
// `;

const MoodResultItem: React.FC<{ index: number; item: any }> = ({
  index = 0,
  item,
}) => {
  return (
    <SRoot>
      <SStarsContainer>
        <MoodRating value={item.value} />
      </SStarsContainer>
      <STitleContainer>
        {/* <SCounter>
          <Typography variant="subtitle1" component="span">
            {index}
          </Typography>
        </SCounter> */}

        {/* <SIconContainer><SiIconify />ICON</SIconContainer> */}

        <Typography variant="subtitle1">{item.text}</Typography>
      </STitleContainer>
    </SRoot>
  );
};

export default MoodResultItem;
