import { styled } from "@mui/material";
import React from "react";
import LicensesHeader from "../components/licenses/LicensesHeader";
import LicensesList from "../components/licenses/LicensesList";
import { pageWithNavbar } from "../globalConfig";
import Page from "../layouts/Page";

const Content = styled("div")`
  background: white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: 100%;
  margin-top: -70px;
  min-height: 120px;
  padding: 30px;
`;

const LicensesPage = () => {
  return (
    <Page title="pages.licenses" style={{ ...pageWithNavbar }}>
      <LicensesHeader />
      <Content>
        <LicensesList />
      </Content>
    </Page>
  );
};

export default LicensesPage;
