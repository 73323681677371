import { Card, CardContent, styled, Typography } from "@mui/material";
import React from "react";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import WorkOutlineRoundedIcon from "@mui/icons-material/WorkOutlineRounded";
import { getDateDigits } from "../../utils/utils";
import CareerItem from "../../@types/CareerItem";
import ExperienceItemMenu from "./ExperienceItemMenu";
import CustomCardHeader from "../common/CustomCardHeader";
import { useTranslation } from "react-i18next";

const ContentLine = styled("div")`
  display: flex;
`;

const ExperienceItem = React.memo(function ExperienceItem({
  exp,
}: {
  exp: CareerItem;
}) {
  const { dateEnd, dateStart, company, label, location, work, companyData } =
    exp;
  const { t } = useTranslation();
  return (
    <Card>
      <CustomCardHeader
        avatar={
          <img
            alt="icon experience"
            src={companyData?.logo ?? "/static/image-jeu/experiences.svg"}
          />
        }
        title={label}
        subheader={work?.category?.label}
        action={<ExperienceItemMenu {...{ exp }} />}
      />
      <CardContent
        sx={{
          margin: 2,
          marginTop: 0,
          background: "#ECEEF7",
          borderRadius: "10px",
          paddingBottom: "16px!important",
          padding: 2,
        }}
      >
        <ContentLine
          style={{ marginBottom: company || location?.label ? "5px" : "0px" }}
        >
          <AccessTimeRoundedIcon sx={{ mr: 1, fontSize: "18px", mt: "5px" }} />
          <Typography sx={{ lineHeight: "28px" }}>
            {dateEnd ? t("experience.from") : t("experience.since")}{" "}
            <Typography component="span" fontWeight="bold">
              {getDateDigits(dateStart)}
            </Typography>
            {dateEnd && ` ${t("experience.to")} `}
            {dateEnd && (
              <Typography component="span" fontWeight="bold">
                {getDateDigits(dateEnd)}
              </Typography>
            )}
          </Typography>
        </ContentLine>

        {(company || location?.label) && (
          <ContentLine>
            <WorkOutlineRoundedIcon
              sx={{ mr: 1, fontSize: "18px", mt: "5px" }}
            />
            <Typography sx={{ lineHeight: "28px" }}>
              {company && `${t("experience.in_company")} `}
              {company && (
                <Typography component="span" fontWeight="bold">
                  {company}
                </Typography>
              )}
              {location?.label && ` à `}
              {location?.label && (
                <Typography component="span" fontWeight="bold">
                  {location?.label}
                </Typography>
              )}
            </Typography>
          </ContentLine>
        )}
      </CardContent>
    </Card>
  );
});

export default ExperienceItem;
