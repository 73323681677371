import {
  Modal,
  Typography,
  IconButton,
  styled,
  useTheme,
  Box,
  Button,
  Stack,
} from "@mui/material";
import React from "react";
import ModalBody from "../common/ModalBody";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Header = styled("div")`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const PEModal: React.FC<{ open: boolean; close: () => void }> = ({
  open,
  close,
}) => {
  const { t } = useTranslation();
  const { firstName } = useSelector((state: any) => state.user);

  const redirectPe = () => {
    window.open(
      "https://www.emploi-store.fr/portail/services/passToWork",
      "_blank"
    );
    close();
  };

  return (
    <ModalBody>
      <Header>
        <Typography variant="h5">{t("feedback.title")}</Typography>

        <IconButton sx={{ ml: "auto" }} onClick={close}>
          <CancelRoundedIcon color="primary" />
        </IconButton>
      </Header>

      <Stack spacing={1}>
        <Typography>
          {t("feedback.2step.text1", {
            name: firstName ?? "",
          })}
        </Typography>
        <Typography>{t("feedback.2step.text2")}</Typography>
        <Typography>{t("feedback.2step.text3")}</Typography>
      </Stack>
      <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
        <img
          onClick={redirectPe}
          src="/static/pe_store.png"
          alt="emploi store illu"
          width="175"
        />
      </Box>

      {/* -- footer -- */}
      <Box display="flex" justifyContent="space-around" marginTop={2}>
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={redirectPe}
          sx={{ mt: 2 }}
        >
          <Typography sx={{ lineHeight: "18px" }} variant="body1">
            {t("feedback.2step.btn")}
          </Typography>
        </Button>
        {/* <Button
          onClick={close}
          variant="outlined"
          color="secondary"
          type="button"
          sx={{ borderRadius: "5px", width: "160px", height: "58px" }}
        >
          <Typography
            color="secondary"
            variant="body1"
            sx={{ color: theme.palette.text.secondary }}
          >
            {t("btn.cancel")}
          </Typography>
        </Button> */}
      </Box>
    </ModalBody>
  );
};

export default PEModal;
