import { Theme, ThemeProvider } from "@mui/material";
import { StyledEngineProvider, CssBaseline, createTheme } from "@mui/material";
import React from "react";
import breakpoints from "./breakpoints";
import componentsOverride from "./overrides";
import palette from "./palette";
import typography from "./typography";
import GlobalStyles from "@mui/material/GlobalStyles";

const ThemeConfig: React.FC = ({ children }) => {
  const theme: Theme = createTheme({
    palette,
    typography,
    breakpoints,
  });

  theme.components = { ...componentsOverride(theme) };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles
          styles={{
            input: {
              "&[type=number]": {
                MozAppearance: "textfield",
                "&::-webkit-outer-spin-button": {
                  margin: 0,
                  WebkitAppearance: "none",
                },
                "&::-webkit-inner-spin-button": {
                  margin: 0,
                  WebkitAppearance: "none",
                },
              },
            },
          }}
        />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeConfig;
