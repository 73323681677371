import { Skeleton, Stack, Typography, Box } from "@mui/material";
import React from "react";
import useInitExperiences from "../../hooks/useInitExperiences";
import ExperienceItem from "./ExperienceItem";

const ExperiencesList: React.FC = () => {
  const { experiences, loading } = useInitExperiences();

  return (
    <div>
      {loading ? (
        <Stack sx={{ paddingTop: 2, padding: 2 }} spacing={2}>
          {[1, 2, 3].map((el: number) => (
            <Skeleton
              key={el}
              variant="rectangular"
              sx={{ borderRadius: "22px", height: "200px" }}
            />
          ))}
        </Stack>
      ) : (
        <>
          {experiences.length > 0 ? (
            <Stack sx={{ paddingTop: 2, padding: 2 }} spacing={2}>
              {experiences.map((el) => (
                <ExperienceItem exp={el} key={el.id} />
              ))}
            </Stack>
          ) : (
            <Box sx={{ paddingTop: 2, padding: 2, textAlign: "center" }}>
              <Typography>
                Vous n'avez encore renseigné aucune expérience
              </Typography>
            </Box>
          )}
        </>
      )}
    </div>
  );
};

export default ExperiencesList;
