// import axios from "axios";

import { initChat } from "./chatActions";

export const SET_USER = "SET_USER";
export const SET_USER_PARTIAL = "SET_USER_PARTIAL";

export const setUser = (value: any) => {
  return (dispatch: any, getState: any) => {
    dispatch(setUsr({ ...value }));
    if (value.firstName) {
      dispatch(initChat());
    }
  };
};

export const setUsr = (value: any) => {
  return {
    type: SET_USER,
    value,
  };
};

export const setUserPartial = (partial: any = {}) => {
  return (dispatch: any, getState: any) => {
    const { user } = getState();
    dispatch(setUser({ ...user, ...partial }));
  };
};
