import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import skillsService from "../../services/skills.service";
import SkillAddItem from "./SkillAddItem";
import { useTranslation } from "react-i18next";

const SkillsListConfirm = () => {
  const [list, setList] = useState(skillsService.addList);
  const { t } = useTranslation();
  useEffect(() => {
    const sub = skillsService._addList.subscribe((e) => setList(e));
    return () => {
      sub.unsubscribe();
    };
  }, []);

  return (
    <Stack spacing={2}>
      {list.length > 0 ? (
        <>
          <Typography>{t("skills.select_level")}</Typography>
          {list.map((e) => (
            <SkillAddItem key={e.id} skill={e} />
          ))}
        </>
      ) : (
        <Typography textAlign="center">{t("skills.select_skills")}</Typography>
      )}
    </Stack>
  );
};

export default SkillsListConfirm;
