import { Button } from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import { checkFileExt } from "../../utils/utils";
import ProfilePictureModal from "./ProfilePictureModal";
import { useTranslation } from "react-i18next";

const ProfilePictureButton = () => {
  const { t } = useTranslation();
  const inputRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const [upImg, setUpImg] = useState<any>(null);

  const handleClick = () => {
    inputRef?.current?.click();
  };

  const onFileChange = (e: any) => {
    if (!checkFileExt(e.target.files[0])) return;

    setOpen(true);
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setUpImg(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  useEffect(() => {
    if (!open && inputRef?.current?.files) {
      inputRef.current.value = null;
    }
  }, [open]);

  return (
    <div>
      <Button onClick={handleClick} variant="outlined">
        {t("profile_modal.edit_picture")}
      </Button>
      <input onChange={onFileChange} ref={inputRef} type="file" hidden />
      {open && (
        <ProfilePictureModal
          upImg={upImg}
          open={open}
          close={() => {
            setUpImg(null);
            setOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default ProfilePictureButton;
