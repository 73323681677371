import { BehaviorSubject, take } from "rxjs";

class drawer {
  public static state = new BehaviorSubject(false);

  public static open() {
    this.state.next(true);
  }

  public static close() {
    this.state.next(false);
  }

  public static toggle() {
    this.state.pipe(take(1)).subscribe((value) => this.state.next(!value));
  }
}

export default drawer;
