export default function Button(theme: any) {
  return {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: false,
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained" as const,
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontSize: "16px",
          fontWeight: "normal",
          borderRadius: "22px",
          padding: "12px 26px",
          textTransform: "none" as const,
        },
      },
    },
  };
}
