import styled from "@emotion/styled";
import { ButtonBase, Drawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ProfileDrawerInfos from "./ProfileDrawerInfos";
import ProfileDrawerMenu from "./ProfileDrawerMenu";
import drawer from "../../services/drawer.service";

const CloseButton = styled(ButtonBase)`
  margin-left: auto;
  background: #eceef7;
  width: 54px;
  height: 54px;
  border-radius: 10px;
`;

const CustomDrawer = styled(Drawer)`
  & > .MuiDrawer-paper {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 36px;
    padding-bottom: 44px;
  }
`;

const DrawerContainer = styled("div")`
  width: 290px;
`;

const DrawerHeader = styled("div")`
  display: flex;
  align-items: center;
  & > img {
    width: 50px;
  }
`;

const ProfileDrawer: React.FC = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const sub = drawer.state.subscribe((value) => setOpen(value));
    return () => {
      sub.unsubscribe();
    };
  }, []);

  return (
    <React.Fragment key={"options-panel"}>
      <CustomDrawer anchor={"right"} open={open} onClose={() => drawer.close()}>
        <DrawerContainer>
          <DrawerHeader>
            <img alt="logo" src="/static/logo.svg" />
            <CloseButton onClick={() => drawer.toggle()}>
              <CloseRoundedIcon sx={{ color: "#505887", fontSize: "35px" }} />
            </CloseButton>
          </DrawerHeader>

          <ProfileDrawerInfos />

          <ProfileDrawerMenu />
        </DrawerContainer>
      </CustomDrawer>
    </React.Fragment>
  );
};

export default ProfileDrawer;
