import React from "react";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { Avatar, Box, Chip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const Big5Archetypes: React.FC<any> = ({ data }) => {
  const { t } = useTranslation();

  return data ? (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      sx={{ mt: 4 }}
    >
      <Chip
        sx={{
          background:
            "linear-gradient(89deg, #33C8FF 0%, #4E60FF 100%) 0% 0% no-repeat padding-box",
          pl: 2,
          pr: 2,
          width: "170px",
          height: "40px",
          borderRadius: "20px",
        }}
        label={
          <Box display="flex" alignItems="center">
            <AutoAwesomeIcon sx={{ fontSize: "18px", mr: 1 }} />
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: t(`big5.result.avatar_title_collab`),
              }}
            ></Typography>
          </Box>
        }
        color="primary"
      />
      <Typography
        variant="subtitle1"
        fontWeight="normal"
        textAlign="center"
        sx={{ maxWidth: "60ch", mt: 2 }}
      >
        {t("big5.result.archetype_text")}
      </Typography>
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="space-evenly"
        sx={{ mt: 3 }}
      >
        {data?.archetype?.map((item: any) => (
          <Box
            display="flex"
            alignItems="center"
            sx={{ m: 1 }}
            key={item.avatar}
          >
            <Avatar alt="Remy Sharp" src={item?.avatar} />
            <Typography
              variant="body1"
              fontWeight="bold"
              sx={{
                ml: 1,
                maxWidth: "14ch",
                textAlign: "left",
                lineHeight: "16px",
              }}
            >
              {item?.name}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  ) : (
    <></>
  );
};

export default Big5Archetypes;
