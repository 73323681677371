import { Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import LicenseItem from "./LicenseItem";

const LicensesCategory: React.FC<any> = ({ category }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Typography sx={{ mb: 1.6 }} variant="h5">
        {t(`licenses.${category.code}`)}
      </Typography>

      <Grid container spacing={2}>
        {category.list.map((license: any, index: number) => (
          <Grid key={license.code} item xs={6}>
            <LicenseItem license={license} index={index} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default LicensesCategory;
