import React, { useState } from "react";
import big5service from "../services/big5Service";

const useBig5 = () => {
  const [results, setResults] = useState(big5service.results);
  const [loading, setLoading] = useState(true);

  React.useEffect(() => {
    const sub1 = big5service._results.subscribe(setResults);
    const sub2 = big5service._loading.subscribe(setLoading);
    return () => {
      sub1.unsubscribe();
      sub2.unsubscribe();
    };
  }, []);

  return { results, loading };
};

export default useBig5;
