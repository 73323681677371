import {
  Box,
  IconButton,
  Collapse,
  Typography,
  Card,
  CardContent,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Big5Config } from "./Big5Config";
import Big5ProgressMarker from "./Big5ProgressMarker";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { returnColor } from "./big5Utils";

const Arrow: any = styled(ExpandMoreIcon, {
  shouldForwardProp: (props) => props !== "orderDesc",
})(({ theme, orderDesc }: any) => ({
  color: theme.palette.secondary.main,
  transition: "all 0.25s ease-in-out",
  transform: orderDesc ? "rotate(180deg)" : "rotate(0deg)",
}));

const Profile = styled(Typography)(({ theme }: any) => ({
  textAlign: "center",
  background: "#fbfbfb",
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius10,
}));

const Big5Restitution: React.FC<{
  results?: any;
  traits?: any;
  compare?: boolean;
  notCollab?: boolean;
  work?: boolean;
  compareResult?: any;
}> = ({ results, traits, compare, notCollab, work, compareResult }) => {
  const { t } = useTranslation();
  const btraits = Big5Config.traits;

  const [collapsedComments, setCollapsedComments] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  const expandComment = (index: number) => {
    let newArr = [...collapsedComments];
    newArr[index] = !newArr[index];
    setCollapsedComments(newArr);
  };

  return results ? (
    <div style={{ maxWidth: "900px", marginLeft: "auto", marginRight: "auto" }}>
      <Box>
        {btraits.map((trait, index) => (
          <Box sx={{ mt: 2, mb: 2 }} key={trait}>
            <Box display="flex" alignItems="center">
              <Box width={notCollab ? "100%" : "95%"}>
                <Big5ProgressMarker
                  compare={compare}
                  compareResult={compareResult ? compareResult[trait] : 0}
                  result={results[trait]}
                  trait={trait}
                />
              </Box>
              {traits?.length > 0 && (
                <Box sx={{ ml: "auto" }}>
                  <IconButton
                    aria-label="expand button"
                    sx={{ ml: "auto" }}
                    onClick={() => expandComment(index)}
                  >
                    <Arrow
                      fontSize="large"
                      orderDesc={collapsedComments[index]}
                    />
                  </IconButton>
                </Box>
              )}
            </Box>
            {traits?.length > 0 && (
              <Collapse in={collapsedComments[index]} unmountOnExit>
                <Box sx={{ mt: 2 }}>
                  <Box>
                    <Profile variant="body1">
                      {
                        traits?.[
                          traits?.findIndex((res: any) => res?.key === trait)
                        ]?.profile
                      }
                    </Profile>
                  </Box>
                  <Box
                    sx={{ mt: 2 }}
                    display="flex"
                    alignItems="center"
                    flexWrap="wrap"
                    justifyContent="space-around"
                  >
                    {traits?.[
                      traits?.findIndex((res: any) => res?.key === trait)
                    ]?.sstraits.map((sstrait: any) => (
                      <Card
                        key={sstrait.label}
                        sx={{
                          boxShadow: `0 0 10px 0 ${returnColor(
                            trait
                          )} !important`,
                          mb: 2,
                        }}
                      >
                        <CardContent sx={{ pb: "16px!important" }}>
                          <Box display="flex" alignItems="center">
                            <Typography variant="subtitle1">
                              {sstrait.percentage} %
                            </Typography>
                            <Typography sx={{ ml: 1 }} variant="body1">
                              {sstrait.label}
                            </Typography>
                          </Box>
                        </CardContent>
                      </Card>
                    ))}
                  </Box>
                </Box>
              </Collapse>
            )}
          </Box>
        ))}
      </Box>
    </div>
  ) : (
    <></>
  );
};

export default Big5Restitution;
