import {
  CircularProgress,
  IconButton,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { SkillsListEntity } from "../../@types/SkillCategoryGroup";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import CustomRating from "./CustomRating";
import skillsService from "../../services/skills.service";
import { tap } from "rxjs";

const Root = styled(Paper)`
  padding: 5px 10px;
  border-radius: 16px;
  box-shadow: rgb(145 158 171 / 24%) 0px 8px 16px 0px;
`;

const Container = styled("div")`
  display: flex;
  align-items: center;
`;

const SkillItem: React.FC<{
  skill: SkillsListEntity;
  transversal?: boolean;
}> = ({ skill, transversal }) => {
  const [level, setLevel] = useState(skill.level.level);
  const [editMode, setEditMode] = useState(false);
  const enableEditMode = () => setEditMode(true);
  const [loading, setLoading] = useState(false);
  const [baseLevel, setBaseLevel] = useState(skill.level.level);

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: number | null
  ) => {
    setLevel(value ? value : level);
  };

  const handleSubmit = () => {
    if (level === baseLevel || loading) {
      setEditMode(false);
      return;
    }

    setLoading(true);
    skillsService
      .edit(skill, level)
      .pipe(
        tap(() => {
          setEditMode(false);
          setLoading(false);
        })
      )
      .subscribe({
        next: () => setBaseLevel(level),
        error: () => {
          setLevel(baseLevel);
          setEditMode(false);
          setLoading(false);
        },
      });
  };

  const handleDelete = () => {
    setLoading(true);
    skillsService.delete(skill, transversal).subscribe({
      error: () => {
        setLoading(false);
        setEditMode(false);
      },
    });
  };

  return (
    <Root>
      <Typography>{skill.label}</Typography>
      <Container>
        <CustomRating
          name="customized-rating"
          value={level}
          disabled={!editMode}
          onChange={handleChange}
        />
        {loading ? (
          <IconButton sx={{ ml: "auto", padding: "10px" }}>
            <CircularProgress sx={{ ml: "auto" }} color="inherit" size={20} />
          </IconButton>
        ) : editMode ? (
          <Container style={{ marginLeft: "auto" }}>
            <IconButton onClick={handleDelete} sx={{ ml: "auto" }}>
              <DeleteForeverRoundedIcon color="error" />
            </IconButton>
            <IconButton onClick={handleSubmit} sx={{ ml: "auto" }}>
              <CheckRoundedIcon color="success" />
            </IconButton>
          </Container>
        ) : (
          <IconButton onClick={enableEditMode} sx={{ ml: "auto" }}>
            <ModeEditOutlineOutlinedIcon />
          </IconButton>
        )}
      </Container>
    </Root>
  );
};

export default SkillItem;
