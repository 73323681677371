import { ButtonBase, FormControl, styled } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

const Root = styled("div")`
  display: flex;
  justify-content: space-evenly;
`;

const Button = styled(ButtonBase)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: "16px",
  padding: "10px",
  fontWeight: "bold",
  color: theme.palette.primary.main,
  "&.selected": {
    background: theme.palette.primary.main,
    color: "white",
  },
}));

const GenderInput: React.FC<any> = ({ control }) => {
  const { t } = useTranslation();
  return (
    <FormControl sx={{ width: "100%" }}>
      <Controller
        control={control}
        name="gender"
        render={({ field: { onChange, value } }) => (
          <Root>
            <Button
              className={`${value === "M" ? "selected" : ""}`}
              onClick={() => onChange("M")}
            >
              {t("profile_modal.gender.M")}
            </Button>
            <Button
              className={`${value === "F" ? "selected" : ""}`}
              onClick={() => onChange("F")}
            >
              {t("profile_modal.gender.F")}
            </Button>
            <Button
              className={`${value === "N" ? "selected" : ""}`}
              onClick={() => onChange("N")}
            >
              {t("profile_modal.gender.N")}
            </Button>
          </Root>
        )}
      />
    </FormControl>
  );
};

export default GenderInput;
