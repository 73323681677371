import { FormControl } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Work } from "../../../@types/CareerItem";
import AutocompleteComponent from "../../common/AutocompleteComponent";

const ExperienceWorkAutocomplete: React.FC<{
  control: any;
  clearErrors: any;
  required?: boolean;
  label?: string;
}> = ({ control, clearErrors, required, label }) => {
  const { t } = useTranslation();

  return (
    <FormControl>
      <Controller
        name="work"
        control={control}
        rules={{
          required: { value: required ?? false, message: "errors.required" },
          validate: {
            required: (value) =>
              !required || value.label?.length > 0 ? true : "errors.required",
          },
        }}
        render={({
          field: { onChange, value, onBlur },
          formState: { errors },
        }) => (
          <AutocompleteComponent
            type="works"
            required={required}
            onBlur={onBlur}
            formError={Boolean(errors.work)}
            formHelper={t((errors.work as any)?.message) ?? " "}
            baseValue={value}
            onFocus={() => clearErrors("work")}
            label={label ?? t("work.title")}
            sendDataToParent={(e: Work) => onChange(e.label ? e : null)}
          />
        )}
      />
    </FormControl>
  );
};

export default ExperienceWorkAutocomplete;
