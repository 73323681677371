import { BehaviorSubject, Observable, Subscription, tap } from "rxjs";
import CareerItem from "../@types/CareerItem";
import {
  addExperience,
  deleteExperience,
  initExperiences,
  setExperiences,
  updateExperience,
} from "../store/actions/experiencesActions";
import store from "../store/store";
import http from "./http";

class experiencesService {
  public static loading = new BehaviorSubject(true);
  static experiences = [];
  static _experiences = new BehaviorSubject(this.experiences);

  static trainings = [];
  static _trainings = new BehaviorSubject(this.trainings);

  /**
   * Initialize experiences list
   * @param callback handle the loading value
   * @returns subscription to the loader
   */
  public static init = (callback: (b: boolean) => void): Subscription => {
    this.loading.next(true);
    store.dispatch(initExperiences());
    return this.loading.subscribe((el: boolean) => callback(el));
  };

  /**
   * Remove a careerItem from the list by ID
   * @param id CareerItem's ID
   */
  public static deleteById = (id: string) => {
    const state: any = store.getState();
    const { experiences } = state.experiences;
    const index = experiences.findIndex((el: any) => el.id === id);

    if (index >= 0) {
      const cp = [...experiences];
      cp.splice(index, 1);
      store.dispatch(setExperiences(cp));
    }
  };

  /**
   * Add a careerItem to the list
   * @param careerItem new careerItem
   */
  private static addCareerItem = (careerItem: CareerItem) => {
    store.dispatch(addExperience(careerItem));
  };

  /**
   * Update a careerItem from the list
   * @param careerItem updated careerItem
   */
  private static updateList(careerItem: CareerItem) {
    store.dispatch(updateExperience(careerItem));
  }

  private static deleteFromList(id: string) {
    store.dispatch(deleteExperience(id));
  }

  /**
   * Create a career item and add it to the list
   * @param data form data
   * @returns
   */
  public static createCareerItem(data: any): Observable<any> {
    const body: any = { ...data };

    body.type = "experience";
    body.description = null;

    // // TODO : Add companyData
    // body.companyData = null;
    body.company = body.companyData?.name ?? "";

    if (body.ongoing) {
      body.dateEnd = null;
    }
    if (!body.location?.id) {
      body.location = null;
    }

    body.work = body.work["@id"];

    delete body.ongoing;

    return http
      .post("career_items", body)
      .pipe(tap((val) => this.addCareerItem(val.data)));
  }

  /**
   * Update a career item
   * @param data form data
   * @returns
   */
  public static updateCareerItem(data: any): Observable<any> {
    const body: any = { ...data };

    const id = body.id;
    delete body.id;

    body.type = "experience";
    body.description = null;

    body.company = body.companyData?.name ?? "";

    if (body.ongoing) {
      body.dateEnd = null;
    }
    if (!body.location?.id) {
      body.location = null;
    }

    body.work = body.work["@id"];

    delete body.ongoing;

    return http
      .put(`career_items/${id}`, body)
      .pipe(tap((val) => this.updateList(val.data)));
  }

  /**
   * Create a training item and add it to the list
   * @param data form data
   * @returns
   */
  public static createTraining(data: any): Observable<any> {
    const body: any = { ...data };

    body.type = "training";

    if (body.ongoing) {
      body.dateEnd = null;
    }
    if (!body.location?.id) {
      body.location = null;
    }

    body.work = body.work["@id"];

    if (body.diplomaLevel === -1) {
      body.diplomaLevel = null;
    }

    delete body.ongoing;

    return http
      .post("career_items", body)
      .pipe(tap((val) => this.addCareerItem(val.data)));
  }

  /**
   * Update a training
   * @param data form data
   * @returns
   */
  public static updateTraining(data: any): Observable<any> {
    const body: any = { ...data };

    const id = body.id;
    delete body.id;

    body.type = "training";

    if (body.ongoing) {
      body.dateEnd = null;
    }
    if (!body.location?.id) {
      body.location = null;
    }

    body.work = body.work["@id"];

    if (body.diplomaLevel === -1) {
      body.diplomaLevel = null;
    }

    delete body.ongoing;

    return http
      .put(`career_items/${id}`, body)
      .pipe(tap((val) => this.updateList(val.data)));
  }

  static deleteCareerItem(id: string) {
    return http
      .delete(`career_items/${id}`)
      .pipe(tap(() => this.deleteFromList(id)));
  }
}

export default experiencesService;
