import React from "react";
import { Modal, Typography, styled, IconButton } from "@mui/material";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import ModalBody from "../common/ModalBody";
import SkillsModalBody from "./SkillsModalBody";
import { useTranslation } from "react-i18next";

const Header = styled("div")`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const SkillsAddModal: React.FC<{
  open: boolean;
  handleClose: () => void;
  transversal?: boolean;
}> = ({ open, handleClose, transversal = false }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalBody className="skills-modal-body">
          <Header>
            <Typography variant="h5">
              {t(`add_${transversal ? "abilities" : "skills"}`)}
            </Typography>

            <IconButton sx={{ ml: "auto" }} onClick={handleClose}>
              <CancelRoundedIcon color="primary" />
            </IconButton>
          </Header>

          <SkillsModalBody
            handleClose={handleClose}
            transversal={transversal}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default SkillsAddModal;
