import { Box, Button, Modal } from "@mui/material";
import axios from "axios";
import React, { useRef, useState } from "react";
import ReactCrop, {
  centerCrop,
  Crop,
  makeAspectCrop,
  PixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useDispatch } from "react-redux";
import { useDebounceEffect } from "../../hooks/useDebounceEffect";
import { setUserPartial } from "../../store/actions/userActions";
import { canvasPreview } from "../../utils/canvasPreview";
import { toastError } from "../../utils/toasts";
import ModalBody from "../common/ModalBody";
import { useTranslation } from "react-i18next";

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const ProfilePictureModal: React.FC<any> = ({ close, open, upImg }) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [aspect] = useState<number | undefined>(1 / 1);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const generateDownload = (canvas: any, crop: any) => {
    if (!crop || !canvas) {
      return;
    }
    setLoading(true);
    canvas.toBlob(
      async (blob: any) => {
        const formData = new FormData();
        formData.append("file", blob);
        try {
          const res = await axios.post("media_objects", formData);
          dispatch(setUserPartial({ image: res?.data?.contentUrl || "" }));
          setLoading(false);
          close();
        } catch (err) {
          toastError();
          setLoading(false);
          close();
        }
      },
      "image/png",
      1
    );
  };

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  const handleSubmit = () => {
    generateDownload(previewCanvasRef.current, completedCrop);
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          1 / 1,
          0
        );
      }
    },
    100,
    [completedCrop, crop]
  );

  return (
    <Modal open={open} onClose={close}>
      <ModalBody>
        {upImg && (
          <>
            {Boolean(upImg) && (
              <Box display="flex" justifyContent="center" marginBottom={2}>
                <ReactCrop
                  crop={crop}
                  onChange={(_, percentCrop) => setCrop(percentCrop)}
                  onComplete={(c) => setCompletedCrop(c)}
                  aspect={aspect}
                >
                  <img
                    ref={imgRef}
                    alt="Crop me"
                    src={upImg}
                    onLoad={onImageLoad}
                  />
                </ReactCrop>
              </Box>
            )}
            <div>
              {Boolean(completedCrop) && (
                <canvas
                  hidden
                  ref={previewCanvasRef}
                  style={{
                    border: "1px solid black",
                    objectFit: "contain",
                    width: completedCrop?.width,
                    height: completedCrop?.height,
                  }}
                />
              )}
            </div>
            <Box display="flex" justifyContent="space-around">
              <Button disabled={loading} onClick={handleSubmit}>
                {t("btn.valid")}
              </Button>
              <Button variant="outlined" disabled={loading} onClick={close}>
                {t("btn.cancel")}
              </Button>
            </Box>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ProfilePictureModal;
