import {
  Box,
  Card,
  CardContent,
  IconButton,
  Modal,
  CardActions,
  Typography,
  styled,
  Button,
} from "@mui/material";
import { HighlightOff } from "@mui/icons-material";
import React from "react";
import { useTranslation } from "react-i18next";
import { modalStyle } from "../../mood/MoodPopup";

const CardImage = styled("img")({
  position: "relative",
  left: "24%",
  width: "45%",
  marginTop: 1,
});

const DeleteResultsModal: React.FC<{
  open: boolean;
  width?: string;
  close: () => void;
  deleteResults: () => void;
}> = ({ open, close, deleteResults, width = "510px" }) => {
  const { t } = useTranslation();

  const handle = () => {
    deleteResults();
    close();
  };

  return (
    <Modal open={open} onClose={close}>
      <Card
        sx={{
          ...modalStyle,
          width: {
            xs: "95%",
            sm: !width ? "auto" : width,
            md: !width ? "auto" : width,
          },
        }}
      >
        <CardContent sx={{ pt: 0 }}>
          <Box sx={{ width: "100%", display: "flex" }}>
            <IconButton
              aria-label="close"
              onClick={close}
              sx={{ ml: "auto", mt: 1, mr: -2, mb: 1 }}
            >
              <HighlightOff />
            </IconButton>
          </Box>
          <CardImage
            alt="Logout icon"
            src="/static/illustrations/Logout-illu.svg"
          />
          <Typography
            variant="subtitle1"
            sx={{ textAlign: "center", mt: 3, fontWeight: 600 }}
          >
            {t("tests.modal.delete_results_text")}
          </Typography>
          <CardActions
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              mt: 2,
            }}
          >
            <Button color="primary" onClick={handle}>
              {t("btn.delete")}
            </Button>
            <Button variant="outlined" onClick={close}>
              {t("btn.cancel")}
            </Button>
          </CardActions>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default DeleteResultsModal;
