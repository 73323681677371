import React, { Suspense, lazy } from "react";
import { useRoutes, Navigate } from "react-router-dom";
import Loader from "../components/loader/Loader";
import ResultsListPage from "../pages/ResultsListPage";
import Big5Page from "../pages/big5/Big5Page";
import MoodPage from "../pages/mood/MoodPage";
const ResultsPage = lazy(() => import("../pages/ResultsPage"));

const RestrictedUserRoutes = () => {
  const completeness = JSON.parse(localStorage.getItem("completeness") ?? "{}");

  return useRoutes([
    {
      path: "/app",
      children: [
        { path: "", element: <Navigate to="/app/results" replace /> },
        {
          path: "results",
          element: (
            <Suspense fallback={<Loader inProp={false} />}>
              <ResultsListPage />
            </Suspense>
          ),
        },
        {
          path: "result",
          element: (
            <Suspense fallback={<Loader inProp={false} />}>
              <ResultsPage webapp />
            </Suspense>
          ),
        },
        {
          path: "onboarding",
          element: <Navigate to="/app/results" replace />,
        },
        {
          path: "mood",
          element: (
            <Suspense fallback={<Loader inProp={false} />}>
              <MoodPage webapp />
            </Suspense>
          ),
        },
        completeness?.needBigFive && {
          path: "big5",
          element: (
            <Suspense fallback={<Loader inProp={false} />}>
              <Big5Page webapp />
            </Suspense>
          ),
        },
        { path: "*", element: <Navigate to="/app/results" replace /> },
      ].filter((el) => el),
    },
    { path: "/login", element: <Navigate to="/app/results" replace /> },
    { path: "/", element: <Navigate to="/app/results" replace /> },
    {
      path: "*",
      element: <Navigate to="/app/results" replace />,
    },
  ]);
};

export default RestrictedUserRoutes;
