import React from "react";
import HomeSectionItem from "../home/HomeSectionItem";
import { styled, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

const Root = styled("div")`
  padding: 20px;
`;

const ResultsList = () => {
  const completeness: any = JSON.parse(
    localStorage.getItem("completeness") ?? "{}"
  );
  const { t } = useTranslation();

  const b5: any = {
    type: "b5",
    title: t("big5.label"),
    link: "/app/big5",
    value: t("big5.card_desc"),
    checked: completeness.hasBigFive,
  };

  const mood: any = {
    type: "mood",
    title: "Mood",
    link: "/app/mood",
    value: t("mood.card_desc"),
    checked: completeness.hasMood,
  };

  const game: any = {
    type: "ptw",
    title: "Pass To Work",
    link: "/app/result",
    value: t("common.dynamics"),
    checked: completeness.hasDynamic,
  };

  return (
    <Root>
      <Stack spacing={2}>
        {(completeness.needGamified || completeness.hasDynamic) && (
          <HomeSectionItem {...game} />
        )}

        <HomeSectionItem {...mood} />

        {completeness.needBigFive && <HomeSectionItem {...b5} />}
      </Stack>
    </Root>
  );
};

export default ResultsList;
