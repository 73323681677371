import { FormControl } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import CompanyAutocomplete from "../../common/CompanyAutocomplete";

const ExperienceFormCompany: React.FC<any> = ({ control }) => {
  return (
    <>
      <FormControl>
        <Controller
          name="companyData"
          control={control}
          render={({
            field: { onChange, value, onBlur },
            formState: { errors },
          }) => (
            <CompanyAutocomplete
              baseValue={value}
              onBlur={onBlur}
              sendDataToParent={(e: any) => onChange(e.name ? e : null)}
            />
          )}
        />
      </FormControl>
    </>
  );
};

export default ExperienceFormCompany;
