import { Stack, styled, Typography } from "@mui/material";
import React from "react";
import { SkillsCategoryGroup } from "../../@types/SkillCategoryGroup";
import SkillItem from "./SkillItem";

const GroupLabelContainer = styled("div")`
  background: #919eab14;
  display: flex;
  padding: 5px 10px;
  border-radius: 8px;
`;

const Root = styled("div")(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const SkillsGroup: React.FC<{
  skillsGroup: SkillsCategoryGroup;
  transversal?: boolean;
}> = ({ skillsGroup, transversal }) => {
  return (
    <Root>
      <div style={{ display: "flex" }}>
        <GroupLabelContainer>
          <Typography color="primary">{skillsGroup.label}</Typography>
        </GroupLabelContainer>
      </div>
      <Stack sx={{ mt: 2 }} spacing={2}>
        {skillsGroup.skillsList?.map((skill) => (
          <SkillItem key={skill.id} {...{ skill, transversal }} />
        ))}
      </Stack>
    </Root>
  );
};

export default SkillsGroup;
