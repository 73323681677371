import { styled } from "@mui/material";
import React from "react";
import PageHeader from "../common/PageHeader";
import { useTranslation } from "react-i18next";

const Root = styled("div")`
  background: #4c6fff;
  background-image: url("/static/backgrounds/licenses.jpeg");
  background-size: cover;
  background-position: bottom;
  padding-top: 30px;
  padding-bottom: 100px;
`;

const LicensesHeader = () => {
  const { t } = useTranslation();
  return (
    <Root>
      <PageHeader
        title={t("pages.licenses")}
        subtitle={t("licenses.subtitle")}
      />
    </Root>
  );
};

export default LicensesHeader;
