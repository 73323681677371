import { Slider, Typography, styled, Box, Button } from "@mui/material";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import axios from "axios";

interface IFormInput {
  rating: number;
  time: number;
  complexity: number;
}

const SliderContainer = styled("div")`
  width: auto;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
`;

const CustomButton = styled(Button)({
  width: "275px",
  height: "55px",
  fontSize: "12px",
});

const Big5EndQuestionnary: React.FC<any> = ({ submitTest }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { handleSubmit, control } = useForm<IFormInput>({
    mode: "onBlur",
    defaultValues: {
      rating: 5,
      time: 0,
      complexity: 5,
    },
  });

  const marks = [
    {
      value: 0,
      label: t("big5.end_questionnary.marks.less_than_5"),
    },
    {
      value: 25,
      label: t("big5.end_questionnary.marks.less_than_10"),
    },
    {
      value: 50,
      label: t("big5.end_questionnary.marks.between_10_and_15"),
    },
    {
      value: 75,
      label: t("big5.end_questionnary.marks.between_15_and_20"),
    },
    {
      value: 100,
      label: t("big5.end_questionnary.marks.more_than_twenty"),
    },
  ];

  const onSubmit = async (data: any) => {
    setLoading(true);
    const handleDataTime = (time: number) => {
      switch (time) {
        case 25:
          return 2;
        case 50:
          return 3;
        case 75:
          return 4;
        case 100:
          return 5;
        default:
          return 1;
      }
    };

    const response = {
      type: "QB5",
      level: "QUES",
      description: JSON.stringify({
        rating: data.rating,
        time: handleDataTime(data.time),
      }),
    };

    try {
      await axios.post("logs", response);
    } catch (err) {}

    submitTest();
  };

  function valueLabelFormat(value: number) {
    return marks.findIndex((mark) => mark.value === value) + 1;
  }

  return (
    <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
      <Typography
        sx={{
          mb: 3,
          textAlign: "center",
          lineHeight: "19px",
          fontSize: "18px",
          fontWeight: "bold",
        }}
      >
        {t("big5.end_questionnary.title")}
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Controller
          control={control}
          name="rating"
          render={({ field: { value, onChange } }) => (
            <SliderContainer>
              <Typography gutterBottom>
                {t("big5.end_questionnary.rating")}
              </Typography>
              <Slider
                aria-label="Small steps"
                value={value}
                getAriaValueText={(v) => `${v}`}
                onChange={(e: any, v: any) => onChange(v)}
                step={1}
                min={1}
                max={10}
                valueLabelDisplay="auto"
              />
              <Box display="flex" justifyContent="space-between">
                <Typography>{t("big5.end_questionnary.not_at_all")}</Typography>
                <Typography>
                  {t("big5.end_questionnary.very_appreciated")}
                </Typography>
              </Box>
            </SliderContainer>
          )}
        />

        <Controller
          control={control}
          name="time"
          render={({ field: { value, onChange } }) => (
            <SliderContainer>
              <Typography gutterBottom>
                {t("big5.end_questionnary.time")}
              </Typography>
              <Slider
                aria-label="Small steps"
                value={value}
                valueLabelFormat={valueLabelFormat}
                onChange={(e: any, v: any) => onChange(v)}
                step={null}
                marks={marks}
                valueLabelDisplay="off"
              />
            </SliderContainer>
          )}
        />

        {/* <Controller
          control={control}
          name="complexity"
          render={({ field: { value, onChange } }) => (
            <SliderContainer>
              <Typography gutterBottom>
                Comment jugez-vous sa complexité ?
              </Typography>
              <Slider
                aria-label="Small steps"
                value={value}
                getAriaValueText={(v) => `${v}`}
                onChange={(e: any, v: any) => onChange(v)}
                step={1}
                min={1}
                max={10}
                valueLabelDisplay="auto"
              />
              <Box display="flex" justifyContent="space-between">
                <Typography>Extrêmement complexe</Typography>
                <Typography>Très facile</Typography>
              </Box>
            </SliderContainer>
          )}
        /> */}

        <Box display="flex" justifyContent="center">
          <CustomButton disabled={loading} variant="contained" type="submit">
            {t("btn.valid")}
          </CustomButton>
        </Box>
      </form>
    </div>
  );
};

export default Big5EndQuestionnary;
