import { Button, styled, Typography } from "@mui/material";
import React, { useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useTrail, animated as a } from "react-spring";

const SRoot = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Star = () => {
  return <img src="/static/mood/logo.svg" alt="Mood star logo" />;
};

const Title = () => {
  const { t } = useTranslation();
  return (
    <Typography mb={2} variant="h4" textAlign="center">
      {t("mood.ranking")}
    </Typography>
  );
};

const Text = () => {
  const { t } = useTranslation();
  return (
    <Typography sx={{ maxWidth: "40ch", textAlign: "center" }}>
      {t("work.discover_text")}
    </Typography>
  );
};

const SButton = styled(Button)`
  border-radius: 30px;
  height: 48px;
  width: 195px;
  margin-top: 20px;
  font-size: 14px;
`;

const CustomButton: React.FC<any> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <SButton variant="contained" onClick={onClick}>
      {t("work.discover")}
    </SButton>
  );
};

const RedoButton: React.FC<any> = (props) => {
  const { t } = useTranslation();
  return (
    <SButton variant="contained" {...props}>
      {t("mood.redo")}
    </SButton>
  );
};

const config = { mass: 5, tension: 2000, friction: 200 };

const MoodResultsActionBlock: React.FC<any> = ({ redo, works = true }) => {
  const navigate = useNavigate();

  const [toggle, set] = useState(false);

  const goToWorks = () => {
    navigate("/app/works");
  };

  const intro = [
    <Star />,
    <Title />,
    <Text />,
    works && <CustomButton onClick={goToWorks} />,
    <RedoButton onClick={redo} />,
  ].filter((el) => el);

  useLayoutEffect(() => {
    set(true);
  }, []);

  const trail = useTrail(intro.length, {
    config,
    opacity: toggle ? 1 : 0,
    x: toggle ? 0 : 20,
    height: toggle ? 80 : 0,
    from: { opacity: 0, x: 20, height: 0 },
  });

  return (
    <SRoot>
      {trail.map(({ x, height, ...rest }, index) => (
        <a.div
          key={index}
          className="trails-text"
          style={{
            ...rest,
            transform: x.to((x) => `translate3d(0,${x}px,0)`),
          }}
        >
          {intro[index]}
        </a.div>
      ))}
    </SRoot>
  );
};

export default MoodResultsActionBlock;
