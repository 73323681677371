import { styled, Typography } from "@mui/material";
import React from "react";
import Page from "../../layouts/Page";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import Mood from "../../components/mood/Mood";
import { pageWithNavbar } from "../../globalConfig";
import PageHeader from "../../components/common/PageHeader";
import { useTranslation } from "react-i18next";

const SHeader = styled("div")(({ theme }) => ({
  width: "100%",
  height: "190px",
  background:
    "transparent linear-gradient(80deg, #dd736a 0%, #e8a100 100%) 0% 0%\n    no-repeat padding-box",
  borderRadius: "11px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  [theme.breakpoints.down("md")]: {
    height: "auto",
    padding: theme.spacing(2),
  },
  marginTop: "-70px",
}));

const STime = styled(Typography)`
  display: flex;
  align-items: center;
  color: white;
`;

const Logo = styled("img")`
  width: 92px;
  height: auto;
`;

const Title = styled("img")`
  margin: 10px;
`;

const Root = styled("div")`
  background: #4c6fff;
  background-image: url("/static/backgrounds/forest.jpg");
  background-size: cover;
  background-position: bottom;
  padding-top: 30px;
  padding-bottom: 100px;
`;

const MoodPage: React.FC<{ webapp?: boolean }> = ({ webapp = false }) => {
  const { t } = useTranslation();
  return (
    <Page
      title="pages.mood"
      style={{
        ...(webapp
          ? { background: "white", minHeight: "100vh" }
          : pageWithNavbar),
        paddingBottom: 12,
      }}
    >
      <Root>
        <PageHeader title="Mood" subtitle={t("mood.subtitle")} menu={!webapp} />
      </Root>

      <SHeader>
        <Logo src="/static/mood/logo.svg" />
        <Title src="/static/mood/name.svg" />
        <STime>
          <AccessAlarmIcon sx={{ marginRight: 1 }} />{" "}
          {t("common.required_time", { minutes: 2 })}
        </STime>
      </SHeader>
      <Mood webapp={!webapp} />
    </Page>
  );
};

export default MoodPage;
