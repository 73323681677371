import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EventSourcePolyfill } from "event-source-polyfill";
import { handleMessageEvent } from "../store/actions/chatActions";

export const useConversations = () => {
  const isLogged = useSelector((state: any) => state.auth.isLogged);
  const personId = useSelector((state: any) => state.user.person_id);
  const mercureToken = useSelector((state: any) => state.user.mercure);
  const dispatch = useDispatch();

  useEffect(() => {
    let source: any;
    if (isLogged) {
      const url = `${process.env.REACT_APP_MERCURE_URL}?topic=/api/chat/conversations/${personId}`;
      source = new EventSourcePolyfill(url, {
        headers: {
          Authorization: `Bearer ${mercureToken}`,
        },
      });
      source.onmessage = (e: any) => {
        const parsed = JSON.parse(e.data);
        dispatch(handleMessageEvent(parsed));
      };
    }

    return () => {
      // eventSource?.close();
      source?.close();
    };
  }, [isLogged]);
};
