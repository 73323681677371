import { ButtonBase, styled, Typography } from "@mui/material";
import React from "react";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Root = styled(ButtonBase)(({ theme }) => ({
  boxShadow: "rgb(145 158 171 / 24%) 0px 8px 16px 0px",
  display: "flex",
  alignItems: "center",
  justifyContent: "left",
  borderRadius: "16px",
  padding: "18px 14px",
  textAlign: "left",
  background: "white",
  width: "100%",
}));

const LogoCircle = styled("div")`
  background: #d1e5ff;
  padding: 10px;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  margin-right: 11px;
  & > img {
    max-width: 36px;
    max-height: 36px;
  }
`;

const LinearCtn = styled("div")`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const BorderLinearProgress = styled(LinearProgress)(({ theme, ...props }) => ({
  height: 8,
  width: "132px",
  marginRight: "10px",
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor:
      props.value === 100
        ? theme.palette.success.main
        : theme.palette.primary.main,
  },
}));

const HomeSectionItem: React.FC<{
  type: string;
  value: number;
  title: string;
  subtitle?: string;
  link?: string;
  checked?: boolean;
}> = ({ type, value, title, subtitle, link, checked }) => {
  const navigate = useNavigate();
  const handleRedirect = () => {
    if (link) navigate(link);
  };
  const { t } = useTranslation();

  return (
    <Root onClick={handleRedirect} disableRipple>
      <LogoCircle>
        <img alt={type} src={`/static/image-jeu/${type}.svg`} />
      </LogoCircle>
      <div>
        <Typography variant="h5" sx={{ display: "flex", alignItems: "center" }}>
          {title}
          {/* {" "} {checked && (
            <CheckCircleRoundedIcon sx={{ color: "#1EC626", height: "18px" }} />
          )} */}
        </Typography>
        {subtitle && (
          <Typography color={value === 100 ? "#1EC626" : "#4C6FFF"}>
            {subtitle}
          </Typography>
        )}

        {type === "skills" || type === "experiences" ? (
          <LinearCtn>
            <BorderLinearProgress variant="determinate" value={value} />
            {value === 100 && (
              <CheckCircleRoundedIcon
                sx={{ color: "#1EC626", height: "18px" }}
              />
            )}
          </LinearCtn>
        ) : (
          <Typography>{value}</Typography>
        )}
      </div>
    </Root>
  );
};

export default HomeSectionItem;
