import { Button, styled } from "@mui/material";
import CampaignRoundedIcon from "@mui/icons-material/CampaignRounded";
import React, { useState } from "react";
import FeedbackModal from "./FeedbackModal";
import { useTranslation } from "react-i18next";

const SButton = styled(Button)``;

const FeedbackButton = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <SButton variant="outlined" onClick={setOpen.bind(this, true)}>
        {t("menu.review")} <CampaignRoundedIcon sx={{ ml: 0.5 }} />
      </SButton>
      <FeedbackModal open={open} close={setOpen.bind(this, false)} />
    </>
  );
};

export default FeedbackButton;
