// import { toast } from "react-toastify";
import { BehaviorSubject, tap } from "rxjs";
import { toastError } from "../utils/toasts";
import http from "./http";

class big5service {
  static results: any = null;
  static _results = new BehaviorSubject(this.results);

  static questions: any = [];
  static _questions = new BehaviorSubject(this.questions);

  static loading: boolean = true;
  static _loading = new BehaviorSubject(this.loading);

  static setLoading(bool: boolean) {
    this.loading = bool;
    this._loading.next(bool);
  }

  static setResults(res: any) {
    this.results = res;
    this._results.next(res);
  }

  static setQuestions(q: any) {
    this.questions = q;
    this._questions.next(q);
  }

  static init() {
    this.setLoading(true);
    return http.get("tests/b5/result").pipe(
      tap({
        next: (res) => {
          this.setResults(res.data);
          if (res.data.results?.results) {
            http.get("archetype").subscribe({
              next: (res) => {
                this.setResults({
                  ...this.results,
                  results: { ...this.results.results, archetypes: res.data },
                });
                this.setLoading(false);
              },
              error: (err) => {
                console.log(err);
                this.setLoading(false);
              },
            });
          } else {
            this.setLoading(false);
          }
        },
        error: (error) => {
          this.setLoading(false);
        },
      })
    );
  }

  static postResults(body: any) {
    this.setLoading(true);
    return http.post("results", body).pipe(
      tap({
        next: (res) => {
          this.init().subscribe();
        },
        error: (error) => {
          this.setLoading(false);
        },
      })
    );
  }

  static getQuestions() {
    return http.get("tests/b5").pipe(
      tap({
        next: (res: any) => this.setQuestions(res.data.questions),
        error: (err) => {},
      })
    );
  }

  static deleteResults() {
    const resId = this.results?.results?.id;
    if (resId) {
      this.setLoading(true);
      http.delete(`results/${resId}`).subscribe({
        next: (res) => {
          this.setResults(null);
          this.setLoading(false);
          // toast.success("Vos résultats ont bien été supprimés");
        },
        error: (err) => {
          this.setLoading(false);
          toastError();
        },
      });
    }
    //
  }
}

export default big5service;
