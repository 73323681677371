import { SET_EXPERIENCES } from "../actions/experiencesActions";
import initialState from "../initialState";

export const experiencesReducer = (
  state = initialState.experiences,
  action: any
) => {
  switch (action.type) {
    case SET_EXPERIENCES:
      return {
        ...state,
        experiences: [...action.payload],
      };
    default:
      return { ...state };
  }
};
