import {
  SkillsCategoryGroup,
  SkillsListEntity,
} from "../@types/SkillCategoryGroup";

export const sortSkillsByCategory = (
  skills: SkillsListEntity[]
): SkillsCategoryGroup[] => {
  const categories: any = [];

  skills?.forEach((skill: any) => {
    const skillCategory = skill.skill?.skillCategory;
    if (
      skillCategory &&
      categories.filter((el: any) => el?.["@id"] === skillCategory?.["@id"])
        .length === 0
    ) {
      categories.push({
        ...skillCategory,
        skillsList: skills.filter(
          (el: any) => el.skill?.skillCategory["@id"] === skillCategory["@id"]
        ),
      });
    } else if (
      !skillCategory &&
      categories.filter((el: any) => el?.id === "noCategory").length === 0
    ) {
      categories.push({
        label: "Non catégorisé",
        id: "noCategory",
        skillsList: skills.filter((el: any) => !el.skill?.skillCategory),
      });
    }
  });
  return categories;
};

export const sortSkills = (skills: any) => {
  const skillCategory: any = [];
  const finalSkills: any = [];

  // eslint-disable-next-line array-callback-return
  skills?.map((skill: any) => {
    if (!skill?.skillCategory) {
      skill.skillCategory = { id: "none" };
    }

    if (skillCategory.indexOf(skill?.skillCategory?.id) === -1) {
      const category = {
        id: skill?.skillCategory?.id || "none",
        label: skill?.skillCategory?.label || "Sans catégorie",
        description: skill?.skillCategory?.description,
        skillsList: [],
      };
      skillCategory.push(category);
    }
    // eslint-disable-next-line array-callback-return
    skillCategory.map((item: any) => {
      if (item.id === skill?.skillCategory?.id) {
        item.skillsList.push(skill);
      }
    });
  });

  const final = skillCategory.filter(function (elem: any) {
    if (finalSkills.indexOf(elem.id) === -1) {
      finalSkills.push(elem.id);
      return true;
    } else {
      return false;
    }
  });
  return final;
};
