// ----------------------------------------------------------------------

export default function Card(theme: any) {
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "22px",
          boxShadow: "rgb(145 158 171 / 24%) 0px 8px 16px 0px",
        },
      },
    },
  };
}
