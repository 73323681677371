// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect } from "react";

import { EventSourcePolyfill } from "event-source-polyfill";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getNotifs,
  incrementNotifsCount,
} from "../store/actions/notificationsActions";
import { toast } from "react-toastify";

const useNotifications = (): any => {
  const user = useSelector((state: any) => state.user, shallowEqual);
  const isLogged = useSelector((state: any) => state.auth.isLogged);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user.person_id || !isLogged) return;

    try {
      Notification.requestPermission();
    } catch (err) {
      console.log("Notifs disabled");
    }

    dispatch(getNotifs());
    const url = `${process.env.REACT_APP_MERCURE_URL}?topic=/api/notifications/${user.user_id}`;

    const eventSource = new EventSourcePolyfill(url, {
      headers: {
        Authorization: `Bearer ${user.mercure}`,
      },
    });

    eventSource.onmessage = (e: any) => {
      const res = JSON.parse(e.data);
      dispatch(incrementNotifsCount());
      if (res["label.fr"]) {
        toast(res["label.fr"]);
      }
      console.log(res);
    };

    return () => {
      eventSource.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isLogged]);
};
export default useNotifications;
