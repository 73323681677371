import { Button, styled } from "@mui/material";
import React, { useState } from "react";
import big5service from "../../../services/big5Service";
import DeleteResultsModal from "./DeleteResultsModal";
import { useTranslation } from "react-i18next";

const SButton = styled(Button)`
  font-size: 14px;
  color: #919eab;
  border-color: #919eabb0;
`;

const Big5DeleteButton = () => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const deleteResults = () => {
    big5service.deleteResults();
  };

  return (
    <>
      <DeleteResultsModal
        deleteResults={deleteResults}
        open={open}
        close={setOpen.bind(this, false)}
      />
      <SButton
        color="secondary"
        onClick={setOpen.bind(this, true)}
        variant="outlined"
      >
        {t("mood.delete")}
      </SButton>
    </>
  );
};

export default Big5DeleteButton;
