import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./navbar/Navbar";

const UserLayout: React.FC = () => {
  return (
    <div style={{ position: "relative", paddingBottom: "75px" }}>
      <Outlet />
      <Navbar />
    </div>
  );
};

export default UserLayout;
