import React from "react";
import { Fade } from "@mui/material";

const Loader: React.FC<any> = ({ inProp }) => {
  return (
    <div style={{}}>
      <Fade in={inProp} mountOnEnter unmountOnExit>
        <div
          style={{
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bottom: 0,
            left: 0,
            right: 0,
            top: 0,

            flexDirection: "column",
          }}
        >
          <img
            style={{ width: "200px" }}
            src="/static/path_to_work_logo.svg"
            alt="logo"
          />
          <div
            style={{ marginTop: "20px" }}
            className="snippet"
            data-title=".dot-flashing"
          >
            <div className="stage">
              <div className="dot-flashing"></div>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default Loader;
