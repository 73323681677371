import { Modal, Typography, styled, IconButton } from "@mui/material";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import React from "react";
import ExperienceForm from "./form/ExperienceForm";
import CareerItem from "../../@types/CareerItem";
import ModalBody from "../common/ModalBody";
import { useTranslation } from "react-i18next";

const Header = styled("div")`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ExperienceModal: React.FC<{
  exp?: CareerItem | Partial<CareerItem>;
  open: boolean;
  handleClose: () => void;
}> = ({ exp, open, handleClose }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalBody>
          <Header>
            <Typography variant="h5">
              {t(`experience.${exp ? "edit" : "add"}`)}
            </Typography>

            <IconButton sx={{ ml: "auto" }} onClick={handleClose}>
              <CancelRoundedIcon color="primary" />
            </IconButton>
          </Header>

          <ExperienceForm handleClose={handleClose} exp={exp} />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ExperienceModal;
