import { styled } from "@mui/material";
import React from "react";

const Controls = styled("div")`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: -30px;
`;

const ControlButton = styled("button")`
  background: white;
  border: none;

  -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.23);
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.23);

  z-index: 100;
  border-radius: 100px;
  height: 57px;
  width: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    width: 30px;
  }
`;

const MoodSwipeControls: React.FC<any> = ({ handleSwipe }) => {
  return (
    <Controls>
      <ControlButton onClick={handleSwipe.bind(this, -1)}>
        <img src="/static/mood/cross.svg" alt="red mark" />
      </ControlButton>
      <ControlButton onClick={handleSwipe.bind(this, 1)}>
        <img src="/static/mood/heart.svg" alt="green heart" />
      </ControlButton>
    </Controls>
  );
};

export default MoodSwipeControls;
