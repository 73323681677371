import { IconButton } from "@mui/material";
import React, { useState } from "react";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import CareerItem from "../../@types/CareerItem";
import ExperienceModal from "./ExperienceModal";

const ExperienceItemMenu: React.FC<{ exp: CareerItem }> = ({ exp }) => {
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => setOpenModal(false);

  return (
    <div>
      <IconButton
        id="basic-button"
        onClick={handleOpenModal}
        aria-label="settings"
      >
        <ModeEditIcon sx={{ mr: 1, fontSize: "16px" }} />
      </IconButton>

      <ExperienceModal
        {...{ open: openModal, handleClose: handleCloseModal, exp }}
      />
    </div>
  );
};

export default ExperienceItemMenu;
