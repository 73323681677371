import { Typography, styled, Paper, IconButton } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { SkillsListEntity } from "../../@types/SkillCategoryGroup";
import skillsService from "../../services/skills.service";
import CustomRating from "./CustomRating";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";

const Root = styled(Paper)`
  padding: 5px 10px;
  border-radius: 16px;
`;
const Container = styled("div")`
  display: flex;
  align-items: center;
`;

const SkillAddItem: React.FC<{ skill: Partial<SkillsListEntity> }> = ({
  skill,
}) => {
  const [level, setLevel] = useState(skill.level?.level ?? 1);

  const { t } = useTranslation();

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: number | null
  ) => {
    if (value !== null) {
      setLevel(value);
      skillsService.updateAddList(skill, value);
    }
  };

  const remove = () => {
    skillsService.removeFromAddList(skill);
  };

  return (
    <Root>
      <Typography> {skill.label}</Typography>

      <Container>
        <CustomRating
          name="customized-rating"
          value={level}
          onChange={handleChange}
        />
        <IconButton onClick={remove}>
          <DeleteForeverRoundedIcon color="error" />
        </IconButton>
        <Typography sx={{ ml: "auto" }}>{t(`skill_level.${level}`)}</Typography>
      </Container>
    </Root>
  );
};

export default SkillAddItem;
