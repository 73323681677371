import { styled } from "@mui/material";
import React from "react";

const SRoot = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  font-weight: bold;
  color: #5d5d5d;
  font-size: 16px;
`;

const SProgress = styled("div")`
  position: relative;
  width: 200px;
  height: 10px;
  border-radius: 30px;
  background: #ffce71;
`;

const SProgressBar = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: #e48f2c;
  border-radius: 30px;
  transition: all 0.1s ease;
`;

const SCurrent = styled("span")`
  color: #e48f2c;
`;

type MoodGameProgressProps = {
  choices: number;
  deck: number;
};

const MoodGameProgress: React.FC<MoodGameProgressProps> = ({
  choices,
  deck,
}) => {
  const completion = (choices / deck) * 100;

  return (
    <SRoot>
      <span>
        <SCurrent>{choices}</SCurrent>/{deck}
      </span>
      <SProgress>
        <SProgressBar style={{ width: `${completion}%` }} />
      </SProgress>
    </SRoot>
  );
};

export default MoodGameProgress;
