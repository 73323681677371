import { styled } from "@mui/material";
import React from "react";
import ActionBlock from "./MoodResultsActionBlock";
import List from "./MoodResultsList";

const SRoot = styled("div")`
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  border-radius: 24px;
  flex-direction: column;
`;

const MoodResults: React.FC<any> = ({ results, redo, webapp = false }) => {
  return (
    <SRoot>
      <div style={{ flex: 1, marginTop: "20px", marginBottom: "20px" }}>
        <ActionBlock redo={redo} works={webapp} />
      </div>
      <div style={{ flex: 1, marginTop: "20px", marginBottom: "20px" }}>
        <List items={results} />
      </div>
    </SRoot>
  );
};

export default MoodResults;
