import {
  Button,
  Card,
  CardContent,
  Modal,
  styled,
  Typography,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { HighlightOff } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export const modalStyle: any = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "98vh",
  overflow: "hidden",
  borderRadius: "6px",
  width: { xs: "95%", sm: "80%", md: "780px" },
};

const SButton = styled(Button)`
  width: 200px;
  height: 50px;
  font-size: 14px;
  margin-top: 20px;
  border-radius: 25px;
  /* background-color: #434343; */
  box-shadow: 0px 25px 40px #00000008;
  /* &:hover {
    background-color: #434343;
  } */
`;

const Footer = styled("div")`
  display: flex;
  justify-content: center;
`;

const Logo = styled("img")`
  width: 92px;
  height: auto;
`;

const Title = styled("img")`
  margin: 10px;
`;

const LogoContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Content = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down(900)]: {
    flexDirection: "column-reverse",
    "& .logoContainer": {
      marginBottom: "20px",
      marginTop: "10px",
    },
  },
}));

const MoodPopup: React.FC<any> = ({ tests }) => {
  const user_id = useSelector((state: any) => state.user.user_id);
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const redirect = () => {
    navigate("/app/mood");
  };

  useEffect(() => {
    if (tests?.isPassed || !user_id) return;

    const hasShown = localStorage.getItem(`${user_id}_mood_popup`);
    if (!hasShown) {
      localStorage.setItem(`${user_id}_mood_popup`, "true");
      setShow(true);
    }
  }, [user_id, tests]);

  return (
    <>
      {show && (
        <Modal open={show}>
          <Card
            sx={{
              ...modalStyle,
              outline: "none",
              background:
                "transparent linear-gradient(80deg, #dd736a 0%, #e8a100 100%) 0% 0%\n    no-repeat padding-box",
              color: "white",
            }}
          >
            <CardContent sx={{ pt: 0 }}>
              <div style={{ display: "flex" }}>
                <IconButton
                  sx={{
                    marginLeft: "auto",
                    color: "white",
                    marginRight: "-18px",
                  }}
                  aria-label="close"
                  onClick={() => setShow(false)}
                >
                  <HighlightOff />
                </IconButton>
              </div>

              <Typography
                sx={{ textDecoration: "underline", fontWeight: "bold" }}
                variant="subtitle1"
              >
                Nouveau !
              </Typography>

              <Content>
                <div>
                  <Typography
                    variant="h2"
                    sx={{ lineHeight: "31px", marginBottom: 2 }}
                  >
                    Avec « Mood » matchez par vos Centres d’intérêts !
                  </Typography>
                  <Typography variant="body1">{t("work.mood")}</Typography>
                </div>

                <LogoContainer className="logoContainer">
                  <Logo src="/static/mood/logo.svg" />
                  <Title src="/static/mood/name.svg" />
                </LogoContainer>
              </Content>

              <Footer>
                <SButton variant="contained" onClick={redirect}>
                  Découvrir « Mood »
                </SButton>
              </Footer>
            </CardContent>
          </Card>
        </Modal>
      )}
    </>
  );
};

export default MoodPopup;
