import styled from "@emotion/styled";
import { Avatar } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import drawer from "../../services/drawer.service";

const AvatarFrame = styled("div")`
  border: 2px solid white;
  padding: 3px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  position: relative;
`;

const RedDot = styled("div")`
  width: 16px;
  height: 16px;
  background: #ff7272;
  border-radius: 50%;
  position: absolute;
  left: 0px;
  top: -4px;
  z-index: 2;
`;

const ProfilePicture: React.FC = () => {
  const user = useSelector((state: any) => state.user);
  const count = useSelector((state: any) => state.notificationsCount);

  return (
    <>
      <AvatarFrame onClick={() => drawer.open()}>
        {count > 0 && <RedDot />}
        <Avatar
          sx={{ width: "48px", height: "48px" }}
          alt="ee"
          src={user.image}
        />
      </AvatarFrame>
    </>
  );
};

export default ProfilePicture;
