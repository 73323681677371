import { Rating, styled } from "@mui/material";
import clsx from "clsx";
import React from "react";

const StyledRating = styled(Rating)({
  "&.Mui-disabled": {
    opacity: "1",
  },
  "& .MuiRating-iconFilled": {
    color: "#4c6fff",
  },
  "& .MuiRating-iconHover": {
    color: "#4c6fff",
  },
});

const Bullet = styled("div")`
  width: 20px;
  height: 20px;
  border-radius: 25px;
  border: 1px solid #c4cdd5;
  margin: 4px;
  &.disabled {
    background: #c4cdd5;
    border: 1px solid #c4cdd5;
  }
`;

const CustomRating: React.FC<any> = ({ ...props }) => {
  return (
    <StyledRating
      {...props}
      max={4}
      icon={
        <Bullet style={{ background: "#4c6fff", borderColor: "#4c6fff" }} />
      }
      emptyIcon={<Bullet className={clsx(props.disabled && "disabled")} />}
    />
  );
};

export default CustomRating;
