import { SET_HOME } from "../actions/homeActions";
import initialState from "../initialState";

export const homeReducer = (state = initialState.home, action: any) => {
  switch (action.type) {
    case SET_HOME:
      return {
        ...action.value,
      };
    default:
      return { ...state };
  }
};
