import { Stack } from "@mui/material";
import React from "react";
import useLicenses from "../../hooks/useLicenses";
import LicensesCategory from "./LicensesCategory";

const LicensesList: React.FC = () => {
  const { loading, error, referential } = useLicenses();

  return (
    <div>
      {!loading && (
        <Stack spacing={4}>
          {referential.map((category) => (
            <LicensesCategory key={category.code} category={category} />
          ))}
        </Stack>
      )}
    </div>
  );
};

export default LicensesList;
