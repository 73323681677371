import axios from "axios";
import { toast } from "react-toastify";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";

export const INIT = "INIT";
export const SET_EVENTSOURCES = "SET_EVENTSOURCES";
export const SET_CONVERSATION = "SET_CONVERSATION";
export const SET_QUEUE = "SET_QUEUE";

export const initChat =
  (): ThunkAction<void, any, unknown, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await axios.get("chat/conversations");

      const list = res.data["hydra:member"];

      dispatch(setConversations(list ?? []));
    } catch (err) {
      console.error(err);
    }
  };

export const handleConversation =
  (conv: any): ThunkAction<void, any, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const conversations = getState().chat.conversations;
    const index = conversations.findIndex((el: any) => el.id === conv.id);
    if (index > -1) {
      conversations[index] = conv;
    } else {
      conversations.push(conv);
    }

    dispatch(setConversations(conversations ?? []));
  };

const setConversations = (conv: any) => {
  return {
    type: SET_CONVERSATION,
    payload: conv,
  };
};

const handleMessage =
  (event: any): ThunkAction<void, any, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const conversations = [...getState().chat.conversations];
    const index = conversations.findIndex((el: any) => el.id === event.convId);
    conversations[index].messages.push(event);

    // Handle queue
    const queue = [...getState().chat.queue];

    const indexQueue = queue.findIndex(
      (el: any) => el.conversation === `/api/chat/conversation/${event.convId}`
    );

    queue.splice(indexQueue, 1);

    dispatch(setQueue(queue));

    dispatch(setConversations([...conversations]));
  };

export const handleMessageEvent =
  (event: any): ThunkAction<void, any, unknown, AnyAction> =>
  async (dispatch, getState) => {
    // console.log(event);
    switch (event.type) {
      case "message":
        dispatch(handleMessage(event));
        break;
      default:
        break;
    }
  };

export const sendMessage =
  (message: any): ThunkAction<void, any, unknown, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(setQueue([...getState().chat.queue, message]));
      await axios.post("chat/message", message);
    } catch (err) {
      toast.error("Le message n'a pas pu être envoyé");
    }
  };

const setQueue = (messages: any) => {
  return {
    type: SET_QUEUE,
    payload: messages,
  };
};
