import { useEffect, useState } from "react";
import { map } from "rxjs";
import { SkillsCategoryGroup } from "../@types/SkillCategoryGroup";
import skillsService from "../services/skills.service";
import { sortSkillsByCategory } from "../utils/skills.utils";

const useSkills = (
  transversal?: boolean
): {
  loading: boolean;
  skills: SkillsCategoryGroup[];
  error: boolean;
} => {
  const [loading, setLoading] = useState(true);
  const [skills, setSkills] = useState<SkillsCategoryGroup[]>([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    const initSub = skillsService
      .get(transversal ? "transversal" : "")
      .subscribe({
        next: () => {
          setLoading(false);
          setLoading(false);
        },
        error: () => {
          setError(true);
          setLoading(false);
        },
      });
    const skillsSub = skillsService._skills
      .pipe(
        map((x) => {
          return sortSkillsByCategory(x);
        })
      )
      .subscribe((data) => setSkills(data));

    return () => {
      initSub.unsubscribe();
      skillsSub.unsubscribe();
    };
  }, []);
  return { loading, skills, error };
};
export default useSkills;
