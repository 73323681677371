// ----------------------------------------------------------------------

export default function Menu(theme: any) {
  return {
    MuiMenu: {
      styleOverrides: {
        root: {
          borderRadius: "22px",
          ".MuiPaper-root": {
            borderRadius: "22px",
          },
        },
      },
    },
  };
}
