import { Paper, Typography, IconButton, styled } from "@mui/material";
import clsx from "clsx";
import React from "react";
import { SkillsListEntity } from "../../@types/SkillCategoryGroup";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import skillsService from "../../services/skills.service";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: "16px",
  display: "flex",
  alignItems: "center",
  "&.active": {
    backgroundColor: theme.palette.success.light,
  },
}));

const SkillsSuggestListItem: React.FC<{
  skill: Partial<SkillsListEntity>;
  active: boolean;
}> = ({ skill, active }) => {
  const toggleSkill = (e: Partial<SkillsListEntity>) => {
    skillsService.toggleFromQueue(e);
  };

  return (
    <Item className={clsx(active && "active")} elevation={2}>
      <Typography variant="h5" fontWeight="normal">
        {skill.label}
      </Typography>
      <IconButton onClick={() => toggleSkill(skill)} sx={{ ml: "auto" }}>
        {active ? (
          <CheckCircleRoundedIcon color="success" sx={{ fontSize: "33px" }} />
        ) : (
          <AddCircleRoundedIcon color="success" sx={{ fontSize: "33px" }} />
        )}
      </IconButton>
    </Item>
  );
};

export default SkillsSuggestListItem;
