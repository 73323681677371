// import axios from "axios";

export const SET_HOME = "SET_HOME";

export const setHome = (value: any) => {
  return {
    type: SET_HOME,
    value,
  };
};
