import React, { Suspense } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Loader from "../components/loader/Loader";

// const OnboardingPage = React.lazy(() => import("../pages/OnboardingPage"));
const GamePage = React.lazy(() => import("../pages/GamePage"));

const OnboardingRoutes = () => {
  return useRoutes([
    {
      path: "/app/onboarding",
      element: (
        <Suspense fallback={<Loader inProp={false} />}>
          <GamePage />
        </Suspense>
      ),
    },
    { path: "/", element: <Navigate to="/app/onboarding" replace /> },
    {
      path: "*",
      element: <Navigate to="/app/onboarding" replace />,
    },
  ]);
};

export default OnboardingRoutes;
