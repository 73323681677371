import { Box, Typography, styled } from "@mui/material";
import React from "react";
import Big5Archetypes from "./Big5Archetypes";
import Big5DeleteButton from "./Big5DeleteButton";
import Big5PdfButton from "./Big5PdfButton";
import Big5Restitution from "./Big5Restitution";
import { useTranslation } from "react-i18next";

const ButtonsContainer = styled("div")`
  margin-top: 35px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (max-width: 510px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Big5Results: React.FC<any> = ({ results }) => {
  const { t } = useTranslation();
  return (
    <>
      {results ? (
        <>
          <div>
            <Typography marginBottom={2} variant="h4">
              {t("big5.your_profile")}{" "}
              <Typography component="span" color="primary" variant="h4">
                {results.dominante_traits.title}
              </Typography>
            </Typography>
            <Typography marginBottom={2}>
              {results.dominante_traits.desc}
            </Typography>

            <Typography variant="caption" fontSize="14px">
              {t("big5.result.desc_end")}
            </Typography>

            <Big5Restitution
              results={results.results}
              traits={results.traits}
            />

            {results.archetypes && <Big5Archetypes data={results.archetypes} />}

            <ButtonsContainer>
              <Box sx={{ mb: 1 }}>
                <Big5PdfButton />
              </Box>

              <div>
                <Big5DeleteButton />
              </div>
            </ButtonsContainer>
          </div>
        </>
      ) : null}
    </>
  );
};

export default Big5Results;
