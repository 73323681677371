import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserRoutes from "./routes/UserRoutes";
import GuestRoutes from "./routes/GuestRoutes";
import Loader from "./components/loader/Loader";
import { init } from "./store/actions/authActions";
import { authLoader } from "./services/authService";
import { delay } from "rxjs";
import { ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProfileDrawer from "./components/profileDrawer/ProfileDrawer";
import useNotifications from "./hooks/useNotifications";
import OnboardingRoutes from "./routes/OnboardingRoutes";
import useRickRoll from "./hooks/useRickRoll";
import RestrictedUserRoutes from "./routes/RestrictedUserRoutes";
import { styled } from "@mui/material";
import A2HS from "./components/A2HS";
import { useConversations } from "./hooks/useConversations";
import { handleTranslationChanges } from "./utils/utils";

const Root = styled("div")`
  width: 100%;
  min-height: 100vh;
  background: url("/static/backgrounds/main.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  justify-content: center;
`;

const Ctn = styled("div")`
  min-height: 100%;
  width: 540px;
  min-height: 100vh;
`;

const App: React.FC = () => {
  const { isLogged } = useSelector((state: any) => state.auth);
  const onboarding = useSelector((state: any) => state.user?.onboarding);
  const webapp = useSelector((state: any) => state.user?.webapp);
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(true);
  const home = useSelector((state: any) => state.home);

  useNotifications();
  useConversations();

  useEffect(() => {
    console.log("home", home);
    if (home?.labels) {
      handleTranslationChanges(home.labels);
    }
  }, [home]);

  useEffect(() => {
    const sub = authLoader
      .pipe(delay(1000))
      .subscribe((res: boolean) => setLoading(res));

    return () => {
      sub.unsubscribe();
    };
  }, []);

  React.useEffect(() => {
    dispatch(init());
  }, [dispatch]);

  useRickRoll();

  return (
    <Root className="App">
      <Ctn>
        <Loader inProp={loading} />
        {!loading &&
          (isLogged ? (
            onboarding >= 100 ? (
              webapp ? (
                <UserRoutes />
              ) : (
                <RestrictedUserRoutes />
              )
            ) : (
              <OnboardingRoutes />
            )
          ) : (
            <GuestRoutes />
          ))}
        <A2HS />

        <ToastContainer
          limit={3}
          transition={Flip}
          position="top-right"
          autoClose={2000}
          hideProgressBar
          closeOnClick
          pauseOnHover
          draggable
        />
        {!loading && isLogged && <ProfileDrawer />}
      </Ctn>
    </Root>
  );
};

export default App;
