import { Skeleton, Stack, styled } from "@mui/material";
import React from "react";

const SkeletonSkill = styled(Skeleton)`
  height: 80px;
  width: 100%;
  border-radius: 16px;
`;

const SkillsAddSkeleton: React.FC = () => {
  return (
    <div>
      <Stack spacing={2}>
        {[1, 2, 3, 4, 5].map((e) => (
          <SkeletonSkill key={e} variant="rectangular" />
        ))}
      </Stack>
    </div>
  );
};

export default SkillsAddSkeleton;
