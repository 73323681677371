import { Modal, Typography, IconButton, styled } from "@mui/material";
import React, { useState } from "react";
import ModalBody from "../common/ModalBody";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import FeedbackForm from "./FeedbackForm";
import PEModal from "./PEModal";
import { useTranslation } from "react-i18next";

type feedbackModalProps = {
  open: boolean;
  close: () => void;
};

const Header = styled("div")`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const FeedbackModal: React.FC<feedbackModalProps> = ({ open, close }) => {
  const [openLink, setOpenLink] = useState(false);
  const { t } = useTranslation();
  const closeAll = () => {
    setOpenLink(false);
    close();
  };

  return (
    <>
      <Modal
        open={open}
        onClose={closeAll}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          {openLink ? (
            <PEModal open={openLink} close={closeAll} />
          ) : (
            <ModalBody>
              <Header>
                <Typography variant="h5">{t("feedback.title")}</Typography>

                <IconButton sx={{ ml: "auto" }} onClick={close}>
                  <CancelRoundedIcon color="primary" />
                </IconButton>
              </Header>
              <FeedbackForm close={close} openLink={setOpenLink} />
            </ModalBody>
          )}
        </>
      </Modal>
    </>
  );
};

export default FeedbackModal;
