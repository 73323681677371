import { CircularProgress, styled, Typography } from "@mui/material";
import clsx from "clsx";
import React, { useState } from "react";
import { tap } from "rxjs";

import LicenseA from "../../icons/licenses/license_A";
import LicenseB from "../../icons/licenses/license_B";
import LicenseC from "../../icons/licenses/license_C";
import LicenseD from "../../icons/licenses/license_D";
import LicenseE from "../../icons/licenses/license_E";
import licensesService from "../../services/licenses.service";

const Root = styled("div")`
  border: 2px solid #4c6fff;
  border-radius: 22px;
  padding: 20px;
  height: 100%;
  position: relative;
  &.active {
    background: #4c6fff;
  }
  &.loading {
    border-color: transparent;
  }
`;

const Backdrop = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 22px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconContainer = styled("div")`
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LicenseItem: React.FC<any> = ({ license, index }) => {
  const [loading, setLoading] = useState(false);

  const getImageByCode = () => {
    switch (license.code.slice(0, 1)) {
      case "A":
        return <LicenseA active={license.active} size={34 + index * 4} />;
      case "B":
        return <LicenseB active={license.active} size={34 + index * 4} />;
      case "C":
        return <LicenseC active={license.active} size={34 + index * 4} />;
      case "D":
        return <LicenseD active={license.active} size={34 + index * 4} />;
      case "E":
        return <LicenseE active={license.active} size={34 + index * 4} />;
      default:
        return <LicenseB active={license.active} size={34 + index * 4} />;
    }
  };

  const handleClick = () => {
    if (loading) return;
    setLoading(true);
    licensesService
      .handleLicense(license)
      .pipe(tap(() => setLoading(false)))
      .subscribe();
  };

  return (
    <Root
      onClick={handleClick}
      className={clsx(license.active && "active", loading && "loading")}
    >
      <div>
        <IconContainer>{getImageByCode()}</IconContainer>

        <Typography
          sx={{
            color: license.active ? "white" : "inherit",
          }}
          textAlign="center"
          fontWeight="bold"
        >
          {license.code}
        </Typography>
        <Typography
          sx={{
            color: license.active ? "white" : "inherit",
          }}
          textAlign="center"
          variant="body2"
        >
          {license.label}
        </Typography>
      </div>
      {loading && (
        <Backdrop>
          <CircularProgress />
        </Backdrop>
      )}
    </Root>
  );
};

export default LicenseItem;
