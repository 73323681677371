import { Theme } from "@mui/material";
import Button from "./button";
import GlobalStyle from "./gloabStyle";
import Input from "./Input";
import Typography from "./typography";
import Card from "./Card";
import Menu from "./Menu";
import Slider from "./Slider";

export default function ComponentsOverrides(theme: Theme) {
  return {
    ...Input(theme),
    ...GlobalStyle(theme),
    ...Button(theme),
    ...Typography(theme),
    ...Card(theme),
    ...Menu(theme),
    ...Slider(theme),
  };
}
