import { Stack, Typography } from "@mui/material";
import React from "react";
import useSkills from "../../hooks/useSkills";
import SkillsGroup from "./SkillsGroup";
import SkillsGroupSkeleton from "./SkillsGroupSkeleton";

const SkillsList: React.FC<{ transversal?: boolean }> = ({
  transversal = false,
}) => {
  const { loading, skills, error } = useSkills(transversal);

  return (
    <Stack spacing={2}>
      {!loading &&
        !error &&
        skills.map((e) => (
          <SkillsGroup transversal={transversal} key={e.id} skillsGroup={e} />
        ))}
      {!loading && !error && skills.length === 0 && (
        <Typography textAlign="center">
          Vous n'avez renseigné aucune compétence
        </Typography>
      )}
      {loading && (
        <>
          {[1, 2].map((e) => (
            <SkillsGroupSkeleton key={e} />
          ))}
        </>
      )}
    </Stack>
  );
};

export default SkillsList;
