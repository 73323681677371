import FileSaver from "file-saver";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import http from "../../../services/http";
import { toastError } from "../../../utils/toasts";
import { getEnvWithoutAPI } from "../../../utils/utils";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { Button, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

const Big5PdfButton = () => {
  const [loading, setLoading] = useState(false);
  const { firstName, lastName, token_pdf, person_id } = useSelector(
    (state: any) => state.user
  );
  const locale = "fr";
  const { t } = useTranslation();

  const download = () => {
    setLoading(true);
    http
      .get(`${process.env.REACT_APP_PDF_URL}/api/b5`, {
        transformRequest: (data, headers: any) => {
          // delete headers.common["X-Context"];
          return data;
        },
        withCredentials: false,
        responseType: "blob",
        headers: { "Content-Type": "blob" },
        params: {
          token: token_pdf,
          apiUrl: getEnvWithoutAPI(),
          personId: person_id,
          locale,
        },
      })
      .subscribe({
        next: (res) => {
          const name = `${firstName}_${lastName}`;
          const typeName = "_Personnalité";
          const blob = new Blob([res.data], { type: "application/pdf" });
          FileSaver.saveAs(blob, `${name}${typeName}.pdf`);
          setLoading(false);
        },
        error: () => {
          toastError();
          setLoading(false);
        },
      });
  };

  return (
    <Button
      disableElevation
      disabled={loading}
      variant="outlined"
      color="secondary"
      onClick={download}
      sx={{ fontSize: "14px" }}
    >
      {t("btn.download_results")}
      {loading ? (
        <CircularProgress
          color="inherit"
          size={"16px"}
          sx={{
            marginLeft: "10px",
            marginBottom: "-1px",
          }}
        />
      ) : (
        <DownloadRoundedIcon
          sx={{
            fontSize: "20px",
            marginLeft: "10px",
            marginBottom: "-1px",
          }}
        />
      )}
    </Button>
  );
};

export default Big5PdfButton;
