const initialState = {
  auth: {
    isLogged: false,
  },
  home: {},
  user: {},
  experiences: {
    experiences: [],
  },
  notificationsCount: 0,
  chat: {
    conversations: [],
    queue: [],
  },
};

export default initialState;
