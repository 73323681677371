import { alpha } from "@mui/material/styles";

// ----------------------------------------------------------------------

function createGradient(color1: any, color2: any) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

function createLinearGradient(color1: any, color2: any) {
  return `linear-gradient(to right, ${color1}, ${color2})`;
}

function createDiagonalGradient(color1: any, color2: any) {
  return `linear-gradient(125deg, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
  0: "#FFFFFF",
  100: "#F9FAFB",
  200: "#F4F6F8",
  300: "#DFE3E8",
  400: "#C4CDD5",
  500: "#919EAB",
  600: "#637381",
  700: "#454F5B",
  800: "#212B36",
  900: "#161C24",
  500_8: alpha("#919EAB", 0.08),
  500_12: alpha("#919EAB", 0.12),
  500_16: alpha("#919EAB", 0.16),
  500_24: alpha("#919EAB", 0.24),
  500_32: alpha("#919EAB", 0.32),
  500_48: alpha("#919EAB", 0.48),
  500_56: alpha("#919EAB", 0.56),
  500_80: alpha("#919EAB", 0.8),
};

const GREEN = {
  0: "#FFFFFF",
  100: "#D6F9D8",
  200: "#ADF3B1",
  300: "#85EC8A",
  400: "#5CE662",
  500: "#1EC626",
  600: "#19A520",
  700: "#148419",
  800: "#0F6313",
  900: "#0A420D",
  500_8: alpha("#1EC626", 0.08),
  500_12: alpha("#1EC626", 0.12),
  500_16: alpha("#1EC626", 0.16),
  500_24: alpha("#1EC626", 0.24),
  500_32: alpha("#1EC626", 0.32),
  500_48: alpha("#1EC626", 0.48),
  500_56: alpha("#1EC626", 0.56),
  500_80: alpha("#1EC626", 0.8),
};

const RED = {
  0: "#FFFFFF",
  100: "#FAD4E0",
  200: "#F5A9C1",
  300: "#F07FA2",
  400: "#EB5483",
  500: "#E62964",
  600: "#C2174C",
  700: "#921139",
  800: "#610B26",
  900: "#310613",
  500_8: alpha("#E62964", 0.08),
  500_12: alpha("#E62964", 0.12),
  500_16: alpha("#E62964", 0.16),
  500_24: alpha("#E62964", 0.24),
  500_32: alpha("#E62964", 0.32),
  500_48: alpha("#E62964", 0.48),
  500_56: alpha("#E62964", 0.56),
  500_80: alpha("#E62964", 0.8),
};

const ORANGE = {
  0: "#FFFFFF",
  100: "#FEEFD5",
  200: "#FCDFAB",
  300: "#FBCF82",
  400: "#F9BF58",
  500: "#F8AF2E",
  600: "#E49408",
  700: "#AB6F06",
  800: "#724A04",
  900: "#392502",
  500_8: alpha("#F8AF2E", 0.08),
  500_12: alpha("#F8AF2E", 0.12),
  500_16: alpha("#F8AF2E", 0.16),
  500_24: alpha("#F8AF2E", 0.24),
  500_32: alpha("#F8AF2E", 0.32),
  500_48: alpha("#F8AF2E", 0.48),
  500_56: alpha("#F8AF2E", 0.56),
  500_80: alpha("#F8AF2E", 0.8),
};

const PURPLE = {
  0: "#FFFFFF",
  100: "#FBEDFF",
  200: "#C9B1E2",
  300: "#AF8BD4",
  400: "#9464C5",
  500: "#9367C1",
  600: "#61358E",
  700: "#49286B",
  800: "#301A47",
  900: "#180D24",
  500_8: alpha("#9367C1", 0.08),
  500_12: alpha("#9367C1", 0.12),
  500_16: alpha("#9367C1", 0.16),
  500_24: alpha("#9367C1", 0.24),
  500_32: alpha("#9367C1", 0.32),
  500_48: alpha("#9367C1", 0.48),
  500_56: alpha("#9367C1", 0.56),
  500_80: alpha("#9367C1", 0.8),
};

const PRIMARY = {
  lighter: "#DBE2FF",
  light: "#4c6fff29",
  main: "#4C6FFF",
  dark: "#0027C7",
  darker: "#000D42",
  contrastText: "#fff",
  100: "#E4F2FC",
  200: "#B7C5FF",
  300: "#94A9FF",
  400: "#708CFF",
  500: "#4C6FFF",
  600: "#0A3AFF",
  700: "#0027C7",
  800: "#001A84",
  900: "#000D42",
  500_3: alpha("#4C6FFF", 0.03),
  500_8: alpha("#4C6FFF", 0.08),
  500_12: alpha("#4C6FFF", 0.12),
  500_16: alpha("#4C6FFF", 0.16),
  500_24: alpha("#4C6FFF", 0.24),
  500_32: alpha("#4C6FFF", 0.32),
  500_48: alpha("#4C6FFF", 0.48),
  500_56: alpha("#4C6FFF", 0.56),
  500_80: alpha("#4C6FFF", 0.8),
};
const SECONDARY = {
  lighter: "#EDF1F7",
  light: "#505887",
  main: "#2E3A59",
  dark: "#192038",
  darker: "#101426",
  contrastText: "#fff",
  100: "#EDF1F7",
  200: "#E4E9F2",
  300: "#C5CEE0",
  400: "#8F9BB3",
  500: "#2E3A59",
  600: "#222B45",
  700: "#192038",
  800: "#151A30",
  900: "#101426",
  500_8: alpha("#2E3A59", 0.08),
  500_12: alpha("#2E3A59", 0.12),
  500_16: alpha("#2E3A59", 0.16),
  500_24: alpha("#2E3A59", 0.24),
  500_32: alpha("#2E3A59", 0.32),
  500_48: alpha("#2E3A59", 0.48),
  500_56: alpha("#2E3A59", 0.56),
  500_70: alpha("#2E3A59", 0.7),
  500_80: alpha("#2E3A59", 0.8),
  500_96: alpha("#2E3A59", 0.06),
  300_20: alpha("#505887", 0.2),
};
const INFO = {
  lighter: "#F2F8FF",
  light: "#94CBFF",
  main: "#0095FF",
  dark: "#0057C2",
  darker: "#002885",
  contrastText: "#fff",
  100: "#F2F8FF",
  200: "#C7E2FF",
  300: "#94CBFF",
  400: "#42AAFF",
  500: "#0095FF",
  600: "#006FD6",
  700: "#0057C2",
  800: "#0041A8",
  900: "#002885",
  500_8: alpha("#0095FF", 0.08),
  500_12: alpha("#0095FF", 0.12),
  500_16: alpha("#0095FF", 0.16),
  500_24: alpha("#0095FF", 0.24),
  500_32: alpha("#0095FF", 0.32),
  500_48: alpha("#0095FF", 0.48),
  500_56: alpha("#0095FF", 0.56),
  500_80: alpha("#0095FF", 0.8),
};
const SUCCESS = {
  lighter: "#EDFFF3",
  light: "#1ec62629",
  main: "#1EC626",
  dark: "#008F72",
  darker: "#00524C",
  contrastText: GREY[800],
  100: "#EDFFF3",
  200: "#B3FFD6",
  300: "#8CFAC7",
  400: "#51F0B0",
  500: "#00E096",
  600: "#00B383",
  700: "#008F72",
  800: "#007566",
  900: "#00524C",
  500_8: alpha("#00E096", 0.08),
  500_12: alpha("#00E096", 0.12),
  500_16: alpha("#00E096", 0.16),
  500_24: alpha("#00E096", 0.24),
  500_32: alpha("#00E096", 0.32),
  500_48: alpha("#00E096", 0.48),
  500_56: alpha("#00E096", 0.56),
  500_80: alpha("#00E096", 0.8),
};
const WARNING = {
  lighter: "#FFF9DB",
  light: "#FFE59E",
  main: "#FFAA00",
  dark: "#B86E00",
  darker: "#703C00",
  contrastText: GREY[800],
  100: "#FFF9DB",
  200: "#FFF1C2",
  300: "#FFE59E",
  400: "#FFC94C",
  500: "#FFAA00",
  600: "#DB8B00",
  700: "#B86E00",
  800: "#945400",
  900: "#703C00",
  500_8: alpha("#FFAA00", 0.08),
  500_12: alpha("#FFAA00", 0.12),
  500_16: alpha("#FFAA00", 0.16),
  500_24: alpha("#FFAA00", 0.24),
  500_32: alpha("#FFAA00", 0.32),
  500_48: alpha("#FFAA00", 0.48),
  500_56: alpha("#FFAA00", 0.56),
  500_80: alpha("#FFAA00", 0.8),
};
const ERROR = {
  lighter: "#FFF2F2",
  light: "#FFA8B4",
  main: "#F75010",
  dark: "#B81D5B",
  darker: "#700940",
  contrastText: "#fff",
  100: "#FFF2F2",
  200: "#FFD6D9",
  300: "#FFA8B4",
  400: "#FF708D",
  500: "#FF3D71",
  600: "#DB2C66",
  700: "#B81D5B",
  800: "#94124E",
  900: "#700940",
  500_8: alpha("#FF3D71", 0.08),
  500_12: alpha("#FF3D71", 0.12),
  500_16: alpha("#FF3D71", 0.16),
  500_24: alpha("#FF3D71", 0.24),
  500_32: alpha("#FF3D71", 0.32),
  500_48: alpha("#FF3D71", 0.48),
  500_56: alpha("#FF3D71", 0.56),
  500_80: alpha("#FF3D71", 0.8),
};

const TESTS = {
  b5: "#3E74FF",
  cf: "#FE7A89",
  inc: "#C1A2FE",
  skills: "#efa500",
  b5_light: "#F5F7FF",
  cf_light: "#FFF2F6",
  inc_light: "#f9f2ff",
};

const B5_COLORS = {
  openness: "#f7b02e",
  conscientiousness: "#1a52c2",
  extraversion: "#e52963",
  agreeableness: "#1fc726",
  neuroticism: "#7842b2",
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
  purple: createGradient(PURPLE[500], PRIMARY[500]),
  b5: createGradient("#39c4ff", "#7081ff"),
  cf: createGradient("#feb2a3", "#fd7c9e"),
  inc: createGradient("#9cddff", "#e89ffd"),
  skills: createGradient("#fbda7e", "#ffa934"),
  b5_linear: createLinearGradient("#505DFF", "#01BDFF"),
  cf_linear: createLinearGradient("#FEA48C", "#FD4E85"),
  inc_linear: createLinearGradient("#82D7FF", "#E683FC"),
  abilities_linear: createLinearGradient("#FFEA90", "#FFC505"),
  skills_linear: createLinearGradient(PURPLE[500], PURPLE[200]),
  b5_test: createLinearGradient("#2ea9bb", "#302a77"),
  cf_test: createLinearGradient("#efa396", "#eed1d5"),
  inc_test: createDiagonalGradient("#21c0ec", "#8842ef"),
};

const CONTEXT_COLORS = {
  advisor: { background: "#001338", font: "#FFF" },
  collab: { background: "#f6f9fd", font: "#2E3A59" },
  admin: { background: "#001338", font: "#FFF" },
  recruiter: { background: "#0b0b0b", font: "#FFF" },
};

const palette = {
  common: { black: "#000", white: "#fff" },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  tests: { ...TESTS },
  b5: { ...B5_COLORS },
  grey: GREY,
  green: GREEN,
  blue: PRIMARY,
  red: RED,
  collabBlue: "#f6f9fd",
  orange: ORANGE,
  purple: PURPLE,
  gradients: GRADIENTS,
  contextColors: CONTEXT_COLORS,
  divider: GREY[500_24],
  text: {
    primary: SECONDARY["light"],
    secondary: GREY[600],
    disabled: GREY[500],
  },
  background: { paper: "#fff", default: "#fff", neutral: GREY[200] },
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default palette;
