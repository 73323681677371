import { Typography, Avatar, IconButton, styled } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import ProfileEditModal from "./ProfileEditModal";

const Root = styled("div")`
  background: #eceef7;
  width: 100%;
  height: 127px;
  border-radius: 10px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  padding: 10px;
`;

const CustomIconButton = styled(IconButton)`
  position: absolute;
  top: -5px;
  right: -5px;
  background: #4c6fff;
  border-radius: 50%;
  padding: 5px;
  width: 34px;
  height: 34px;
  color: white;
  &:focus {
    background: #4c6fff;
  }
`;

const AvatarOutline = styled("div")`
  margin-left: auto;
  border: 2px solid white;
  border-radius: 50%;
  padding: 4px;
  position: relative;
`;

const CustomAvatar = styled(Avatar)`
  width: 80px;
  height: 80px;
`;

const ProfileDrawerInfos: React.FC = () => {
  const user = useSelector((state: any) => state.user);
  const [openModal, setOpenModal] = useState(false);

  return (
    <Root>
      <div>
        <Typography variant="h3">{user.firstName}</Typography>
        <Typography variant="h3">{user.lastName}</Typography>
      </div>
      <AvatarOutline>
        <CustomAvatar
          alt={`${user.firstName} ${user.lastName}`}
          src={user.image}
        />

        <CustomIconButton onClick={() => setOpenModal(true)}>
          <ModeEditOutlineOutlinedIcon sx={{ height: "21px" }} />
        </CustomIconButton>
      </AvatarOutline>
      <ProfileEditModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
      />
    </Root>
  );
};

export default ProfileDrawerInfos;
