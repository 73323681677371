import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";

const TrainingHeader: React.FC<{
  title: string;
  content: string;
  goal: string;
  entreprise: string;
}> = ({ title, content, goal, entreprise }) => {
  return (
    <div>
      <Typography variant="h4">{title}</Typography>
      <Typography>Par {entreprise}</Typography>

      <Divider sx={{ margin: 2 }} />

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography color="primary" variant="h5">
            Contenu de la formation
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography textAlign="left">{content}</Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography variant="h5" color="primary">
            Objectif de la formation
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography textAlign="left">{goal}</Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default TrainingHeader;
