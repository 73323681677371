import { SET_QUEUE, INIT, SET_CONVERSATION } from "../actions/chatActions";
import initialState from "../initialState";

export const chatReducer = (state = initialState.chat, action: any) => {
  switch (action.type) {
    case INIT:
      return {
        ...action.value,
      };

    case SET_CONVERSATION:
      return {
        ...state,
        conversations: action.payload,
      };
    case SET_QUEUE:
      return {
        ...state,
        queue: action.payload,
      };
    default:
      return { ...state };
  }
};
