import axios, { AxiosResponse } from "axios";
import { logout } from "./utils/logout";

export const setAxios = () => {
  /** axios configuration */

  const origin = window.location.origin;
  const env = process.env.REACT_APP_ENV;

  let baseUrl = process.env.REACT_APP_BASEURL;

  if (
    !origin.includes("monkey-tie") &&
    process.env.REACT_APP_GOM !== "true" &&
    !origin.includes("localhost")
  ) {
    const split = origin.split(".");
    split[0] = `https://mtback-${env}`;
    baseUrl = split.join(".") + "/api/";
  }

  axios.defaults.baseURL = baseUrl;
  axios.defaults.withCredentials = true;

  // axios.defaults.headers["Accept"] = "application/ld+json";
  // axios.defaults.headers["Content-Type"] = "application/ld+json";
  axios.defaults.headers.common["content-language"] =
    localStorage.getItem("lng") || "fr";
  // axios.defaults.headers.common["X-Context"] = "null";

  axios.interceptors.response.use(
    function (response: AxiosResponse) {
      return response;
    },
    function (error) {
      if (error?.response?.status === 401) {
        logout();
      }
      return Promise.reject(error?.response ?? error); // transform response.response -> response
    }
  );
};
