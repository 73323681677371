import { ButtonBase, styled, Typography } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { logout } from "../../utils/logout";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import drawer from "../../services/drawer.service";
import { useTranslation } from "react-i18next";

const Button = styled(ButtonBase)`
  color: rgb(255 114 114);
  font-weight: bold;
`;

const LogoutButton = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const out = async () => {
    setLoading(true);

    try {
      await axios.post("logout");
      drawer.close();
      logout();
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <div>
      <Button disabled={loading} onClick={out}>
        <LogoutRoundedIcon sx={{ mr: 1 }} />
        <Typography sx={{ fontWeight: "bold" }}>{t("auth.logout")}</Typography>
      </Button>
    </div>
  );
};

export default LogoutButton;
