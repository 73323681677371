import * as React from "react";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material";
import { navHeight } from "../../globalConfig";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

interface linkItem {
  to: string;
  icon: string;
  title: string;
  activeIcon: string;
}

const SRoot = styled("div")`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
`;

const Root = styled("div")`
  margin-left: auto;
  margin-right: auto;
  background: white;
  height: ${navHeight}px;
  width: 540px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: -1px 13px 15px 7px #000000;
  z-index: 100;
  @media screen and (max-width: 540px) {
    width: 100%;
  }
`;

const NavItem = styled(NavLink)`
  display: flex;
  text-decoration: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > .MuiTypography-root {
    color: #b4b4b4;
    transition: all 0.1s linear;
    font-weight: normal !important;
  }
  &.active > .MuiTypography-root {
    color: #3b3b3b;
    font-weight: normal !important;
  }
`;

const IconCtn = styled("div")`
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  .icon {
    max-width: 24px;
    height: 24px;
  }
`;

const Navbar: React.FC = () => {
  const location = useLocation();
  const hasTrainings = useSelector(
    (state: any) => state.user?.trainings ?? false
  );
  const { t } = useTranslation();

  const routes: linkItem[] = [
    {
      to: "/app/home",
      icon: "/static/nav-logo/home.svg",
      title: t("menu.home"),
      activeIcon: "/static/nav-logo/home_active.svg",
    },
    {
      to: "/app/works",
      icon: "/static/nav-logo/works.svg",
      title: t("work.title_plural"),
      activeIcon: "/static/nav-logo/works_active.svg",
    },
    hasTrainings && {
      to: "/app/trainings",
      icon: "/static/nav-logo/trainings.svg",
      title: t("menu.trainings"),
      activeIcon: "/static/nav-logo/trainings_active.svg",
    },
    {
      to: "/app/results",
      icon: "/static/nav-logo/results.svg",
      title: t("menu.tests"),
      activeIcon: "/static/nav-logo/results_active.svg",
    },
  ].filter((el) => el && el !== false);

  const isActive = (path: string) => {
    return path === location.pathname;
  };

  return (
    <SRoot>
      <Root>
        {routes.map((link) => (
          <NavItem key={link.to} to={link.to}>
            <IconCtn>
              <img
                className="icon"
                style={{ maxHeight: "39px", maxWidth: "39px" }}
                alt="home"
                src={isActive(link.to) ? link.activeIcon : link.icon}
              />
            </IconCtn>
            <Typography variant="subtitle2">{link.title}</Typography>
          </NavItem>
        ))}
      </Root>
    </SRoot>
  );
};
export default Navbar;
