import { styled, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import TrainingHeader from "../../components/trainings/TrainingHeader";
import TrainingInfos from "../../components/trainings/TrainingInfos";
import { pageWithNavbar } from "../../globalConfig";
import Page from "../../layouts/Page";
import TrainingPageHeader from "./TrainingPageHeader";

const Content = styled("div")`
  background: white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  width: 100%;
  height: 100%;
  margin-top: -70px;
  min-height: 120px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const TrainingPage = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any>(null);

  const title = data?.intituleFormation?.value ?? "";
  const entp = data?.organismeFormationResponsable?.nomOrganisme?.value ?? "";

  useEffect(() => {
    (async () => {
      try {
        const call = await fetch(
          `${process.env.REACT_APP_TRAININGS_URL}/${id}`,
          {
            headers: {
              "Content-Type": "application/ld+json",
              Accept: "application/ld+json",
              authorization:
                "9B0917DAFEAAE1932DC22BE55F23D631E0344CE47E761D88598703300097131A",
            },
          }
        );
        const json = await call.json();
        setData(json);

        setLoading(false);

        console.log(json);
      } catch (err) {
        setLoading(false);
      }
    })();

    return () => {};
  }, [id]);

  return (
    <Page title="pages.training" style={{ ...pageWithNavbar }}>
      <TrainingPageHeader />
      <Content>
        {loading && <Loader inProp={true} />}
        {!loading && data && (
          <div>
            <TrainingHeader
              content={data?.contenuFormation?.value ?? ""}
              goal={data?.objectifFormation?.value ?? ""}
              title={title}
              entreprise={entp}
            />
            <TrainingInfos data={data} />
          </div>
        )}
      </Content>
    </Page>
  );
};

export default TrainingPage;
