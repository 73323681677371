import React, { Suspense } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import Loader from "../components/loader/Loader";

const Login = React.lazy(() => import("../pages/login/Login"));
const NotFound = React.lazy(() => import("../pages/NotFound"));
const GameCGUV = React.lazy(() => import("../pages/GameCGUV"));
const SignUpPage = React.lazy(() => import("../pages/SignUpPage"));

const GuestRoutes = () => {
  return useRoutes([
    {
      path: "/login",
      element: (
        <Suspense fallback={<Loader inProp={false} />}>
          <Login />
        </Suspense>
      ),
    },
    { path: "/", element: <Navigate to="/login" replace /> },
    {
      path: "/game-cguv",
      element: (
        <Suspense fallback={<Loader inProp={false} />}>
          <GameCGUV />
        </Suspense>
      ),
    },
    {
      path: "sign-up",
      element: (
        <Suspense fallback={<Loader inProp={false} />}>
          <SignUpPage />
        </Suspense>
      ),
    },
    {
      path: "/404",
      element: (
        <Suspense fallback={<Loader inProp={true} />}>
          <NotFound />
        </Suspense>
      ),
    },
    {
      path: "*",
      element: <Navigate to="/login" replace />,
    },
  ]);
};

export default GuestRoutes;
