import { SET_NOTIFICATIONS_COUNT } from "../actions/notificationsActions";
import initialState from "../initialState";

export const notificationsCountReducer = (
  state = initialState.notificationsCount,
  action: any
) => {
  switch (action.type) {
    case SET_NOTIFICATIONS_COUNT:
      return action.payload;
    default:
      return state;
  }
};
