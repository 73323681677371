import { styled } from "@mui/material";

const ModalBody = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "354px",
  maxHeight: "90vh",
  overflow: "auto",
  borderRadius: "22px",
  background: "white",
  boxShadow: theme.shadows[24],
  padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
  "&:focus": {
    outline: "none",
  },
}));

export default ModalBody;
