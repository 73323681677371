import axios from "axios";
import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { setAuthLoader } from "../../services/authService";
import { setHome } from "./homeActions";
import { setUser } from "./userActions";

export const SET_LOGGED = "SET_LOGGED";

export const setLogged = (value: boolean) => {
  if (!value) {
    localStorage.clear();
    sessionStorage.clear();
  }

  return {
    type: SET_LOGGED,
    value,
  };
};

export const init =
  (): ThunkAction<void, any, unknown, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const home = (await axios.get("home")).data["hydra:member"][0];
      dispatch(setHome(home));

      const is_logged = (await axios.get("is_logged")).data;
      dispatch(setUser(is_logged));

      const { person_id } = is_logged;

      await handleSearchParams();

      const completeness = (await axios.get(`people/${person_id}/completeness`))
        .data?.completenessOfProfile;

      localStorage.setItem("completeness", JSON.stringify(completeness));

      dispatch(setLogged(true));
    } catch (err) {
    } finally {
      setAuthLoader(false);
    }
  };

const parse = (value: any) => {
  try {
    return JSON.parse(value);
  } catch (err) {
    return value;
  }
};

const parseSearch = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const obj: any = {};
  searchParams.forEach((value, key) => {
    obj[key] = parse(value);
  });
  return obj;
};

const handleSearchParams = async () => {
  try {
    const { data, test } = parseSearch();

    if (test === "mood" && data) {
      const body = {
        duration: 0,
        raw: data,
        test: `/api/tests/${test}`,
      };

      await axios.post(`results`, body);
    }
  } catch (err) {
    console.error(err);
  }
};
