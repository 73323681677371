import styled from "@emotion/styled";
import { ButtonBase, Typography } from "@mui/material";
import React from "react";
import ProfilePicture from "../profileDrawer/ProfilePicture";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { useNavigate } from "react-router-dom";

const PreviousButton = styled(ButtonBase)`
  background: rgba(255, 255, 255, 0.36);
  padding: 13px;
  margin-right: 20px;
  margin-left: 20px;
  border-radius: 15px;
`;

const ProfileCtn = styled("div")`
  margin-right: 20px;
  margin-left: 20px;
  border-radius: 15px;
`;

const Header = styled("div")`
  display: flex;
  align-items: center;
`;

const PageHeader: React.FC<{
  title: string;
  subtitle?: string;
  menu?: boolean;
}> = ({ title, subtitle, menu = true }) => {
  const navigate = useNavigate();

  const previousLocation: any = window.history.state.idx > 0 ? -1 : "/";

  return (
    <Header>
      <PreviousButton onClick={() => navigate(previousLocation)}>
        <ArrowBackRoundedIcon sx={{ color: "white", fontSize: "28px" }} />
      </PreviousButton>

      <div style={{ marginLeft: "auto", marginRight: "auto" }}>
        <Typography
          sx={{
            color: "white",
            fontWeight: "bold",
            fontSize: "22px",
            lineHeight: "22px",
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{ color: "white", fontSize: "12px", lineHeight: "14px" }}
        >
          {subtitle}
        </Typography>
      </div>

      {menu && (
        <ProfileCtn>
          <ProfilePicture />
        </ProfileCtn>
      )}
    </Header>
  );
};

export default PageHeader;
