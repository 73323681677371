import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { chatReducer } from "./chatReducer";
import { experiencesReducer } from "./experiencesReducer";
import { homeReducer } from "./homeReducer";
import { notificationsCountReducer } from "./notificationsCountReducer";
import { userReducer } from "./userReducer";

export const reducers = combineReducers({
  auth: authReducer,
  user: userReducer,
  home: homeReducer,
  experiences: experiencesReducer,
  notificationsCount: notificationsCountReducer,
  chat: chatReducer,
});
