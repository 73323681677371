import { Button, Stack } from "@mui/material";
import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import CareerItem from "../../../@types/CareerItem";
import WorkElement from "../../../@types/WorkElement";
import ExperienceFormCompany from "./ExperienceFormCompany";
import ExperienceWorkAutocomplete from "./ExperienceWorkAutocomplete";
import ExperienceFormDate from "./ExperienceFormDate";
import ExperienceFormLocation from "./ExperienceFormLocation";
import experiencesService from "../../../services/experiences.service";
import { useTranslation } from "react-i18next";
import { toastError } from "../../../utils/toasts";

interface IFormInput {
  company: string;
  work: WorkElement;
  dateStart: Date;
  dateEnd: Date;
  ongoing: boolean;
  location: any;
  companyData: any;
}

const ExperienceForm: React.FC<{
  exp?: CareerItem | Partial<CareerItem>;
  handleClose: () => void;
}> = ({ exp, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const { handleSubmit, control, clearErrors, getValues, watch } =
    useForm<IFormInput>({
      mode: "onBlur",
      defaultValues: {
        company: exp?.company ?? "",
        companyData: exp?.companyData ?? {},
        work: exp?.work ?? {},
        dateStart: exp?.dateStart ? new Date(exp?.dateStart) : undefined,
        dateEnd: exp?.dateEnd ? new Date(exp?.dateEnd) : undefined,
        ongoing: !Boolean(exp?.dateEnd),
        location: exp?.location ?? {},
      },
    });

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
    setLoading(true);

    const observer = {
      next: (e: any) => {
        // toast.success(exp?.id ? "Updated" : "Created");
      },
      error: (err: any) => {
        toastError();
        setLoading(false);
      },
      complete: () => {
        setLoading(false);
        handleClose();
      },
    };

    if (!exp?.id) {
      experiencesService.createCareerItem(data).subscribe(observer);
    } else {
      experiencesService
        .updateCareerItem({ ...data, id: exp.id })
        .subscribe(observer);
    }
  };

  const handleDelete = () => {
    setLoading(true);
    const observer = {
      next: (e: any) => {
        // toast.success("Supprimé")
        handleClose();
      },
      error: (err: any) => {
        toastError();
        setLoading(false);
      },
    };

    exp?.id && experiencesService.deleteCareerItem(exp.id).subscribe(observer);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Stack>
        <ExperienceWorkAutocomplete
          control={control}
          clearErrors={clearErrors}
        />

        <ExperienceFormCompany control={control} />

        <ExperienceFormLocation control={control} />

        <ExperienceFormDate
          watch={watch}
          clearErrors={clearErrors}
          getValues={getValues}
          baseOngoing={getValues("ongoing")}
          control={control}
        />

        <div style={{ display: "flex", justifyContent: "space-around" }}>
          {exp?.id && (
            <Button
              onClick={handleDelete}
              color="error"
              sx={{ mt: 1 }}
              disabled={loading}
            >
              {t("actions.delete")}
            </Button>
          )}
          <Button sx={{ mt: 1 }} type="submit" disabled={loading}>
            {t("actions.save")}
          </Button>
        </div>
      </Stack>
    </form>
  );
};

export default ExperienceForm;
