import React, { useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FeedbackReactions from "./FeedbackReactions";
import http from "../../services/http";
import { toastError } from "../../utils/toasts";

interface IFormInput {
  type: string;
  mood: number | null;
  message: string;
}

const FeedbackForm: React.FC<{
  close: () => void;
  openLink: (b: boolean) => void;
}> = ({ close, openLink }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const types = [
    { value: "compliment", label: "feedback.type.compliment" },
    { value: "suggestion", label: "feedback.type.suggestion" },
    { value: "worry", label: "feedback.type.worry" },
    { value: "other", label: "feedback.type.other" },
  ];

  const { handleSubmit, control } = useForm<IFormInput>({
    mode: "onBlur",
    defaultValues: { type: "compliment", mood: null, message: "" },
  });

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
    setLoading(true);

    http
      .post("feedback", { ...data, context: window.location.pathname })
      .subscribe({
        next: () => {
          setLoading(false);
          if (data.mood && data.mood >= 4) {
            openLink(true);
          }
        },
        error: () => {
          setLoading(false);
          toastError();
        },
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <Typography
        variant="h4"
        sx={{
          fontSize: "24px",
          fontFamily: "Clash Display",
          mb: 1,
        }}
      >
        {t("feedback.text_1")}
      </Typography>
      <Stack spacing={3}>
        <Box>
          <Typography sx={{ mb: 1 }} variant="subtitle1">
            {t("feedback.text_1A")}
          </Typography>

          <Controller
            name="mood"
            control={control}
            rules={{
              required: { value: true, message: "errors.required" },
            }}
            render={({ field: { value, onChange }, formState: { errors } }) => (
              <FeedbackReactions
                handleMood={onChange}
                activeMood={value}
                error={errors?.["mood"] && !value}
              />
            )}
          />
        </Box>

        <Box>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
            {t("feedback.text_2")}
          </Typography>
          <Controller
            name="type"
            control={control}
            rules={{
              required: { value: true, message: "errors.required" },
            }}
            render={({ field: { value, onChange } }) => (
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                onChange={(event: any) => onChange(event.target.value)}
                sx={{ width: "100%" }}
              >
                {types.map((e) => (
                  <MenuItem key={e.value} value={e.value}>
                    {t(e.label)}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Box>

        <Box>
          <Typography variant="subtitle1">{t("feedback.text_3")}</Typography>
          <Typography
            variant="body2"
            sx={{ mb: 1, fontSize: "10px;", opacity: 0.8 }}
          >
            {t("feedback.max_length")}
          </Typography>

          <Controller
            name={"message"}
            control={control}
            rules={{
              required: { value: true, message: "errors.required" },
            }}
            render={({ field, formState: { errors } }) => (
              <TextField
                sx={{ width: "100%" }}
                helperText={
                  errors["message"] ? t(errors?.["message"]?.message ?? "") : ""
                }
                error={Boolean(errors["message"])}
                label={t(`feedback.message`)}
                multiline
                rows={3}
                {...field}
                inputProps={{ maxLength: 800 }}
              />
            )}
          />
        </Box>

        <Box display="flex" justifyContent="space-around">
          <Button
            disabled={loading}
            variant="contained"
            color="primary"
            type="submit"
          >
            <Typography variant="body1"> {t("btn.send")}</Typography>
          </Button>
          {/* <Button
            onClick={close}
            variant="outlined"
            color="secondary"
            type="button"
            sx={{ borderRadius: "5px", width: "160px", height: "58px" }}
          >
            <Typography
              color="secondary"
              variant="body1"
              sx={{ color: theme.palette.text.secondary }}
            >
              {t("btn.cancel")}
            </Typography>
          </Button> */}
        </Box>
      </Stack>
    </form>
  );
};

export default FeedbackForm;
