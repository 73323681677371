import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import http from "../../services/http";

export const SET_NOTIFICATIONS_COUNT = "SET_NOTIFICATIONS_COUNT";

export const setNotifsCount = (payload: number) => {
  return {
    type: SET_NOTIFICATIONS_COUNT,
    payload,
  };
};

export const getNotifs = (): ThunkAction<void, any, unknown, AnyAction> => {
  return (dispatch, getState) => {
    http.get(`notifications`, { params: { page: 1 } }).subscribe({
      next: (e) => dispatch(setNotifsCount(e.data["hydra:totalItems"])),
      error: () => {},
    });
  };
};

export const incrementNotifsCount = (): ThunkAction<
  void,
  any,
  unknown,
  AnyAction
> => {
  return (dispatch, getState) => {
    const count = getState().notificationsCount;
    dispatch(setNotifsCount(count + 1));
  };
};

export const decrementNotifsCount = (): ThunkAction<
  void,
  any,
  unknown,
  AnyAction
> => {
  return (dispatch, getState) => {
    const count = getState().notificationsCount;
    dispatch(setNotifsCount(count - 1));
  };
};
