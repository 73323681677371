import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { shuffleArray } from "../../utils/utils";
import Loader from "../loader/Loader";
import MoodGame from "./game/MoodGame";
import MoodIntro from "./MoodIntro";
import MoodResults from "./results/MoodResults";

const Mood: React.FC<{ webapp?: boolean }> = ({ webapp = false }) => {
  const [moodData, setData] = useState<any>(null);

  const [state, setState] = useState(0);
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState<any>(null);

  const start = () => {
    setState(1);
  };
  const end = (res: any) => {
    setResults(handleResults(res));
    setState(2);
  };

  const redo = async () => {
    setState(0);
    try {
      const moodres = await axios.get("tests/mood");
      moodres.data.questions = shuffleArray(moodres?.data?.questions ?? []);
      setData(moodres?.data);
    } catch (err) {}
  };

  const handleResults = (res: any) => {
    const resu = res?.data?.results?.results;
    const keys = Object.keys(resu);
    const arr: any[] = [];
    keys.forEach((el: any) =>
      arr.push({
        key: el,
        ...resu[el],
      })
    );
    return arr;
  };

  useEffect(() => {
    const getResults = async () => {
      try {
        const res = await axios.get("tests/mood/result");

        if (res.data?.results?.results) {
          setResults(handleResults(res));
          setState(2);
        } else {
          const moodres = await axios.get("tests/mood");
          setData(moodres?.data);
        }

        setLoading(false);
      } catch (err) {
        toast.error(
          "Nous n'avons pas pu récupérer vos données concernant ce test"
        );
        setLoading(false);
      }
    };
    getResults();
  }, []);

  return (
    <div>
      {loading ? (
        <Loader noBackground />
      ) : (
        <>
          {state === 0 && (
            <MoodIntro start={start} hasLoaded={Boolean(moodData)} />
          )}
          {state === 1 && (
            <MoodGame handleEnd={end} questions={moodData?.questions ?? []} />
          )}
          {state === 2 && (
            <MoodResults redo={redo} results={results} webapp={webapp} />
          )}
        </>
      )}
    </div>
  );
};

export default Mood;
