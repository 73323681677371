import React from "react";
import PageHeader from "../components/common/PageHeader";
import Page from "../layouts/Page";
import { pageWithNavbar } from "../globalConfig";
import { useSelector } from "react-redux";
import ResultsList from "../components/results/ResultsList";
import { useTranslation } from "react-i18next";

const ResultsListPage = () => {
  const webapp = useSelector((state: any) => state.user?.webapp ?? false);
  const { t } = useTranslation();
  return (
    <Page
      title="pages.results"
      style={{
        ...(webapp ? pageWithNavbar : { minHeight: "100vh" }),
        width: "100%",
        backgroundImage: "url('/static/image-jeu/fond-montagne.jpg')",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        paddingTop: "30px",
        backgroundAttachment: "fixed",
      }}
    >
      {webapp && (
        <PageHeader title={t("tests.title")} subtitle={t("tests.subtitle")} />
      )}
      {/* <ResultsContent /> */}
      <ResultsList />
    </Page>
  );
};

export default ResultsListPage;
