import { Player } from "@lottiefiles/react-lottie-player";
import { styled, Typography } from "@mui/material";
import React, { useEffect, useLayoutEffect, useState } from "react";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { animated as a, useSprings, useSpring } from "react-spring";
import axios from "axios";
import { toast } from "react-toastify";
import worksService from "../../../services/works.service";
import { useTranslation } from "react-i18next";

const SRoot = styled(a.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CheckIcon = styled(CheckCircleRoundedIcon)`
  color: #e07f4f;
  margin-right: 8px;
`;

const SLabelContainer = styled("div")`
  display: flex;
  align-items: center;
`;

const SLabelsList = styled("div")`
  > div {
    margin-bottom: 10px;
  }
`;

const Label = (props: any) => (
  <SLabelContainer>
    <CheckIcon />
    <Typography variant="subtitle1" sx={{ color: "#5D5D5D" }}>
      {props.children}
    </Typography>
  </SLabelContainer>
);

const to = (i: any) => ({
  y: 0,
  opacity: 1,
  delay: i * 1200,
});
const from = (_i: any) => ({ y: 20, opacity: 0 });

const LabelsList: React.FC<any> = ({ handleEnd }) => {
  const labels = [
    "Récupération de vos données",
    "Analyse de la spontanéité",
    "Calcul des scores",
    "Construction de votre profil",
  ];

  const [ended, setEnded] = useState(false);

  useEffect(() => {
    if (ended) {
      handleEnd();
    }
  }, [ended, handleEnd]);

  const [springs] = useSprings(labels.length, (index) => ({
    ...to(index),
    from: from(index),
    onRest: () => {
      if (index === labels.length - 1) {
        setEnded(true);
      }
    },
  }));

  return (
    <SLabelsList>
      {springs.map((style, i) => (
        <a.div key={labels[i]} style={{ ...style }}>
          <Label>{labels[i]}</Label>
        </a.div>
      ))}
    </SLabelsList>
  );
};

const MoodChoicesHandler: React.FC<any> = ({ end, choices }) => {
  const [ok, setOk] = useState(false);
  const { t } = useTranslation();
  const [isEnding, setIsEnding] = useState(false);

  const [hasPosted, setHasPosted] = useState(false);

  const [results, setResults] = useState<any>(null);

  const duration = 500;
  const styles = useSpring({
    to: { opacity: isEnding ? 0 : 1 },
    from: { opacity: 1 },
    duration,
  });

  useLayoutEffect(() => {
    const to = setTimeout(() => {
      setOk(true);
    }, 1000);

    return () => {
      clearTimeout(to);
    };
  }, []);

  useEffect(() => {
    const save = async () => {
      try {
        const body = {
          duration: 0,
          raw: choices,
          test: "/api/tests/mood",
        };

        await axios.post("results", body);

        const resultsAxios = await axios.get("tests/mood/result");

        const completion = JSON.parse(
          localStorage.getItem("completeness") ?? "{}"
        );
        completion.hasMood = true;
        localStorage.setItem("completeness", JSON.stringify(completion));
        worksService.updateDraft({ mood: [3, 2, 1, 0] });
        worksService.updateParams({ mood: [3, 2, 1, 0] });

        setResults(resultsAxios);

        setHasPosted(true);
      } catch (err) {
        toast.error(t("errors.toast"));
      }
    };

    save();
  }, [choices]);

  useEffect(() => {
    let to: NodeJS.Timeout;

    if (isEnding && hasPosted && results) {
      to = setTimeout(() => {
        end(results);
      }, duration);
    }

    return () => {
      clearTimeout(to);
    };
  }, [isEnding, end, hasPosted, results]);

  const handleEnd = () => {
    setIsEnding(true);
  };

  return (
    <SRoot style={styles}>
      <Player
        autoplay
        loop
        src={`/static/illustrations/lotties/mood-star-pulse.json`}
        style={{ height: "300px", width: "70%" }}
      ></Player>
      {ok && <LabelsList handleEnd={handleEnd} />}
    </SRoot>
  );
};

export default MoodChoicesHandler;
