import { CardHeader, styled } from "@mui/material";

const CustomCardHeader = styled(CardHeader)(({ theme }) => ({
  // flex: "1 1 auto",
  // width: "100%",
  // "& .MuiCardHeader-content": {
  //   overflow: "hidden",
  // },
  alignItems: "flex-start",
  ".MuiCardHeader-title": {
    color: theme.palette.secondary.light,
    fontSize: "18px",
    fontWeight: "bold",
  },
  "& .MuiCardHeader-avatar > img": {
    maxWidth: "36px",
    maxHeight: "36px",
  },
  "& .MuiCardHeader-subheader": {
    color: "#828CC7",
    fontSize: "14px",
  },
}));

export default CustomCardHeader;
