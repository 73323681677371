import React from "react";
import { animated, useSprings, to as interpolate } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";
import { useState, useEffect, useLayoutEffect } from "react";
import { styled } from "@mui/material";
import MoodSwipeCard from "./MoodSwipeCard";
import MoodSwipeControls from "./MoodSwipeControls";

const Deck = styled("div")`
  position: relative;
  height: 275px;
  width: 215px;
`;

const to = (i: any) => ({
  x: 0,
  y: 0,
  scale: 1,
  delay: 100,
});
const from = (_i: any) => ({ x: 0, rot: 0, scale: 1.5, y: -1000 });

const trans = (r: any, s: any) =>
  `rotateY(${r / 10}deg) rotateZ(${r}deg) scale(${s})`;

const MoodGameView: React.FC<any> = ({ deck, handleChoice }) => {
  const [gone] = useState(() => new Set());
  const [overlayDir, setOverlayDir] = useState(0);
  const [current, setCurrent] = useState(deck.length - 1);
  const [lock, setLock] = useState(true);

  useLayoutEffect(() => {
    const to = setTimeout(() => {
      setLock(false);
    }, 100);

    return () => {
      clearTimeout(to);
    };
  }, [deck.length]);

  const [springs, api] = useSprings(deck.length, (index: number) => ({
    ...to(index),
    from: from(index),
    onProps: ({ to }: any) => {
      setOverlayDir(to.x / 100);
    },
  }));
  const handleSwipe = (swipeDir: any) => {
    if (lock) return;
    api.start((index: number) => {
      if (index === current) {
        handleChoice({
          id: deck[index].id,
          value: swipeDir,
        });
        setCurrent((c) => c - 1);
        gone.add(current);
        return { x: (200 + window.innerWidth) * swipeDir };
      }
    });
  };

  useEffect(() => {
    setOverlayDir(0);
  }, [current]);

  const bind = useDrag(
    ({
      args: [index],
      active,
      movement: [mx, my],
      direction: [xDir],
      velocity: [vx],
    }: any) => {
      if (lock) return;
      const trigger = vx > 0.2;
      if (!active && trigger) {
        gone.add(index);
      }
      api.start((i: number) => {
        if (current !== i) return;
        const isGone = gone.has(index);
        if (isGone) {
          api.stop();
          handleSwipe(xDir);
          return;
        }
        const rot = active ? mx / 100 + (isGone ? xDir * 10 * vx : 0) : 0;
        return {
          x: active ? mx : 0,
          y: active ? my : 0,
          rot,
          delay: undefined,
        };
      });
    }
  );

  return (
    <div>
      <Deck>
        {springs.map(({ x, y, rot, scale }: any, i: number) => (
          <animated.div key={i} style={{ x, y }}>
            <MoodSwipeCard
              text={deck[i].label}
              background={deck[i]?.background}
              current={current === i}
              overlayDir={current === i ? overlayDir : 0}
              {...bind(i)}
              style={{
                ...(current === i
                  ? {}
                  : {
                      top: "-10px",
                      boxShadow: "none",
                    }),
                transform: interpolate([rot, 1], trans),
              }}
            />
          </animated.div>
        ))}
      </Deck>
      <MoodSwipeControls handleSwipe={handleSwipe} />
    </div>
  );
};

export default MoodGameView;
